export const en = {
  homepage: 'Home Page',

  active: 'Active',
  inactive: 'Inactive',

  homeCharts: {
    earnBarTitle: 'Earnings (Daily)',
    todayEarn: 'Daily Earnings',
    weeklyEarn: 'Weekly Earnings',
    monthlyEarn: 'Monthly Earnings',

    orderBarTitle: 'Order Count (Daily)',
    todayOrder: 'Daily Order',
    weeklyOrder: 'Weekly Order',
    monthlyOrder: 'Monthly Order',

    popularBarTitle: 'Best Selling Products',
    todayPopularProduct: 'Product of the Day',
    weeklyPopularProduct: 'Product of the Week',
    monthlyPopularProduct: 'Product of the Month',
  },
};
