import { Grid, Typography } from '@mui/material';
import restaurantDemo from 'assets/img/restorant-demo.jpg';
import restaurantMenuDemo from 'assets/img/qr-editor.png';
import { useStyles } from './Landing.styles';

export const FeaturesImages = () => {
  const { classes } = useStyles();
  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignContent="center"
      className={classes.floor3}
      id="use-cases"
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="space-around"
        alignContent="center"
      >
        <Grid
          item
          xs={12}
          lg={5}
          container
          justifyContent="start"
          flexDirection="column"
        >
          <Typography variant="h4" className={classes.useCasesTitle}>
            Online Satış Sistemi
          </Typography>
          <Typography variant="body2" fontSize="1.5rem" mt={2}>
            Kendi siteniz üzerinden satış yaparak komisyon ödemekten kurtulun.
          </Typography>
          <Typography variant="body2" fontSize="1.5rem" mt={2}>
            <ul>
              <li>Anlık sipariş bildirimleri</li>
              <li>Kişiselleştirilebilen ürünler</li>
              <li>Her yerden çevrimiçi erişim</li>
              <li>Kullanıcı dostu tasarım</li>
              <li>Komisyonsuz satış</li>
            </ul>
          </Typography>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'flex' } }} xs={12} lg={6} item>
          <img
            src={restaurantDemo}
            alt="Sahin Yazilim"
            style={{ width: '100%', borderRadius: '10px' }}
          />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        container
        justifyContent="space-around"
        alignContent="center"
        item
      >
        <Grid
          sx={{ display: { xs: 'none', md: 'flex' } }}
          lg={6}
          container
          justifyContent="end"
          item
        >
          <img
            src={restaurantMenuDemo}
            alt="Sahin Yazilim"
            style={{
              width: '100%',
              borderRadius: '10px',
            }}
          />
        </Grid>
        <Grid
          xs={12}
          lg={5}
          container
          justifyContent="start"
          flexDirection="column"
          item
        >
          <Typography variant="h4" className={classes.useCasesTitle}>
            QR Kod Sistemi
          </Typography>
          <Typography variant="body2" fontSize="1.5rem" mt={2}>
            Oluşturduğunuz QR Kod ile müşterilerinizle sitenizi kolayca
            paylaşabilir, dilediğiniz gibi sipariş alabilirsiniz.
          </Typography>
          <Typography variant="body2" fontSize="1.5rem">
            <ul>
              <li>Otomatik QR kod oluşturma</li>
              <li>Her yerden erişim</li>
              <li>Sade anlaşılır tasarım</li>
              <li>Özelleştirilebilir QR Editör</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
