import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { sahinAlertDialog } from 'common/components/AlertDialog/AlertDialog';
import { LandingHeader } from 'common/components/LandingComponents/LandingHeader';
import { LandingMiniFooter } from 'common/components/LandingComponents/LandingMiniFooter';
import { SahinMaskField } from 'common/components/MaskField/SahinMaskField';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { Stepper } from 'common/components/Stepper/Stepper';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { commonActions } from 'common/context/CommonSlice';
import { useAppDispatch } from 'common/context/hooks';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { postAPI } from 'common/helper/apiHelpers';
import { axios } from 'common/helper/axiosInit';
import { createLink } from 'common/helper/createRoute';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  RegisterData,
  RegisterFormResolver,
  Step0Shema,
  Step1Shema,
  Step2Shema,
  subDomains,
} from './Register.types';
import { OwnerEnv } from 'common/utils/env';

export const Register = () => {
  const {
    register: formRegister,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<RegisterData>({
    resolver: RegisterFormResolver,
    mode: 'onChange',
  });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const subDomainRef = useRef<HTMLInputElement>(null);

  const getValidation = () => {
    const values: any = getValues();
    let isValid = false;

    switch (step) {
      case 1:
        isValid =
          Step1Shema.isValidSync(values) &&
          values.password === values.passwordAgain;
        if (
          !errors.passwordAgain?.message &&
          !isValid &&
          values.password &&
          values.passwordAgain &&
          values.password !== values.passwordAgain
        ) {
          setError('passwordAgain', {
            type: 'custom',
            message: 'Şifreler eşleşmiyor!',
          });
        }
        break;

      case 2:
        isValid = Step2Shema.isValidSync(values);
        break;

      default:
        isValid = Step0Shema.isValidSync(values);
        break;
    }

    return !isValid;
  };

  const isValid = getValidation();

  const onSubmit = (data: RegisterData) => {
    setLoading(true);
    postAPI(AdminTablePaths.REGISTER, data)
      .then((res) => {
        if (res.status === 'error') {
          setError('email', { type: 'custom', message: res.message });
        } else {
          dispatch(commonActions.setAuth(res.data.auth));
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${res.data.auth.token}`;
          sahinAlertDialog({
            message: t('application.login.successLogin'),
            type: 'success',
          });
        }
      })
      .catch((item) => console.log(item))
      .finally(() => {
        setLoading(false);
      });
  };

  const nextStep = () => {
    clearErrors();
    setStep((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (step !== 2) return;
    const input = subDomainRef.current;
    if (input) input.placeholder = subDomains[subDomains.length - 1];
    const interval = setInterval(() => {
      if (!input) return;
      const index = Math.floor(Math.random() * 10);
      input.placeholder = subDomains[index % subDomains.length];
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [step]);

  const register = (name: any) => {
    const registerData = formRegister(name);
    return {
      ...registerData,
      onChange: (e: any) => {
        trigger();
        registerData.onChange(e);
      },
    };
  };

  const formSteps = [
    <Fragment key={1}>
      <Grid xs={12} item>
        <SahinTextField
          {...register('fullName')}
          error={errors.fullName?.message}
          autoComplete="name"
          label={t('form.fullName.value')}
          placeholder={t('form.fullName.placeholder')}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinMaskField
          {...register('phone')}
          error={errors.phone?.message}
          autoComplete="tel-local"
          label={t('form.phone.value')}
          placeholder={t('form.phone.placeholder')}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          {...register('email')}
          error={errors.email?.message}
          autoComplete="email"
          label={t('form.email.value')}
          placeholder={t('form.email.placeholder')}
          fullWidth
        />
      </Grid>
    </Fragment>,
    <Fragment key={2}>
      <Grid xs={12} item>
        <SahinTextField
          {...register('password')}
          error={errors.password?.message}
          autoComplete="current-password"
          type="password"
          label={t('form.password.value')}
          placeholder={t('form.password.placeholder')}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          {...register('passwordAgain')}
          error={errors.passwordAgain?.message}
          autoComplete="new-password"
          type="password"
          label={t('form.passwordAgain.value')}
          placeholder={t('form.passwordAgain.placeholder')}
          fullWidth
        />
      </Grid>
    </Fragment>,
    <Fragment key={3}>
      <Grid xs={12} item>
        <SahinTextField
          {...register('company')}
          error={errors.company?.message}
          autoComplete="organization"
          label={t('form.organizationName.value')}
          placeholder={t('form.organizationName.placeholder')}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          // {...register('domain')}
          name="domain"
          error={errors.domain?.message}
          label={t('form.customDomain.value')}
          placeholder={t('form.customDomain.placeholder')}
          helperText="çok yakında..."
          sx={{ opacity: 0.4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">.com</InputAdornment>,
          }}
          disabled
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          {...register('subDomain')}
          error={errors.subDomain?.message}
          label={t('form.subdomain.value')}
          placeholder={t('form.subdomain.placeholder')}
          fullWidth
          inputRef={subDomainRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">.{OwnerEnv.domain}</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Fragment>,
  ];

  return (
    <Grid
      justifyContent="space-around"
      alignContent="space-between"
      direction="row"
      container
    >
      <LandingHeader />

      <Grid
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        xs={10}
        lg={4}
        alignContent="flex-start"
        spacing={3}
        item
        container
      >
        {formSteps[step ?? 0]}
        {step === 0 && (
          <Grid xs={12} justifyContent="space-between" item container>
            <SahinButton
              color="text"
              component={Link}
              to={createLink(ProjectRouteNames, ProjectRouteNames.LOGIN)}
            >
              <Typography
                fontSize={13}
                style={{
                  textDecorationLine: 'underline',
                }}
              >
                {t('application.login.haveAccound')}
              </Typography>
            </SahinButton>
          </Grid>
        )}
        <Grid sx={{ mt: 5 }} justifyContent="center" container>
          <Stepper
            steps={formSteps.length}
            activeStep={step}
            nextButton={
              step === 2 ? (
                <SahinButton key={1} variant="contained" type="submit">
                  {t('application.login.register')}
                </SahinButton>
              ) : (
                <SahinButton
                  key={2}
                  color="normal"
                  size="small"
                  variant="contained"
                  onClick={nextStep}
                  disabled={isValid}
                >
                  İLERİ
                  <KeyboardArrowRight />
                </SahinButton>
              )
            }
            backButton={
              <SahinButton
                color="text"
                size="small"
                loading={loading}
                disabled={step === 0 || loading}
                onClick={() => setStep((prevState) => prevState - 1)}
              >
                <KeyboardArrowLeft />
                GERİ
              </SahinButton>
            }
          />
        </Grid>
      </Grid>
      <LandingMiniFooter />
    </Grid>
  );
};
