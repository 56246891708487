import { GridColDef } from '@mui/x-data-grid';
import { sahinCustomDialog } from 'common/components/CustomDialog/CustomDialog';
import { Image } from 'common/components/Image/Image';
import { getCdnImageUrl } from 'common/utils/image';

export const sliderListColumns: GridColDef[] = [
  {
    field: 'image',
    headerName: '🖼️',
    width: 650,
    type: 'actions',
    renderCell: (params) => {
      return (
        <>
          {params.row?.image ? (
            <Image
              src={getCdnImageUrl(params.row.image)}
              alt={params.row.name}
              height={200}
              onClick={() => {
                sahinCustomDialog({
                  title: 'SLIDER',
                  children: (
                    <Image
                      src={getCdnImageUrl(params.row.image)}
                      alt={params.row.name}
                      aspectRatio="auto"
                    />
                  ),
                });
              }}
            />
          ) : (
            '#'
          )}
        </>
      );
    },
  },
  { field: 'url', headerName: 'Yönlendirme', width: 230 },
];
