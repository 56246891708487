import { GlobalStyles } from 'tss-react';

export const GlobalCss = () => {
  return (
    <GlobalStyles
      styles={{
        'input:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #2a2a2a inset !important',
        },
        '& ::-webkit-scrollbar': {
          width: '12px',
        },
        '& ::-webkit-scrollbar-track': {
          backgroundColor: '#fff',
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: '#6099ef',
        },
        '& .admin ::-webkit-scrollbar': {
          width: '14px',
        },
        '& .admin ::-webkit-scrollbar-track': {
          backgroundColor: '#0e0e0e',
        },
        '& .admin ::-webkit-scrollbar-thumb': {
          backgroundColor: '#2e2e2e',
        },
      }}
    />
  );
};
