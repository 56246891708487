import { Grid, InputAdornment } from '@mui/material';
import { SahinMaskField } from 'common/components/MaskField/SahinMaskField';
import { useTranslation } from 'react-i18next';
import { SettingSwitchField } from './SettingSwitchField';
import { SettingsDividerTitle } from './SettingsDividerTitle';

export const PaymentTypeForm = ({ control, register, errors }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <SettingsDividerTitle text="Ödeme Seçenekleri" />
      <Grid xs={12} alignItems="center" item container>
        <Grid xs={10} item>
          Minimum Sipariş Tutarı
        </Grid>
        <Grid xs={2} item>
          <SahinMaskField
            {...register(`paymentMethod.minPrice` as keyof Setting)}
            error={errors?.paymentMethod?.minPrice?.message}
            placeholder="Tutar"
            mask={'###'}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">TL</InputAdornment>,
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <SettingsDividerTitle text={t('application.payment.paymentMethods')} />
      {paymentMethodForm.map((item) => (
        <>
          <SettingSwitchField control={control} {...item} />
          <SettingsDividerTitle />
        </>
      ))}
    </>
  );
};

const paymentMethodForm = [
  { name: 'paymentMethod.gateCreditCard', label: 'Kapıda Kredi Kartı' },
  { name: 'paymentMethod.gateCash', label: 'Kapıda Nakit' },
  { name: 'paymentMethod.ticket', label: 'Ticket Restaurant Kartı' },
  { name: 'paymentMethod.setcard', label: 'Setcard' },
  { name: 'paymentMethod.multinet', label: 'Multinet Kart' },
  { name: 'paymentMethod.paye', label: 'Paye Kart' },
  { name: 'paymentMethod.sodexo', label: 'Sodexo Card' },
  { name: 'paymentMethod.metropol', label: 'Metrepol Card' },
];
