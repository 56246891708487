import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  root: {
    borderRadius: 10,
  },
  bg: {
    background: '#282828',
    width: 550,
    overflow: 'auto',
    color: 'white',
    '& p': {
      color: 'white',
    },
  },
  buttons: {
    '& button': {
      fontWeight: 600,
      color: _theme.palette.primary.main,
    },
  },
}));
