import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const SliderFormYup = yupResolver(
  yup
    .object({
      url: yup.string().optional().max(240),
      image: yup.string().min(5).max(240).required(),
    })
    .required(),
);
