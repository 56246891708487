import { confirmAlert } from 'react-confirm-alert';
import { SahinCustomDialogProps } from './CustomDialog.types';
import { CustomDialogCustomUI } from './CustomDialogCustomUI';

export const sahinCustomDialog = ({
  children,
  ...props
}: SahinCustomDialogProps) => {
  return confirmAlert({
    customUI: ({ ...rest }) => {
      return (
        <CustomDialogCustomUI {...rest} {...props}>
          {children}
        </CustomDialogCustomUI>
      );
    },
  });
};
