import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  textButton: {
    borderRadius: 10,
    marginRight: 10,
    padding: 5,
    cursor: 'pointer',
    border: '1px solid #ccc',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
    textAlign: 'center',
  },
}));
