import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';
import { OwnerEnv } from 'common/utils/env';

export const PrivacyPolicy = () => {
  return <BlogWrapper title={title} content={content} component="pre" />;
};

const variables = {
  company: {
    name: OwnerEnv.company.name,
    title: OwnerEnv.title,
    domain: OwnerEnv.domain,
    phone: OwnerEnv.company.phone,
    mail: OwnerEnv.company.mail,
  },
};

const title = 'Gizlilik Sözleşmesi';
const content = `
İNTERNET SİTESİ GİZLİLİK ÇEREZ POLİTİKASI

${variables.company.name} (“${variables.company.title}” veya “Şirket”) tarafından işletilen ${variables.company.domain} internet sitelerini ziyaret edenlerin (“Veri Sahibi”) gizliliğini korumak Şirketimizin önde gelen ilkelerindendir. İnternet Sitesi Gizlilik Politikası (“Politika”) ile; (1) Veri Sahiplerinin kişisel verilerinin işlenmesi, (2) Çerez Politikası ve (3) İnternet Sitesi Gizlilik Politikası’nın yürürlüğü hakkında açıklamalar yapılmaktadır.

1. VERİ SAHİPLERİNİN KİŞİSEL VERİLERİNİN İŞLENMESİ

İnternet sitemizi ziyaret etmeniz sırasında elde edilen kişisel veriler MYSO Exclusive tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca aşağıda açıklanan kapsamda işlenebilecektir. Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere ${OwnerEnv.domain} sitesinin Kişisel Verilerin Korunması ve İşlenmesi Politikası’ndan ulaşabilirsiniz.

KİŞİSEL VERİLERİNİZİN İŞLENME AMACI

İnternet Sitemizi ziyaret etmeniz dolayısıyla elde edilen kişisel verileriniz aşağıda sıralanan amaçlarla MYSO Exclusive  tarafından KVK Kanunu’nun 5. ve 6. maddelerine uygun olarak işlenebilecektir.

-  Şirket’in insan kaynakları politikaları ve süreçlerinin planlanması ve icra edilmesi,

-  Şirket tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi,

- Şirket tarafından sunulan ürün ve hizmetlerin ilgili kişilerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek ilgili kişilere önerilmesi ve tanıtılması için gerekli olan aktivitelerin planlanması ve icrası,

- Şirket tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,

-  Şirket’in ticari ve/veya iş stratejilerinin planlanması ve icrası,

- Şirket’in ve Şirket’le iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş güvenliğinin temini.

Şirketimiz ayrıca; kişisel verilerinizi talep etmeniz halinde üyelik kaydının gerçekleştirilmesi, sizleri kampanyalarımızdan bildirilmesini sağlamak amacıyla da işleyebilecektir.

KİŞİSEL VERİLERİNİZİN AKTARILDIĞI TARAFLAR VE AKTARIM AMACI

İnternet sitemizi ziyaret etmeniz dolayısıyla elde edilen kişisel verileriniz, kişisel verilerinizin işlenme amaçları doğrultusunda, iş ortaklarımıza, tedarikçilerimize kanunen yetkili kamu kurumlarına ve özel kişilere KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde aktarılabilecektir.

KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ

Kimliğinizi belirli ya da belirlenebilir kılan her türlü bilgi “kişisel veri”dir. İnternet sitemizi ziyaretiniz kapsamında kişisel verileriniz Kanun’da yer alan veri işleme şartlarına uygun olarak İnternet Sitemizi ziyaret etmeniz dolayısıyla teknik iletişim dosyaları olan çerezler (cookies) vasıtasıyla toplanmaktadır.

VERİ SAHİPLERİNİN HAKLARI

Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:

Kişisel verilerinizin işlenip işlenmediğini öğrenme,

Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,

Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,

Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,

Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,

Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,

İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkması durumunda buna itiraz etme,

Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.

Yukarıda sıralanan haklarınıza yönelik başvurularınızı, ${OwnerEnv.domain}  sitesinden  Şirketimize iletebilirsiniz. Talebinizin niteliğine göre en kısa sürede ve en geç otuz gün içinde başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre tarafınızdan ücret talep edilebilecektir.



VERİ SAHİPLEİRNİN KİŞİSEL VERİLERİNİN GÜVENLİĞİNİN SAĞLANMASINA YÖNELİK ALINAN TEDBİRLER

Şirketimizin kişisel verilerinin korunmasına ve veri güvenliğinin sağlanmasına verdiği önem doğrultusunda, Kanun’un 12. maddesine uygun olarak, Şirketimiz tarafından veri güvenliğinin sağlanması için,

– Kişisel verilerin hukuka aykırı olarak işlenmesini önlemek,

– Kişisel verilere hukuka aykırı olarak erişilmesini önlemek,

– Kişisel verilerin muhafazasını sağlamak, amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli her türlü teknik ve idari tedbirleri almaktadır.

2. ÇEREZ POLİTİKASI

İnternet sitemizden en verimli şekilde faydalanabilmeniz ve kullanıcı deneyiminizi geliştirebilmek için Çerez kullanıyoruz. Çerez kullanılmasını tercih etmezseniz tarayıcınızın ayarlarından Çerezleri silebilir ya da engelleyebilirsiniz. Ancak bunun internet sitemizi kullanımınızı etkileyebileceğini hatırlatmak isteriz. Tarayıcınızdan Çerez ayarlarınızı değiştirmediğiniz sürece bu sitede çerez kullanımını kabul ettiğinizi varsayacağız.

ÇEREZ NEDİR VE NEDEN KULLANILMAKTADIR?

Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır.

İnternet sitemizde çerez kullanılmasının başlıca amaçları aşağıda sıralanmaktadır:

– İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan hizmetleri geliştirmek,

– İnternet sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek,

– İnternet sitesinin, sizin ve Şirketimizin hukuki ve ticari güvenliğinin teminini sağlamak.

İNTERNET SİTEMİZDE KULLANILAN ÇEREZ TÜRLERİ

Oturum Çerezleri (Session Cookies)

Oturum çerezleri ziyaretçilerimizin internet sitesini ziyaretleri süresince kullanılan, tarayıcı kapatıldıktan sonra silinen geçici çerezlerdir.

Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz süresince internet sitesinin düzgün bir biçimde çalışmasının teminini sağlamaktır.

Örneğin; birden fazla sayfadan oluşan çevrimiçi formları doldurmanız sağlanmaktadır.

Kalıcı Çerezler (Persistent Cookies)

Kalıcı çerezler internet sitesinin işlevselliğini artırmak, ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla kullanılan çerez türleridir.

Bu tür çerezler tercihlerinizi hatırlamak için kullanılır ve tarayıcılar vasıtasıyla cihazınızda depolanır.

Kalıcı çerezlerin bazı türleri; internet sitesini kullanım amacınız gibi hususlar göz önünde bulundurarak sizlere özel öneriler sunulması için kullanılabilmektedir.

Kalıcı çerezler sayesinde internet sitemizi aynı cihazla tekrardan ziyaret etmeniz durumunda, cihazınızda internet sitemiz tarafından oluşturulmuş bir çerez olup olmadığı kontrol edilir ve var ise, sizin siteyi daha önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu doğrultuda belirlenir ve böylelikle sizlere daha iyi bir hizmet sunulur.

Teknik Çerezler (Technical Cookies)

Teknik çerezler ile internet sitesinin çalışmasının sağlanmakta, internet sitesinin çalışmayan sayfaları ve alanları tespit edilmektedir.

Otantikasyon Çerezleri (Authentication Cookies)

Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları durumunda, bu tür çerezler ile, ziyaretçinin internet sitesinde ziyaret ettiği her bir sayfada site kullanıcısı olduğu belirlenerek, kullanıcının her sayfada şifresini yeniden girmesi önlenir.

Flash Çerezleri (Flash Cookies)

İnternet sitesinde yer alan görüntü veya ses içeriklerini etkinleştirmek için kullanılan çerez türleridir.

Kişiselleştirme Çerezleri (Customization Cookies)

Kullanıcıların tercihlerini farklı internet sitesinin farklı sayfalarını ziyarette de hatırlamak için kullanılan çerezlerdir. Örneğin, seçmiş olduğunuz dil tercihinizin hatırlanması.

Analitik Çerezler (Analytical Cookies)

Analitik çerezler ile internet sitesini ziyaret edenlerin sayıları, internet sitesinde görüntülenen sayfaların tespiti, internet sitesi ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi analitik sonuçların üretimini sağlayan çerezlerdir.

Üçüncü Parti Çerezler (Third Party Cookies)

Çerezi yerleştiren tarafa göre, platform çerezleri ve üçüncü taraf çerezler kullanılmaktadır. Platform çerezleri, Flo tarafından oluşturulurken, üçüncü taraf çerezlerini Flo ile iş birlikteliği olan farklı firmalar yönetmektedir



ÇEREZLERİN KULLANIMI VERİ SAHİPLERİ TARAFINDAN ENGELLENEBİLİR Mİ?

Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirme imkanına sahipsiniz.

Analytics

http://www.adobe.com/uk/privacy/opt-out.html

AOL

https://help.aol.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser

Google Adwords

https://support.google.com/ads/answer/2662922?hl=en

Google Analytics

https://tools.google.com/dlpage/gaoptout

Google Chrome

http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647

Internet Explorer

https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies

Mozilla Firefox

http://support.mozilla.com/en-US/kb/Cookies

Opera

http://www.opera.com/browser/tutorials/security/privacy/

Safari:

https://support.apple.com/kb/ph19214?locale=tr_TR


3. İNTERNET SİTESİ GİZLİLİK POLİTİKASI’NIN YÜRÜRLÜĞÜ

İnternet Sitesi Gizlilik Politikası 12.09.2022 tarihlidir. Politika’nın tümünün veya belirli maddelerinin yenilenmesi durumunda Politika’nın yürürlük tarihi güncellenecektir.

Politika MYSO Exclusive tarafından işletilen internet sitelerinde (${OwnerEnv.domain})  yayımlanır ve kişisel veri sahiplerinin talebi üzerine ilgili kişilerin erişimine sunulur.

Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere ${OwnerEnv.domain} sitesinin Kişisel Verilerin Korunması ve İşlenmesi Politikası’ndan ulaşabilirsiniz.
`;
