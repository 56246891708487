import { default as axiosInstance } from 'axios';
import { AdminCacheNames } from 'common/model/AdminCacheNames';
import { setCache } from 'common/utils/helper';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;
const auth = JSON.parse(localStorage.getItem(AdminCacheNames.AUTH) ?? '0');

const axiosConfig = {
  baseURL: BACKEND_API_URL,
  headers: {
    ...(auth
      ? {
          Authorization: `Bearer ${auth.token}`,
          // withCredentials: true,
          // credentials: true,
          'Content-Type': 'application/json, text/plain, */*',
        }
      : {}),
  },
};

export const axios = axiosInstance.create(axiosConfig);

axios.interceptors.response.use(undefined, (request) => {
  if (request.response.status === 401) {
    setCache(AdminCacheNames.AUTH, null);
    document.location.reload();
  }
  return request;
});

export const setAxiosAuth = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
