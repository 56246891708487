import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const PaymentFormYup = yupResolver(
  yup
    .object({
      user: yup.object().shape({
        name: yup
          .string()
          .min(3)
          .max(20)
          .matches(/^[aA-zZ\s]+$/, 'Lütfen isminizi girin')
          .required(),
        surname: yup.string().min(2).max(30).required(),
        phone: yup.string().length(12).required(),
        email: yup.string().email().max(30).required(),
        identity: yup.string().length(11).required(),
      }),
      address: yup.object().shape({
        city: yup.string().required(),
        address: yup.string().min(10).max(120).required(),
      }),
    })
    .required(),
);
