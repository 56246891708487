import { Box, Button, Grid, Typography } from '@mui/material';
import { SahinDropdown } from 'common/components/Dropdown/SahinDropdown';
import { SahinModal } from 'common/components/Modal/SahinModal';
import { updateAPI } from 'common/helper/apiHelpers';
import { Nullable } from 'common/model/Nullable';
import { OrderStatus } from 'common/model/OrderStatus';
import { PaymentMethods } from 'common/model/PaymentMethods';
import { currencyFormat } from 'common/utils/helper';
import { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { COMPONENT_TABLE_PATH } from '../List/OrderList';
import { orderStatusDot } from '../List/OrderList.types';
import { ProductFormYup } from './Order.types';
import { useStyles } from './OrderForm.styles';

export const OrderForm = forwardRef<
  any,
  {
    initialData: Nullable<Order>;
    open: boolean;
    handleClose: () => void;
  }
>(({ initialData, open, handleClose }, ref) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<Order>({
    resolver: ProductFormYup,
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const { classes } = useStyles();

  useEffect(() => {
    reset(initialData || {});
  }, [initialData, reset]);

  const onSubmit = (data: Order) => {
    if (initialData?.id) {
      updateAPI(COMPONENT_TABLE_PATH, initialData.id, data);
    }
    mutate(COMPONENT_TABLE_PATH);
    handleClose();
  };

  return (
    <SahinModal open={open} handleClose={handleClose} width="50%" height="90%">
      <Grid
        sx={{ height: '100%' }}
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        container
      >
        <Grid alignContent="flex-start" spacing={3} container>
          <Grid xs={6} item>
            <Box className={classes.card}>
              <Typography variant="h6">{t('form.name.value')}</Typography>
              <Typography>
                {`${initialData?.address.name} ${initialData?.address.surname}`}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box className={classes.card}>
              <Typography variant="h6">{t('form.phone.value')}</Typography>
              <Typography>{initialData?.address.phone}</Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box className={classes.card}>
              <Typography variant="h6">{t('form.address.value')}</Typography>
              <Typography>{initialData?.address.address}</Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box className={classes.card}>
              <Typography variant="h6">{t('form.price.value')}: </Typography>
              <Typography>{currencyFormat(initialData?.price)}</Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box className={classes.card}>
              <Typography variant="h6">{t('order.products')}</Typography>
              <Typography fontSize={20}>
                {initialData?.basket?.map((item) => (
                  <Grid key={item.product.name} pl={2}>
                    x{item.count} {item.product.name}
                  </Grid>
                ))}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box className={classes.card}>
              <Typography variant="h6">{t('order.paymentType')}</Typography>
              <Typography>
                {
                  PaymentMethods[
                    initialData?.paymentMethod as keyof typeof PaymentMethods
                  ]
                }
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item container>
            <Grid className={classes.card} flexDirection="column" container>
              <Typography variant="h6">{t('order.orderNote')}</Typography>
              <Typography>{initialData?.note || 'Yok'}</Typography>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SahinDropdown
                  style={{ backgroundColor: 'rgba(255,255,255,0.05)' }}
                  error={errors?.paymentMethod?.message}
                  label="Durum"
                  data={Object.values(OrderStatus)?.map((item) => ({
                    key: item,
                    label: (
                      <Grid gap={2} alignItems="center" container>
                        {orderStatusDot(item)}
                        {t('orderStatus.' + item)}
                      </Grid>
                    ),
                  }))}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid xs={12} justifyContent="flex-end" item container>
            <Button variant="contained" type="submit">
              {initialData?.id ? t('edit') : t('add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </SahinModal>
  );
});
