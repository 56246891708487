export enum OrderStatusColor {
  PENDING = 'success',
  PREPARING = 'warning',
  ON_WAY = 'info',
  DELIVERED = 'secondary',
  REJECTED = 'error',
}

export enum CheckStatusColor {
  ACTIVE = 'success',
  INACTIVE = 'error',
}
