import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  card: {
    padding: 20,
    border: '1px solid #0e0e0e',
    borderRadius: 10,
    backgroundColor: '#222',
    color: '#eee',

    '&>h6': {
      fontSize: 14,
      color: '#8a8a8a',
      textTransform: 'uppercase',
      marginBottom: 13,
    },
  },
}));
