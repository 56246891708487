import { Button, Grid } from '@mui/material';
import { SahinModal } from 'common/components/Modal/SahinModal';
import { SahinSwitch } from 'common/components/SahinSwitch/SahinSwitch';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { postAPI, updateAPI } from 'common/helper/apiHelpers';
import { forwardRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { mutate } from 'swr';
import { COMPONENT_TABLE_PATH } from '../List/PostList';
import { PostFormYup } from './PostForm.types';

export const PostForm = forwardRef<any, any>(
  ({ initialData, open, handleClose }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      getValues,
      reset,
    } = useForm<Post>({
      resolver: PostFormYup,
      mode: 'onBlur',
    });
    const { t } = useTranslation();

    useEffect(() => {
      reset(initialData || {});
    }, [initialData, reset]);

    const onSubmit = async (data: Post) => {
      let promise;
      if (initialData?.id) {
        promise = updateAPI(COMPONENT_TABLE_PATH, initialData.id, data);
      } else {
        promise = postAPI(COMPONENT_TABLE_PATH, data);
      }
      promise.then(() => mutate(COMPONENT_TABLE_PATH));
      handleClose();
    };

    return (
      <SahinModal
        open={open}
        handleClose={handleClose}
        width="50%"
        height="80%"
      >
        <Grid
          sx={{ height: '100%' }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          container
        >
          <Grid alignContent="flex-start" spacing={3} container>
            <Grid xs={12} item>
              <SahinTextField
                {...register('title')}
                error={errors?.title?.message}
                label="Başlık"
                placeholder="Başlık"
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <ReactQuill
                value={getValues('content')}
                style={{ height: 450 }}
                modules={modules}
                formats={formats}
                onChange={(value) => setValue('content', value)}
              />
            </Grid>
            <Grid mt={10} xs={12} item container>
              <Grid xs={6} item>
                <Controller
                  control={control}
                  name="enabled"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SahinSwitch
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      label={t('product.showSite')}
                      size={1}
                    />
                  )}
                />
              </Grid>
              <Grid xs={6} justifyContent="flex-end" item container>
                <Button variant="contained" type="submit">
                  {initialData?.id ? t('edit') : t('add')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SahinModal>
    );
  },
);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];
