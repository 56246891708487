import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';

export const DeliveryReturn = () => {
  const title = 'Teslimat ve İade';
  const content = `
CAYMA HAKKI

ALICI; mesafeli sözleşmenin mal satışına ilişkin olması durumunda, ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa teslim tarihinden itibaren 14 (on dört) gün içerisinde, SATICI’ya bildirmek şartıyla hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin malı reddederek sözleşmeden cayma hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir. ALICI, iş bu sözleşmeyi kabul etmekle, cayma hakkı konusunda bilgilendirildiğini peşinen kabul eder.

Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde SATICI' ya iadeli taahhütlü posta, faks veya eposta ile yazılı bildirimde bulunulması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın kullanılması halinde,

kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade edilmek istenen ürünün faturası kurumsal ise, iade ederken kurumun düzenlemiş olduğu iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)

İade formu, İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.

SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre içerisinde toplam bedeli ve ALICI’yı borç altına sokan belgeleri ALICI’ ya iade etmek ve 20 günlük süre içerisinde malı iade almakla yükümlüdür.

ALICI’ nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında SATICI’ nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI sorumlu değildir.

Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.

İadeler mutlaka orijinal kutu veya ambalajı ile birlikte yapılmalıdır.

Orijinal kutusu/ambalajı bozulmuş tekrar satılabilirlik özelliğini kaybetmiş, bir başka müşteri tarafından alınamayacak olan ürünlerin iadesi kabul edilmemektedir.

Niteliği itibari ile iade edilmeyecek ürünler; tek kullanımlık ürünler, tekstil ve kozmetik ürünlerinde iade hakkının kullanılması, ürünün ambalajının açılmamış, bozulmamış ve ürünün kullanılmamış olması şartına bağlıdır. İade etmek istediğiniz ürün ayıplı ise kargo ücreti tarafımızdan karşılanmaktadır. Sipariş esnasında ödenen, kapıda ödeme ve kargo bedelleri, ürün iade edilmek istenildiğinde tarafınıza ödenmez. Sadece ürün bedeli tarafınıza iade edilecektir.

CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER

ALICI’nın isteği veya açıkça kişisel ihtiyaçları doğrultusunda hazırlanan sunulan hizmetin kullanıldığı günler için Cayma Hakkı kullanılamaz.
`;

  return <BlogWrapper title={title} content={content} component="pre" />;
};
