import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { yup } from 'application/init/yupInit';
import { sahinAlertDialog } from 'common/components/AlertDialog/AlertDialog';
import { ForgotPassword } from 'common/components/ForgotPasswordDialog/ForgotPasswordDialog';
import { LandingHeader } from 'common/components/LandingComponents/LandingHeader';
import { LandingMiniFooter } from 'common/components/LandingComponents/LandingMiniFooter';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { commonActions } from 'common/context/CommonSlice';
import { useAppDispatch } from 'common/context/hooks';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { postAPI } from 'common/helper/apiHelpers';
import { setAxiosAuth } from 'common/helper/axiosInit';
import { createLink } from 'common/helper/createRoute';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const LoginFormYup = yupResolver(
  yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().min(5).required(),
    })
    .required(),
);

interface LoginData {
  email: string;
  password: string;
}

export const Login = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: LoginFormYup,
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const onSubmit = (data: LoginData) => {
    setLoading(true);
    postAPI(AdminTablePaths.LOGIN, data)
      .then((res) => {
        if (res?.status === 'success') {
          dispatch(commonActions.setAuth(res.data));
          setAxiosAuth(res.data.token);
          sahinAlertDialog({
            message: t('application.login.successLogin'),
            type: 'success',
          });
        } else {
          setError('email', { type: 'custom', message: res?.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen((prev) => !prev);
  };

  return (
    <>
      <Grid
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        justifyContent="space-around"
        alignContent="space-between"
        container
      >
        <LandingHeader />
        <Grid
          xs={10}
          lg={4}
          alignContent="flex-start"
          spacing={3}
          item
          container
        >
          <Grid xs={12} item>
            <SahinTextField
              {...register('email')}
              error={errors.email?.message}
              label={t('form.email.value')}
              placeholder={t('form.email.placeholder')}
              fullWidth
            />
          </Grid>
          <Grid xs={12} item>
            <SahinTextField
              {...register('password')}
              error={errors.password?.message}
              label={t('form.password.value')}
              type="password"
              placeholder={t('form.password.placeholder')}
              fullWidth
            />
          </Grid>
          <Grid xs={12} justifyContent="space-between" item container>
            <SahinButton color="text" onClick={handleForgotPasswordOpen}>
              <Typography
                fontFamily={'revert'}
                fontSize={13}
                style={{
                  textDecorationLine: 'underline',
                  textDecorationThickness: 1.5,
                }}
              >
                {t('application.login.forgotPassword')}
              </Typography>
            </SahinButton>
            <SahinButton
              variant="contained"
              loading={loading}
              disabled={loading}
              type="submit"
            >
              {t('application.login.login')}
            </SahinButton>
          </Grid>
          <Grid xs={12} justifyContent="center" item container>
            <SahinButton
              color="text"
              component={Link}
              to={createLink(ProjectRouteNames, ProjectRouteNames.REGISTER)}
            >
              <Typography
                fontFamily={'revert'}
                fontSize={13}
                style={{
                  textDecorationLine: 'underline',
                  textDecorationThickness: 1.5,
                }}
              >
                {t('application.login.notHaveAccound')}
              </Typography>
            </SahinButton>
          </Grid>
        </Grid>
        <LandingMiniFooter />
      </Grid>
      <ForgotPassword
        open={forgotPasswordOpen}
        onClose={handleForgotPasswordOpen}
      />
    </>
  );
};
