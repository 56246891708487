import {
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useStyles } from './CustomDialog.styles';
import { CustomDialogCustomUIProps } from './CustomDialog.types';

export const CustomDialogCustomUI = ({
  title,
  children,
  onClose,
  ...rest
}: CustomDialogCustomUIProps) => {
  const { classes } = useStyles();

  return (
    <Dialog
      open={true}
      className={classes.root}
      onBackdropClick={onClose}
      fullScreen
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar
          sx={{ position: 'relative', pl: '0!important', pr: '0!important' }}
        >
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            <DialogTitle className={classes.bg}>{title || '-'}</DialogTitle>
          </Typography>
          <IconButton
            sx={{ width: 50, right: -5 }}
            color="inherit"
            onClick={onClose}
          >
            <GridCloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent {...rest} className={classes.bg}>
        <Grid
          sx={{ height: '100%' }}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          container
        >
          {children}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
