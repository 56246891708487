import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';
import { tr } from './tr';

export function initI18n(): void {
  const resources = {
    en: {
      translation: en,
    },
    tr: {
      translation: tr,
    },
  };
  i18n.use(initReactI18next).init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'tr',
    resources,
  });
}
