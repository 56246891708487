import { AdminTablePaths } from 'common/model/AdminTablePaths';
import useSWR from 'swr';

type ErrorType = any;

type ResponseType<T> = {
  data: T;
  totalData: number;
  totalDataPerPage: number;
  totalPage: number;
};

export const useFetch = <ResponseDataType>(
  collectionName: AdminTablePaths,
  options?: any,
) => {
  const { data, ...rest } = useSWR<
    ResponseType<ResponseDataType>,
    ErrorType,
    AdminTablePaths
  >(collectionName, options);

  return { ...rest, ...data };
};
