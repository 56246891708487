import { commonActions } from 'common/context/CommonSlice';
import { useAppDispatch } from 'common/context/hooks';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { useEffect } from 'react';

export const ProductManager = () => {
  const dispatch = useAppDispatch();

  const { data } = useFetch<Product[]>(AdminTablePaths.CATEGORY);

  useEffect(() => {
    if (data) dispatch(commonActions.setProducts(data));
  }, [data, dispatch]);

  return <></>;
};
