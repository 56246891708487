import {
  AlternateEmailOutlined,
  PhoneOutlined,
  RoomOutlined,
} from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { LandingFooter } from 'common/components/LandingComponents/LandingFooter';
import { LandingHeader } from 'common/components/LandingComponents/LandingHeader';
import { OwnerEnv } from 'common/utils/env';

export const Contact = () => {
  return (
    <Grid justifyContent="space-around" alignContent="space-between" container>
      <LandingHeader />
      <Grid xs={10} justifyContent="space-around" item container>
        <Typography variant="h1" fontSize={34}>
          Müşteri Hizmetleri
        </Typography>
      </Grid>
      <Grid xs={10} spacing={4} item container>
        {list.map((item) => (
          <Grid key={item.key} xs={4} item container>
            <Grid
              component={Paper}
              justifyContent="center"
              alignItems="center"
              direction="column"
              p={4}
              gap={2}
              item
              container
            >
              <IconButton
                sx={{
                  background: '#2e2e2e',
                  width: 80,
                  height: 80,
                }}
              >
                {item.icon}
              </IconButton>
              <Typography>{item.content}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <LandingFooter />
    </Grid>
  );
};

const list = [
  {
    key: 1,
    icon: <PhoneOutlined fontSize="large" />,
    content: OwnerEnv.company.phone,
  },
  {
    key: 2,
    icon: <AlternateEmailOutlined fontSize="large" />,
    content: OwnerEnv.company.mail,
  },
  {
    key: 3,
    icon: <RoomOutlined fontSize="large" />,
    content: OwnerEnv.company.address,
  },
];
