import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, Grid } from '@mui/material';
import { yup } from 'application/init/yupInit';
import { postAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sahinAlertDialog } from '../AlertDialog/AlertDialog';
import { SahinButton } from '../SahinButton/SahinButton';
import { SahinTextField } from '../TextField/SahinTextField';

export const ForgotPasswordDialogFormYup = yupResolver(
  yup
    .object({
      email: yup.string().email().required(),
    })
    .required(),
);

interface ForgotPasswordDialogData {
  open: boolean;
  onClose: () => void;
}

export const ForgotPassword = ({ open, onClose }: ForgotPasswordDialogData) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    email: string;
  }>({
    resolver: ForgotPasswordDialogFormYup,
    mode: 'onBlur',
  });
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  const onSubmit = (data: { email: string }) => {
    setLoading(true);
    postAPI(AdminTablePaths.FORGOT_PASSWORD, data)
      .then((res) => {
        if (res.status === 'success') {
          sahinAlertDialog({
            message: t('application.login.forgotPasswordSuccess'),
            type: 'success',
          });
        }
      })
      .catch((err) => {
        sahinAlertDialog({
          message: t('application.login.forgotPasswordError'),
          type: 'error',
        });
      })
      .finally(() => {
        onClose();
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid
        onSubmit={handleSubmit(onSubmit)}
        sx={{ p: 2, minWidth: 400 }}
        spacing={2}
        component="form"
        container
      >
        <Grid item xs={12}>
          <SahinTextField
            {...register('email')}
            error={errors.email?.message}
            label={t('form.email.value')}
            placeholder={t('form.email.placeholder')}
            sx={{ color: 'red' }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SahinButton
            variant="contained"
            type="submit"
            loading={loading}
            disabled={loading}
            fullWidth
          >
            {t('send')}
          </SahinButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};
