import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const ProductFormYup = yupResolver(
  yup
    .object({
      name: yup.string().min(2).required(),
      price: yup.number().min(0).required(),
      description: yup.string().min(0).required(),
    })
    .required(),
);
