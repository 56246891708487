import { SahinDataTable } from 'common/components/DataTable/SahinDataTable';
import { FailedLoad } from 'common/components/FailedLoad/FailedLoad';
import { Loading } from 'common/components/Loading/Loading';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Nullable } from 'common/model/Nullable';
import { useState } from 'react';
import { PostForm } from '../Form/PostForm';
import { postListColumns } from './PostList.types';

export const COMPONENT_TABLE_PATH = AdminTablePaths.POST;

export const PostList = () => {
  const { data, error, isLoading } = useFetch<Post[]>(COMPONENT_TABLE_PATH);

  const [selectedItem, setSelectedItem] =
    useState<Nullable<Partial<Post>>>(null);

  const onAdd = () => {
    setSelectedItem({});
  };

  const onEdit = (selected: Post) => {
    setSelectedItem(selected);
  };

  const onCloseModal = () => {
    setSelectedItem(null);
  };

  if (error) return <FailedLoad />;
  if (isLoading) return <Loading />;

  return (
    <>
      <PostForm
        open={Boolean(selectedItem)}
        initialData={selectedItem}
        handleClose={onCloseModal}
      />
      <SahinDataTable
        data={data}
        columns={postListColumns}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    </>
  );
};
