import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { yup } from 'application/init/yupInit';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { postAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ResetPasswordFormYup = yupResolver(
  yup
    .object({
      newPassword: yup
        .string()
        .min(6)
        .max(20)
        .equals([yup.ref('passwordAgain')], 'Şifreler uyuşmuyor')
        .required(),
      passwordAgain: yup
        .string()
        .min(6)
        .max(20)
        .equals([yup.ref('newPassword')], 'Şifreler uyuşmuyor')
        .required(),
    })
    .required(),
);

interface ResetPasswordData {
  newPassword: string;
  passwordAgain: string;
}

export const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordData>({
    resolver: ResetPasswordFormYup,
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token] = useState(searchParams.get('token') || '');

  useEffect(() => {
    if (searchParams.get('token')) navigate('', { replace: true });
  }, [navigate, searchParams]);

  const onSubmit = (data: ResetPasswordData) => {
    const apiData = {
      token: token,
      password: data.newPassword,
    };

    postAPI(AdminTablePaths.RESET_PASSWORD, apiData).then((response) => {
      if (response?.status === 'success') {
        navigate('/login');
      }
    });
  };

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        width={500}
        height={200}
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <Typography variant="h4" component="h4" textAlign="center">
            {t('resetPassword')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SahinTextField
            {...register('newPassword')}
            error={errors.newPassword?.message}
            label={t('form.newPassword.value')}
            placeholder={t('form.newPassword.placeholder')}
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SahinTextField
            {...register('passwordAgain')}
            error={errors.passwordAgain?.message}
            label={t('form.passwordAgain.value')}
            placeholder={t('form.passwordAgain.placeholder')}
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SahinButton variant="contained" type="submit" fullWidth>
            {t('send')}
          </SahinButton>
        </Grid>
      </Grid>
    </Box>
  );
};
