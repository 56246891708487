export const OwnerEnv = {
  title: process.env.REACT_APP_BASE_TITLE,
  domain: process.env.REACT_APP_BASE_DOMAIN,
  apiUrl: process.env.REACT_APP_BACKEND_URL,
  assetsUrl: process.env.REACT_APP_ASSETS_URL,
  company: {
    name: process.env.REACT_APP_COMPANY_NAME,
    phone: process.env.REACT_APP_COMPANY_PHONE,
    mail: process.env.REACT_APP_COMPANY_MAIL,
    address: process.env.REACT_APP_COMPANY_ADDRESS,
  },
};
