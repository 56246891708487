import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useStyles } from './PropertyDialog.styles';
import { PropertyDialogCustomUIProps } from './PropertyDialog.types';

export const PropertyDialogCustomUI = ({
  title,
  data,
  onClose,
}: PropertyDialogCustomUIProps) => {
  const { classes } = useStyles();

  const parseCamelCase = (text: string) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };

  const parseData = (key: string, value: any) => {
    let result = [value];

    if (typeof value === 'object' && !Array.isArray(value)) {
      result = ['OBJECT'];
    }

    return (
      <>
        {result.map((item) => (
          <Grid key={key} item container>
            <Grid xs={3}>{parseCamelCase(key)}</Grid>
            <Grid xs={1}>:</Grid>
            <Grid xs={8}>{item}</Grid>
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Dialog open={true} className={classes.root} onBackdropClick={onClose}>
      {title && <DialogTitle className={classes.bg}>{title}</DialogTitle>}
      <DialogContent className={classes.bg}>
        <Grid rowSpacing={2} container>
          {data && Object.keys(data).map((key) => parseData(key, data[key]))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
