import { commonActions } from 'common/context/CommonSlice';
import { useAppDispatch } from 'common/context/hooks';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { useEffect } from 'react';

export const MenuManager = () => {
  const dispatch = useAppDispatch();

  const { data } = useFetch<Menu>(AdminTablePaths.MENU);

  useEffect(() => {
    if (data) dispatch(commonActions.setMenu(data));
  }, [data, dispatch]);

  return <></>;
};
