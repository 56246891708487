import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const PostFormYup = yupResolver(
  yup
    .object({
      title: yup.string().min(6).required(),
      content: yup.string().min(5).required(),
    })
    .required(),
);
