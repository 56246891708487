import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    background: '#151515',
    padding: '0 8px!important',
    paddingRight: '20px!important',
    justifyContent: 'space-between',
    '& *': { color: '#eee' },
    '& [class*=disabled]': { opacity: 0.2 },
  },
});
