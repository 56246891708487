import { GridColDef } from '@mui/x-data-grid';
import { sahinCustomDialog } from 'common/components/CustomDialog/CustomDialog';
import { Image } from 'common/components/Image/Image';
import { getCdnImageUrl } from 'common/utils/image';

export const categoryListColumns: GridColDef[] = [
  {
    field: 'image',
    headerName: '🖼️',
    width: 150,
    type: 'actions',
    renderCell: (params) => {
      return params.row?.url?.length ? (
        <Image
          src={getCdnImageUrl(params.row.url)}
          alt={params.row.name}
          height={50}
          onClick={() => {
            sahinCustomDialog({
              children: (
                <Image
                  src={getCdnImageUrl(params.row.url)}
                  alt={params.row.name}
                  aspectRatio="auto"
                />
              ),
            });
          }}
        />
      ) : (
        '#'
      );
    },
  },
  { field: 'name', headerName: 'Adı', width: 300 },
  { field: 'description', headerName: 'Açıklama', width: 450 },
];
