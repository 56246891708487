import { Divider, Grid } from '@mui/material';
import { Loading } from 'common/components/Loading/Loading';
import { useAppSelector } from 'common/context/hooks';
import { getBrandDomain, timeBetween } from 'common/utils/helper';
import { useStyles } from './ActivePlanBrandCard.styles';
import { useTranslation } from 'react-i18next';

export const ActivePlanBrandCard = ({ data }: any) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const brand = useAppSelector((state) => state.common.brand);
  const settings = useAppSelector((state) => state.common.settings);

  if (!brand) return <Loading />;
  return (
    <Grid className={classes.card} item container>
      <Grid xs={12} className={classes.infoCard} gap={2} item container>
        {settings?.store?.title && (
          <Grid item container>
            {settings.store.title}
          </Grid>
        )}
        {settings?.store?.logo && (
          <Grid item container>
            <img src={settings?.store.logo} alt={settings.store.title} />
          </Grid>
        )}
        <Divider />
        <Grid item container>
          {brand?.name}
        </Grid>
        <Grid item container>
          {getBrandDomain(brand)}
        </Grid>
        <Grid item container>
          {t('account.activeTime', { time: timeBetween(brand.createdAt) })}
        </Grid>
      </Grid>
    </Grid>
  );
};
