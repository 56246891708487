import { Grid, Typography } from '@mui/material';
import { useStyles } from './Landing.styles';
import { useResponsive } from 'common/hooks/useResponsive';

export const HowUsed = () => {
  const { classes } = useStyles();
  const { isXs } = useResponsive();

  return (
    <Grid
      xs={12}
      container
      className={classes.floor2}
      alignContent="center"
      justifyContent="center"
      flexDirection="column"
      item
      sx={{
        padding: { xs: '0 20px', md: 10 },
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        className={classes.floor2Title}
      >
        Restorant Kolay Tanıtım
      </Typography>

      <iframe
        style={
          isXs.down
            ? {
                width: '100%',
                minHeight: 'calc( 100vw * .5)',
              }
            : {
                width: 1000,
                height: 600,
              }
        }
        src="https://www.youtube.com/embed/H7oRv9HtEiA"
        title="YouTube video player"
      />
    </Grid>
  );
};
