import { Button } from '@mui/material';
import clsx from 'clsx';
import { LoadingIcon } from '../Loading/Loadingıcon';
import { useStyles } from './SahinButton.style';
import { SahinButtonProps } from './SahinButton.types';

export const SahinButton = (props: SahinButtonProps) => {
  const {
    children,
    text,
    color = 'normal',
    className,
    loading,
    ...rest
  } = props;
  const classes = useStyles();

  const buttonClasses = clsx({
    [className || '']: className,
    [classes.text]: color === 'text',
    [classes.soft]: color === 'soft',
  });

  return (
    <Button {...rest} variant="contained" className={buttonClasses}>
      {loading && <LoadingIcon size={25} />}
      {!loading && (text ?? children)}
    </Button>
  );
};
