import { Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { OrderStatus } from 'common/model/OrderStatus';
import { PaymentMethods } from 'common/model/PaymentMethods';
import { OrderStatusColor } from 'common/model/StatusColor';
import { timeBetween } from 'common/utils/helper';

export const orderListColumns: GridColDef[] = [
  {
    field: 'price',
    headerName: 'Fiyat',
    width: 70,
    renderCell: (params) => {
      return <>{params.row.price} TL</>;
    },
  },
  {
    field: 'paymentMethod',
    headerName: 'Ödeme Yöntemi',
    width: 140,
    renderCell: (params) => {
      return (
        <>
          {
            PaymentMethods?.[
              params.row.paymentMethod as keyof typeof PaymentMethods
            ]
          }
        </>
      );
    },
  },
  {
    field: 'address',
    headerName: 'Adres',
    renderCell: (params) => {
      const address = params.row.address;
      return <>{address.address}</>;
    },
    width: 200,
  },
  {
    field: 'note',
    headerName: 'Not',
    renderCell: (params) => {
      return params.row.note;
    },
    width: 100,
  },
  {
    field: 'createdAt',
    headerName: 'Sipariş Tarihi',
    width: 130,
    renderCell: (params) => {
      return <>{timeBetween(params.row.createdAt)}</>;
    },
  },
  {
    field: 'basket',
    headerName: 'Sepet',
    renderCell: (params) => {
      const basket = params.row.basket;
      return <>{basket.length} Ürün</>;
    },
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Durum',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => orderStatusDot(params.row.status),
    width: 80,
  },
];

export const orderStatusDot = (status: OrderStatus) => (
  <Chip
    sx={
      status === OrderStatus.DELIVERED
        ? {
            height: 4,
            width: 4,
          }
        : status === OrderStatus.REJECTED
        ? {
            height: 8,
            width: 8,
          }
        : { height: 20, width: 20 }
    }
    color={
      OrderStatusColor[status.toUpperCase() as keyof typeof OrderStatusColor]
    }
  />
);
