import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export interface RegisterData {
  fullName: string;
  company: string;
  email: string;
  password: string;
  phone: string;
  domain: string;
  subDomain: string;
  passwordAgain: string;
}

export const RegisterFormResolver = yupResolver(
  yup
    .object({
      fullName: yup.string().min(3).required(),
      company: yup.string().min(3).required(),
      phone: yup.string().min(10).required(),
      subDomain: yup.string().min(3).required(),
      email: yup.string().email().required().trim(),
      password: yup.string().min(5).required(),
    })
    .required(),
);

export const Step0Shema = yup
  .object({
    fullName: yup.string().min(3).required(),
    phone: yup.string().min(10).required(),
    email: yup.string().email().required().trim(),
  })
  .required();

export const Step1Shema = yup
  .object({
    password: yup.string().min(5).required(),
    passwordAgain: yup.string().min(5).required(),
  })
  .required();

export const Step2Shema = yup
  .object({
    company: yup.string().min(3).required(),
    subDomain: yup.string().min(3).required(),
  })
  .required();

export const subDomains = [
  'mehmetusta',
  'meshurdondurmaci',
  'sahanedoner',
  'ustanin-elinden',
  'hanim_eli',
  'sicakgozleme',
];
