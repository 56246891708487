import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const SettingFormYup = yupResolver(
  yup
    .object({
      logo: yup.string(),
      contact: yup.object(),
      socialMedia: yup.object(),
    })
    .required(),
);
