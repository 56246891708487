import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: '#999',
    },
    '& label.Mui-focused': {
      color: '#eee',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
      color: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: '#ddd',
        letterSpacing: '.05em',
      },
      '& input:foces': {
        color: 'red',
      },
      '& fieldset': {
        borderColor: '#3e3e3e',
      },
      '&:hover fieldset': {
        borderColor: '#5e5e5e',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#777',
      },
    },
  },
});
