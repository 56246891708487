import { createSlice } from '@reduxjs/toolkit';

interface AccountContext {
  selectedPlan?: any;
}

const initialState: AccountContext = {};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    selectPlan: (state, { payload }: { payload: any }) => {
      state.selectedPlan = payload;
    },
  },
});

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
