import { Grid } from '@mui/material';
import { SahinTabs } from 'common/components/Tab/Tab';
import { useAppSelector } from 'common/context/hooks';
import { getBrandDomain } from 'common/utils/helper';
import { CreateQr } from 'corporateIdentity/components/CreateQr/CreateQr';
import { useMemo } from 'react';

export const CorporateIdentityList = () => {
  const brand = useAppSelector((state) => state.common.brand);

  const title = useMemo(() => getBrandDomain(brand, true), [brand]);
  const url = useMemo(() => getBrandDomain(brand), [brand]);
  const filePage = useMemo(
    () => [
      {
        label: 'QR Oluştur',
        content: <CreateQr url={url} title={title} />,
      },
      // {
      //   label: 'Banner Oluştur',
      //   content: <CreateBanner url={url} title={title} />,
      // },
    ],
    [title, url],
  );

  return (
    <Grid sx={{ height: '100%', overflow: 'hidden' }} container>
      <SahinTabs data={filePage} />
    </Grid>
  );
};
