import { Grid } from '@mui/material';
import { FeatureTitle } from 'common/components/LandingComponents/FeatureTitle';
import { Features } from 'common/components/LandingComponents/Features';
import { FeaturesImages } from 'common/components/LandingComponents/FeaturesImages';
import { HowUsed } from 'common/components/LandingComponents/HowUsed';
import { LandingFooter } from 'common/components/LandingComponents/LandingFooter';
import { LandingHeader } from 'common/components/LandingComponents/LandingHeader';
import { NavSlider } from 'common/components/LandingComponents/NavSlider';
import { Plans } from 'common/components/LandingComponents/Plans';
import { WhatThatIt } from 'common/components/LandingComponents/WhatThatIt';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { createLink } from 'common/helper/createRoute';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './Landing.styles';
import { useTranslation } from 'react-i18next';
import { useResponsive } from 'common/hooks/useResponsive';
import Head from 'common/components/Head/Head';

export const LandingPage = () => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const { isMd } = useResponsive();
  const navigate = useNavigate();

  return (
    <Grid container className={cx(classes.root, 'rk')}>
      <Head />
      <LandingHeader homeMenu />
      <NavSlider />

      <WhatThatIt
        title="QR Menü'ye ihtiyacın mı var?"
        props={{
          padding: '20px 0!important',
        }}
        titleProps={{
          sx: {
            backgroundImage:
              'linear-gradient(125deg, #764ba2 0%, #1789ef 50%)!important',
            backgroundClip: 'text',
            textFillColor: 'transparent',
          },
        }}
      />
      <FeatureTitle
        title="✨ Menü Kolay ✨"
        text="Ücretsiz ve Sınırsızdır"
        buttonProps={{
          component: 'a',
          href: 'https://menukolay.com.tr',
          target: '_blank',
          text: 'Hemen Dene',
        }}
      />

      <WhatThatIt
        title={t('application.landing.whatThatItTitle')}
        buttonText={t('application.landing.whatThatItButton')}
        buttonLink={createLink(ProjectRouteNames, ProjectRouteNames.WHY_RK)}
      />

      <FeaturesImages />

      <HowUsed />
      <FeatureTitle
        title={t('application.landing.featureTitle1')}
        text={t('application.landing.featureDescription1')}
      />
      <Features />
      <FeatureTitle
        title={t('application.landing.featureTitle2')}
        text={t('application.landing.featureDescription2')}
      />

      <Plans
        style={{
          background: '#1B1919',
          padding: isMd.up ? 80 : '80px 0',
        }}
        onSelect={() => {
          navigate(createLink(ProjectRouteNames, ProjectRouteNames.REGISTER));
        }}
      />
      <LandingFooter />
    </Grid>
  );
};
