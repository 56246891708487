import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { FailedLoad } from '../FailedLoad/FailedLoad';
import { Loading } from '../Loading/Loading';

export interface BarChartsProps {
  loading: boolean;
  error: boolean;
  data: any;
  title: string;
  xKey: string;
  valueKey: string;
  barColor?: string;
  sx?: React.CSSProperties;
  valueFormatter?: (value: any) => string;
}

export const BarCharts = ({
  loading,
  error,
  data,
  title,
  xKey,
  valueKey,
  barColor,
  sx,
  valueFormatter,
}: BarChartsProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        ...sx,
      }}
    >
      <Typography variant="h6" component="div" gutterBottom>
        {title}
      </Typography>
      <ResponsiveContainer width="99%" height={300}>
        {loading ? (
          <Loading />
        ) : error ? (
          <FailedLoad />
        ) : (
          <BarChart width={500} height={300} data={data} barSize={30}>
            <XAxis
              dataKey={xKey}
              scale="point"
              padding={{ left: 20, right: 10 }}
            />
            <YAxis />
            <Tooltip
              contentStyle={{
                backgroundColor: '#333333',
              }}
              formatter={(title, name) => [
                `${valueFormatter ? valueFormatter(title) : title}`,
                t(`homeCharts.tooltipLabels.${name}`),
              ]}
            />
            {/* <CartesianGrid strokeDasharray="4" stroke="#121212" /> */}
            <Bar
              dataKey={valueKey}
              fill={barColor ?? '#1cab81'}
              background={{ fill: '#2e2e2e' }}
            />
          </BarChart>
        )}
      </ResponsiveContainer>
    </Box>
  );
};
