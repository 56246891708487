import { GridColDef } from '@mui/x-data-grid';

export const contactListColumns: GridColDef[] = [
  {
    field: 'subject',
    headerName: 'Konu',
    width: 200,
  },
  { field: 'message', headerName: 'İçerik', width: 400 },
  {
    field: 'status',
    headerName: 'Durum',
    width: 120,
    valueGetter: (params) => {
      const response = params.row.status;

      if (response === 'unread') return 'İletildi';
      else if (response === 'read') return 'Okundu';
      else return 'Cevaplandı';
    },
  },
];
