import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PaymentSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        bgcolor: '#1a1a1a',
        overflow: 'hidden',
      }}
      container
    >
      <Grid xs={10} justifyContent="center" item container>
        <Typography fontSize={25} color="success.main">
          {t('application.payment.successPay')}
        </Typography>
      </Grid>
    </Grid>
  );
};
