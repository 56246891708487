import { Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CheckStatusColor } from 'common/model/StatusColor';

const getColor = (status: boolean) => {
  return status ? CheckStatusColor.ACTIVE : CheckStatusColor.INACTIVE;
};

export const postListColumns: GridColDef[] = [
  { field: 'title', headerName: 'Başlık', width: 450 },
  { field: 'content', headerName: 'İçerik', width: 400 },
  {
    field: 'enabled',
    headerName: 'Yayında',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <Chip
          sx={{ height: 20, width: 20 }}
          color={getColor(params.row.enabled)}
        />
      );
    },
    width: 100,
  },
];
