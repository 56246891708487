import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PaymentErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        bgcolor: '#1a1a1a',
        overflow: 'hidden',
      }}
      container
    >
      <Grid xs={10} justifyContent="center" item container>
        <Grid justifyContent="center" item container>
          <Typography fontSize={25} color="error">
            {t('application.payment.errorPay')}
          </Typography>
        </Grid>
        <Box p={1} />
        <Grid justifyContent="center" item container>
          <Typography fontSize={14} color="error" component="small">
            {t('application.payment.tryAgain')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
