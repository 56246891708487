import { Box, Grid, Typography } from '@mui/material';
import { LandingFooter } from '../../../common/components/LandingComponents/LandingFooter';
import { LandingHeader } from '../../../common/components/LandingComponents/LandingHeader';

export const BlogWrapper = ({
  title,
  content,
  html,
  component = 'pre',
}: any) => {
  return (
    <>
      <LandingHeader />
      <Grid justifyContent="center" py={10} container>
        <Grid xs={6} item>
          <Typography component="h1" textAlign="center" fontSize={35}>
            {title}
          </Typography>
          <Box m={4} />
          <Grid sx={{ height: '100%', flex: 1 }} item container>
            {html ? (
              <Typography
                component={component}
                fontFamily={'-apple-system, BlinkMacSystemFont'}
                textAlign="justify"
                sx={{ textWrap: 'wrap', letterSpacing: '.05rem' }}
                dangerouslySetInnerHTML={{ __html: html }}
              ></Typography>
            ) : (
              <Typography
                component={component}
                fontFamily={'-apple-system, BlinkMacSystemFont'}
                textAlign="justify"
                sx={{ textWrap: 'wrap', letterSpacing: '.05rem' }}
              >
                {content}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <LandingFooter />
    </>
  );
};
