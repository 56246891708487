import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { GlobalCss } from 'common/components/GlobalCss/GlobalCss';
import { store } from 'common/context/store';
import { GlobalStyle, muiTheme, theme } from 'common/helper/theme';
import { initI18n } from 'common/i18n/I18n';
import { SwrManager } from 'common/managers/SwrManager';
import { mainRoutes } from 'common/utils/routes';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

initI18n();

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <GlobalStyle />
          <GlobalCss />
          <BrowserRouter>
            <SnackbarProvider maxSnack={5} dense={true}>
              <SwrManager>{mainRoutes}</SwrManager>
            </SnackbarProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
);
