export enum PaymentMethods {
  gateCreditCard = 'gateCreditCard',
  gateCash = 'gateCash',
  ticket = 'ticket',
  setcard = 'setcard',
  multinet = 'multinet',
  paye = 'paye',
  sodexo = 'sodexo',
  metropol = 'metropol',
}
