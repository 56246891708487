import { Button, FormLabel, Grid } from '@mui/material';
import { SahinModal } from 'common/components/Modal/SahinModal';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { postAPI, updateAPI } from 'common/helper/apiHelpers';
import { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { COMPONENT_TABLE_PATH } from '../List/CategoryList';
import { CategoryFormYup, categoryIcons } from './CategoryForm.types';

export const CategoryForm = forwardRef<any, any>(
  ({ initialData, open, handleClose }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm<Category>({
      resolver: CategoryFormYup,
      mode: 'onBlur',
    });

    const { t } = useTranslation();

    const [selectedIcon, setSelectedIcon] = useState<Icon | null>(null);

    useEffect(() => {
      reset(initialData || {});
      setSelectedIcon(
        categoryIcons?.find((icon) => icon.imageRoot === initialData?.url) ??
          null,
      );
    }, [initialData, reset]);

    const onSubmit = (data: Category) => {
      if (!selectedIcon) {
        return;
      }

      const requestData = {
        ...data,
        url: selectedIcon.imageRoot,
      };

      let promise;
      if (initialData?.id) {
        promise = updateAPI(COMPONENT_TABLE_PATH, initialData.id, requestData);
      } else {
        promise = postAPI(COMPONENT_TABLE_PATH, requestData);
      }
      promise.then(() => mutate(COMPONENT_TABLE_PATH));
      handleClose();
    };

    const selectIcon = (item: Icon) => () => {
      setSelectedIcon(item);
    };

    return (
      <SahinModal
        open={open}
        handleClose={handleClose}
        width="50%"
        height="80%"
      >
        <Grid
          sx={{ height: '100%' }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          container
        >
          <Grid alignContent="flex-start" spacing={3} container>
            <Grid xs={12} item>
              <SahinTextField
                {...register('name')}
                error={errors?.name?.message}
                label={t('form.notHumanName.value')}
                placeholder={t('form.notHumanName.placeholder')}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <SahinTextField
                {...register('description')}
                error={errors?.description?.message}
                label={t('form.description.value')}
                placeholder={t('form.description.placeholder')}
                multiline
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <Grid mb={2} container>
                <FormLabel>Kategori Iconu Seç</FormLabel>
              </Grid>
              <Grid
                item
                container
                sx={{
                  alignItems: 'center',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {categoryIcons?.map((icon) => (
                  <Grid
                    key={icon.name}
                    sx={{
                      padding: '5px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      bgcolor:
                        selectedIcon?.url === icon.url ? '#1cab81' : '#3a3a3a',
                    }}
                    onClick={selectIcon(icon)}
                    item
                  >
                    <img
                      title={icon.name.split('.')[0]}
                      style={{ width: '50px', height: '50px' }}
                      src={icon.url}
                      alt={icon.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid xs={12} justifyContent="flex-end" item container>
              <Button
                variant="contained"
                type="submit"
                sx={{ textTransform: 'uppercase' }}
              >
                {initialData?.id ? t('edit') : t('add')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SahinModal>
    );
  },
);
