import { Box, Grid, Typography } from '@mui/material';
import { SahinButton } from '../SahinButton/SahinButton';
import { useStyles } from './PlanCard.styles';

export const PlanCard = ({
  plan,
  color,
  onSelect,
  isClosedHoverEffect = false,
  isYearly = false,
  xs = 3,
}: any) => {
  const { classes, cx } = useStyles();

  return (
    <Grid
      item
      xs={xs}
      container
      justifyContent="start"
      flexDirection="column"
      className={cx({
        [classes.planCard]: true,
        [classes.cardHover]: !plan.disabled && !isClosedHoverEffect,
        [classes.planCardColor1]: color === 0,
        [classes.planCardColor2]: color === 1,
        [classes.planCardColor3]: color === 2,
        [classes.planCardColor4]: color === 3,
        [classes.disabled]: plan.disabled,
      })}
    >
      <div className={classes.planHeader}>
        <Typography className={classes.planTitle}>{plan.title}</Typography>
        <Typography className={classes.planDescription}>
          {plan.description}
        </Typography>
      </div>
      {(isYearly || plan.disabled) && (
        <Typography
          className={cx({
            [classes.badge]: true,
            [classes.planCardColor1]: color === 0,
            [classes.planCardColor2]: color === 1,
            [classes.planCardColor3]: color === 2,
            [classes.planCardColor4]: color === 3,
          })}
        >
          {!plan.disabled ? '%10 indirim' : 'YAKINDA'}
        </Typography>
      )}
      {plan.price && (
        <Typography className={classes.planPrice}>
          {plan.price - (isYearly ? plan.price / 10 : 0)} TL
          <Box sx={{ mr: 3, display: 'inline-block' }} />
          <small>/ AY</small>
        </Typography>
      )}
      <Typography>
        {plan.features.map((feature: string, index: number) => (
          <Typography sx={{ margin: 2 }} fontSize="0.9em" key={index}>
            ✓ {feature === 'Aylık 1.000 sipariş' ? <s>{feature}</s> : feature}
            {feature === 'Aylık 1.000 sipariş' && (
              <>
                <br />
                <small
                  style={{
                    textAlign: 'center',
                    display: 'block',
                    color: '#00000080',
                    marginTop: 10,
                  }}
                >
                  Üst paketler gelene kadar <br />
                  SINIRSIZ SİPARİŞ!
                </small>
              </>
            )}
          </Typography>
        ))}
      </Typography>
      <Grid
        alignItems="flex-end"
        justifyContent="center"
        sx={{ flex: 1 }}
        item
        container
      >
        {!plan.disabled && onSelect && (
          <SahinButton
            onClick={() => !plan.disabled && onSelect(plan)}
            variant="contained"
            color="soft"
            fullWidth
          >
            Satın Al
          </SahinButton>
        )}
      </Grid>
    </Grid>
  );
};
