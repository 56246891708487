import BallotIcon from '@mui/icons-material/Ballot';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { Button, ButtonGroup, Grid, Tooltip } from '@mui/material';
import { DataGridProps, GridColDef } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { sahinConfirmDialog } from '../ConfirmDialog/ConfirmDialog';
import { propertyDialog } from '../propertyDialog/PropertyDialog';

export type SahinDataTableProps = Omit<DataGridProps, 'rows'> & {
  data: any;
  columns: any;
  showProperty?: boolean;
  onEdit?: (data: any) => void;
  onAdd?: (data: any) => void;
  onDelete?: (data: any) => void;
  customActionComponent?: (params: any) => ReactNode;
  customActionComponentTitle?: string;
  actionButtonColumnWidth?: number;
  toolbox?: ReactNode;
};

export const generalColumns = ({
  onDelete,
  onEdit,
  customActionComponent,
  customActionComponentTitle,
  actionButtonColumnWidth,
  showProperty,
}: Partial<SahinDataTableProps>): GridColDef[] => {
  return [
    {
      ...defaultRowParams,
      field: 'id',
      headerName: 'Eylemler',
      width: actionButtonColumnWidth ?? 220,
      align: 'center',
      type: 'actions',
      renderCell: (params: any) => {
        return (
          <Grid justifyContent="space-evenly" container>
            {customActionComponent && (
              <Tooltip title={customActionComponentTitle ?? ''}>
                <>{customActionComponent(params)}</>
              </Tooltip>
            )}
            <ButtonGroup variant="outlined">
              {showProperty && (
                <Tooltip title="Property Show">
                  <Button
                    onClick={() =>
                      propertyDialog({ title: 'Property', data: params.row })
                    }
                  >
                    <BallotIcon />
                  </Button>
                </Tooltip>
              )}
              {onEdit && (
                <Tooltip title="Düzenle">
                  <Button onClick={() => onEdit(params.row)}>
                    <DriveFileRenameOutlineRoundedIcon />
                  </Button>
                </Tooltip>
              )}
              {onDelete && (
                <Tooltip title="Sil">
                  <Button
                    onClick={() => {
                      sahinConfirmDialog({
                        title: 'Silmek istedğinize emin misiniz?',
                        message: 'Silmek istedğinize emin misiniz?',
                        onConfirm: () => {
                          onDelete(params.row);
                        },
                      });
                    }}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>
              )}
              {/* <Tooltip title="Copy ID">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(params.row.id);
                    sahinAlertDialog({
                      message: 'ID kopyalandı.',
                      type: 'success',
                    });
                  }}
                >
                  <ContentCopyIcon />
                </Button>
              </Tooltip> */}
            </ButtonGroup>
          </Grid>
        );
      },
    },
  ];
};

export const defaultRowParams = {
  hide: false,
  sortable: false,
  filterable: false,
  hideable: false,
  editable: false,
  groupable: false,
  pinnable: false,
  disableColumnMenu: true,
  resizable: false,
  hideSortIcons: false,
  disableReorder: false,
  disableExport: false,
};
