import { Divider, Grid, Typography } from '@mui/material';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { createLink } from 'common/helper/createRoute';
import { OwnerEnv } from 'common/utils/env';
import { Link } from 'react-router-dom';
import { useStyles } from './Landing.styles';

const weAre = [
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.ABOUT),
    title: 'Hakkımızda',
  },
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.SOCIAL_MEDIA),
    title: 'Sosyal Medya',
  },
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.CONTACT),
    title: 'İletişim',
  },
];

const services = [
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.SSS),
    title: 'Sık Sorulan Sorular',
  },
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.DELIVERY_RETURN),
    title: 'Teslimat ve İade Şartları',
  },
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.PRIVACY_POLICY),
    title: 'Gizlilik Sözleşmesi',
  },
  {
    link: createLink(ProjectRouteNames, ProjectRouteNames.SALES_CONTRACT),
    title: 'Mesafeli Satış Sözleşmesi',
  },
];

export const LandingFooter = () => {
  const { classes } = useStyles();

  return (
    <Grid
      xs={12}
      justifyContent="center"
      className={classes.footer}
      item
      container
    >
      <Grid
        xs={12}
        lg={3}
        justifyContent="start"
        alignItems="center"
        item
        container
        flexDirection="column"
        sx={{
          justifyContent: { xs: 'center' },
          marginBottom: { xs: 4, md: 0 },
        }}
      >
        <Typography fontSize={20} sx={{ textAlign: { xs: 'center' } }}>
          {OwnerEnv.company.phone}
          <Divider sx={{ m: 1 }} />
        </Typography>
        <Typography sx={{ mt: 2, textAlign: { xs: 'center' } }}>
          {OwnerEnv.company.mail}
          <Divider sx={{ m: 1 }} />
        </Typography>
        <Typography sx={{ mt: 2, textAlign: { xs: 'center' } }}>
          <img src="/odeme.png" alt="dd" width="86%" />
        </Typography>
      </Grid>
      <Grid
        xs={6}
        lg={3}
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
        item
        container
      >
        <Typography variant="h5" letterSpacing={1.4}>
          Sayfalar
        </Typography>
        <Divider sx={{ m: 1 }} />
        {services.map((item) => (
          <Typography
            key={item.title}
            variant="caption"
            component={Link}
            to={item.link}
          >
            {item.title}
          </Typography>
        ))}
      </Grid>
      <Grid
        xs={6}
        lg={3}
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
        item
        container
      >
        <Typography variant="h5" letterSpacing={1.4}>
          Biz
        </Typography>
        <Divider sx={{ m: 1 }} />
        {weAre.map((item) => (
          <Typography
            key={item.title}
            variant="caption"
            component={Link}
            to={item.link}
          >
            {item.title}
          </Typography>
        ))}

        {/* <Typography variant="caption">Randevu Kolay</Typography> */}
        {/* <Typography variant="caption">Kervan</Typography> */}
      </Grid>
    </Grid>
  );
};
