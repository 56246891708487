import { TextField } from '@mui/material';
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals/components/PureDateInput';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { IMaskMixin } from 'react-imask';
import { useStyles } from './SahinMaskField.styles';

export type CustomProps = Omit<MuiTextFieldProps, 'error'> & {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  error?: any;
  autocomplete?: string;
};

const MaskedTextField = IMaskMixin(
  ({ inputRef, defaultValue, ...otherProps }: any) => (
    <TextField {...otherProps} inputRef={inputRef} value={defaultValue} />
  ),
);

export const SahinMaskField = forwardRef<HTMLElement, any>((props, ref) => {
  const { className, error, mask = '### ### ####', ...rest } = props;
  const classes = useStyles();

  return (
    <MaskedTextField
      {...rest}
      inputRef={ref}
      error={!!error}
      mask={mask}
      definitions={{ '#': /[0-9]/, '^': /[A-Z0-9]/ }}
      helperText={error || rest.helperText}
      color={error ? 'error' : undefined}
      className={clsx(classes.root, className)}
      overwrite={false}
    />
  );
});
