import { Cancel, Check } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { GridKeyboardArrowRight } from '@mui/x-data-grid';
import { sahinConfirmDialog } from 'common/components/ConfirmDialog/ConfirmDialog';
import { SahinDataTable } from 'common/components/DataTable/SahinDataTable';
import { FailedLoad } from 'common/components/FailedLoad/FailedLoad';
import { Loading } from 'common/components/Loading/Loading';
import { updateAPI } from 'common/helper/apiHelpers';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Nullable } from 'common/model/Nullable';
import { OrderStatus } from 'common/model/OrderStatus';
import { OrderSteps } from 'common/model/OrderSteps';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderForm } from '../Form/OrderForm';
import { useStyles } from './OrderList.styles';
import { orderListColumns, orderStatusDot } from './OrderList.types';

export const COMPONENT_TABLE_PATH = AdminTablePaths.ORDER;

export const OrderList = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data, error, isLoading, mutate } = useFetch<Order[]>(
    COMPONENT_TABLE_PATH,
    {
      refreshInterval: 1000 * 60,
    },
  );

  const [selectedItem, setSelected] = useState<Nullable<Order>>(null);

  const onEdit = (selected: Order) => {
    setSelected(selected);
  };

  const onCloseModal = () => {
    setSelected(null);
  };

  const orderStatusControl = useCallback(
    (params: any) => {
      const status = params?.row?.status as OrderStatus;

      const handleConfirm = (isAct: OrderSteps) => () => {
        if (status !== OrderStatus.DELIVERED)
          sahinConfirmDialog({
            title: t('confirmDialog.title'),
            message: t('confirmDialog.description'),
            onConfirm: () => {
              setOrderStatus(isAct);
            },
          });
      };

      const setOrderStatus = (isAct: OrderSteps) => {
        if (!data) return;

        const id = params?.row?.id;
        const changingOrder = data.find((item) => item.id === id);

        if (!changingOrder) return;

        switch (isAct) {
          case OrderSteps.APPROVAL:
            if (status === OrderStatus.PENDING)
              changingOrder.status = OrderStatus.PREPARING;
            break;
          case OrderSteps.FORWARD: {
            if (status === OrderStatus.DELIVERED) break;
            const statusIndex = Object.keys(OrderStatus).indexOf(
              status.toUpperCase(),
            );
            Object.keys(OrderStatus).forEach((item, index) => {
              if (statusIndex + 1 === index)
                changingOrder.status = item.toLowerCase() as OrderStatus;
            });
            break;
          }
          case OrderSteps.CANCEL:
            if (status !== OrderStatus.REJECTED)
              changingOrder.status = OrderStatus.REJECTED;
            break;
        }

        updateAPI(COMPONENT_TABLE_PATH, id, changingOrder).then(() => {
          mutate();
        });
      };

      return (
        <Box width={150}>
          <Grid alignItems="center" container>
            {status === OrderStatus.PENDING && (
              <>
                <IconButton
                  onClick={handleConfirm(OrderSteps.APPROVAL)}
                  className={classes.textButton}
                >
                  <Check />
                </IconButton>
                <IconButton className={classes.textButton}>
                  <Cancel onClick={handleConfirm(OrderSteps.CANCEL)} />
                </IconButton>
              </>
            )}
            {(status === OrderStatus.REJECTED ||
              status === OrderStatus.DELIVERED) && (
              <Typography textAlign="center">
                {status === OrderStatus.DELIVERED
                  ? t('orderStatus.delivered')
                  : t('orderStatus.rejected')}
              </Typography>
            )}
            {(status === OrderStatus.ON_WAY ||
              status === OrderStatus.PREPARING) && (
              <Grid
                xs={12}
                className={classes.textButton}
                onClick={handleConfirm(OrderSteps.FORWARD)}
                justifyContent="center"
                alignItems="center"
                item
                container
              >
                <Typography textAlign="center">
                  {t(`orderStatus.${status.toLowerCase()}`)}
                </Typography>
                <GridKeyboardArrowRight fontSize="medium" />
              </Grid>
            )}
          </Grid>
        </Box>
      );
    },
    [classes.textButton, t, data, mutate],
  );

  const toolbox = useMemo(
    () => (
      <Grid
        justifyContent="center"
        alignItems="center"
        columnSpacing={2}
        container
      >
        {Object.values(OrderStatus).map((item: any) => {
          return (
            <Grid key={item} alignItems="center" sx={{ opacity: 0.9 }} item>
              {orderStatusDot(item)}
              <span style={{ marginLeft: 10, fontSize: 13, color: '#eee' }}>
                {t(`orderStatus.${item}`)}
              </span>
            </Grid>
          );
        })}
      </Grid>
    ),
    [t],
  );

  if (error) return <FailedLoad />;
  if (isLoading) return <Loading />;
  return (
    <>
      <OrderForm
        open={Boolean(selectedItem)}
        initialData={selectedItem}
        handleClose={onCloseModal}
      />
      <SahinDataTable
        data={data}
        columns={orderListColumns}
        toolbox={toolbox}
        onEdit={onEdit}
        customActionComponent={orderStatusControl}
        actionButtonColumnWidth={350}
      />
    </>
  );
};
