import { accountActions } from 'account/common/context/AccountSlice';
import { AccountRouteNames } from 'account/common/model/AccountRouteNames';
import { sahinAlertDialog } from 'common/components/AlertDialog/AlertDialog';
import { Plans } from 'common/components/LandingComponents/Plans';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { useAppDispatch } from 'common/context/hooks';
import { createLink } from 'common/helper/createRoute';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const PlanList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSelectHandle = (selected: any, index: number, isYearly: boolean) => {
    console.log(selected);
    if (!Number(selected.price || 0)) {
      sahinAlertDialog({
        message: 'Ücretsiz plan satın alınamaz',
        type: 'error',
      });
      return;
    }
    dispatch(accountActions.selectPlan({ ...selected, index, isYearly }));
    navigate(createLink(AccountRouteNames, AccountRouteNames.PAYMENT));
  };

  return (
    <ListWrapper title={t('account.selectPlan')}>
      <Plans onSelect={onSelectHandle} />
    </ListWrapper>
  );
};
