import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useStyles } from './ConfirmDialog.styles';
import { ConfirmDialogCustomUIProps } from './ConfirmDialog.types';

export const ConfirmDialogCustomUI = ({
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
}: ConfirmDialogCustomUIProps) => {
  const { classes, cx } = useStyles();

  return (
    <Dialog open={true}>
      <DialogTitle className={classes.bg}>{title}</DialogTitle>
      <DialogContent className={classes.bg}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className={cx(classes.bg, classes.buttons)}>
        <Button onClick={onClose} autoFocus>
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            onConfirm();
            onClose?.();
          }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
