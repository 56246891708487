import { confirmAlert } from 'react-confirm-alert';
import { PropertyDialogProps } from './PropertyDialog.types';
import { PropertyDialogCustomUI } from './PropertyDialogCustomUI';

export const propertyDialog = ({ title, data }: PropertyDialogProps) => {
  return confirmAlert({
    customUI: ({ ...rest }) => {
      return <PropertyDialogCustomUI {...rest} title={title} data={data} />;
    },
  });
};
