import {
  Badge,
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAppSelector } from 'common/context/hooks';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './SahinDrawer.styles';
import { menuList } from './SahinDrawerList';
import { Image } from '../Image/Image';
import { OwnerEnv } from 'common/utils/env';

export const SahinDrawer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const list = useMemo(
    () => (
      <Box role="presentation">
        <Grid
          justifyContent="center"
          alignItems="center"
          sx={{
            p: 2,
            pb: 0,
          }}
          onClick={() => navigate('/')}
          container
        >
          <Image
            src="/logo.png"
            alt={OwnerEnv.title}
            aspectRatio="1009/123"
            height="100%"
          />
        </Grid>
        {menuList.map((menuGroup, index) => (
          <Fragment key={index}>
            <Typography
              sx={{
                color: '#111',
                textShadow: '1px -1px 0 #444',
                fontSize: 13,
                fontWeight: 600,
                textAlign: 'center',
                letterSpacing: '.4em',
                mb: -1,
                mt: 1,
              }}
            >
              {menuGroup.title}
            </Typography>
            <List>
              {menuGroup.menu.map((menuItem, index) => (
                <SahinListItem key={index} menuItem={menuItem} />
              ))}
            </List>
            <Divider />
          </Fragment>
        ))}
      </Box>
    ),
    [navigate],
  );

  return (
    <Drawer
      open={true}
      variant="permanent"
      anchor="left"
      classes={{
        root: classes.drawer,
        paper: classes.paper,
      }}
    >
      {list}
    </Drawer>
  );
};

const getStateValue = (data: any, key: string) => {
  const keys: string[] = key.split('.');
  return data[keys[0]][keys[1]];
};

const SahinListItem = ({ menuItem }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const isBadge = useAppSelector((state) => {
    if (!menuItem.badge) return '';
    return getStateValue(state, menuItem.badge);
  });

  const Icon = menuItem.icon;
  return (
    <ListItem
      className={classes.listItem}
      disabled={menuItem.disabled}
      onClick={() =>
        menuItem?.link ? navigate(menuItem.link) : menuItem.action?.()
      }
    >
      <ListItemButton>
        <ListItemIcon className={classes.icon}>
          <Badge badgeContent={isBadge || 0} color="error">
            <Icon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={menuItem.label} />
      </ListItemButton>
    </ListItem>
  );
};
