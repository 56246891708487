import { createSlice } from '@reduxjs/toolkit';
import { getCache, setCache } from 'common/utils/helper';
import { AdminCacheNames } from 'common/model/AdminCacheNames';
import { Nullable } from 'common/model/Nullable';

interface CommonContext {
  auth: any;
  contacts: Contact[];
  categories: Category[];
  products: Product[];
  settings?: Nullable<Setting>;
  brand?: Nullable<Brand>;
  menu?: Nullable<Menu>;
}

const initialState: CommonContext = {
  //Auth
  auth: getCache(AdminCacheNames.AUTH, null),

  // Contacts
  contacts: [],

  // Categories
  categories: [],

  // Products
  products: [],
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setContacts: (state, { payload }: { payload: Contact[] }) => {
      state.contacts = payload;
    },
    setAuth: (state, { payload }: { payload: any }) => {
      setCache(AdminCacheNames.AUTH, payload);
      state.auth = payload;
    },
    setCategories: (state, { payload }: { payload: Category[] }) => {
      state.categories = payload;
    },
    setProducts: (state, { payload }: { payload: Product[] }) => {
      state.products = payload;
    },
    setSettings: (state, { payload }: { payload: Setting }) => {
      state.settings = payload;
    },
    setBrand: (state, { payload }: { payload: Brand }) => {
      state.brand = payload;
    },
    setMenu: (state, { payload }: { payload: Menu }) => {
      state.menu = payload;
    },
  },
});

export const commonActions = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
