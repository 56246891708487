import { Grid, Typography } from '@mui/material';
import { useStyles } from './Landing.styles';
import { SahinButton } from '../SahinButton/SahinButton';
import { SahinButtonProps } from '../SahinButton/SahinButton.types';
import { Link } from 'react-router-dom';

export const FeatureTitle = ({
  title,
  text,
  buttonText,
  buttonLink,
  buttonProps,
}: {
  title: string;
  text: string;
  buttonText?: string;
  buttonLink?: string;
  buttonProps?: SahinButtonProps;
}) => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignContent="center"
      className={classes.floor5}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
      >
        <Typography
          variant="h4"
          className={classes.useCasesTitle}
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography variant="body2" fontSize="1.5rem" mt={2} textAlign="center">
          {text}
        </Typography>
        {(buttonProps || buttonLink || buttonText) && (
          <SahinButton
            color="soft"
            component={Link}
            to={buttonLink}
            {...buttonProps}
            sx={{ ...buttonProps?.sx, mt: 5, p: 2 }}
          >
            {buttonText}
          </SahinButton>
        )}
      </Grid>
    </Grid>
  );
};
