import { FormControl, FormLabel } from '@mui/material';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { SurveyType } from 'common/model/Survey';

type TextQuestionType = Pick<
  SurveyType['questions'][0],
  'id' | 'text' | 'type' | 'required'
>;

export const Text = ({
  data,
  onChange,
}: {
  data: Readonly<TextQuestionType>;
  onChange?: (value: string) => void;
}) => {
  const handleChange = (event: any) => {
    onChange?.(event?.target?.value);
  };

  return (
    <FormControl fullWidth>
      <FormLabel>{data.text}</FormLabel>
      <SahinTextField
        onChange={handleChange}
        required={data.required}
        multiline
      />
    </FormControl>
  );
};
