import { CardMedia, Grid, Typography } from '@mui/material';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { useResponsive } from 'common/hooks/useResponsive';
import { useStyles } from './Landing.styles';
import { Link } from 'react-router-dom';
import { createLink } from 'common/helper/createRoute';

export const NavSlider = () => {
  const { classes } = useStyles();
  const { isMd } = useResponsive();

  return (
    <Grid justifyContent="center" container>
      <Grid
        xs={10}
        className={classes.navSlider}
        justifyContent="center"
        alignContent="center"
        item
        container
      >
        <Grid
          lg={5}
          md={12}
          alignItems="start"
          flexDirection="column"
          item
          container
        >
          <Typography variant="h4" className={classes.incomingPageTitle}>
            Kendi siteninin sahibi ol komisyonlardan kurtul
          </Typography>
          <Typography variant="body2" className={classes.incomingPageInfo}>
            Benim restorantım uygulaması ile kendi sitenizi oluşturun <br />
            satış yapmaya hemen başlayın.
          </Typography>
          <SahinButton
            href={ProjectRouteNames.REGISTER}
            className={classes.incomingPageButtonNowStart}
          >
            Hemen Başla
          </SahinButton>
          <SahinButton
            className={classes.incomingPageButtonMoreInfo}
            color="soft"
            component={Link}
            to={createLink(ProjectRouteNames, ProjectRouteNames.SSS)}
          >
            Daha Fazla Bilgi Edin
          </SahinButton>
        </Grid>
        {isMd.up && (
          <Grid xs={false} lg={7} item>
            <CardMedia
              component="video"
              image="/trailer.webm"
              sx={{ width: '100%', borderRadius: '10px' }}
              muted
              loop
              autoPlay
              playsInline
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
