import { SahinDataTable } from 'common/components/DataTable/SahinDataTable';
import { FailedLoad } from 'common/components/FailedLoad/FailedLoad';
import { Loading } from 'common/components/Loading/Loading';
import { deleteAPI } from 'common/helper/apiHelpers';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Nullable } from 'common/model/Nullable';
import { useState } from 'react';
import { mutate } from 'swr';
import { ProductForm } from '../Form/ProductForm';
import { productListColumns } from './ProductList.types';

export const COMPONENT_TABLE_PATH = AdminTablePaths.PRODUCT;

export const ProductList = () => {
  const { data, error, isLoading } = useFetch<Product[]>(COMPONENT_TABLE_PATH);

  const [selectedItem, setSelectedItem] =
    useState<Nullable<Partial<Product>>>(null);

  const onAdd = () => {
    setSelectedItem({ isActive: true });
  };

  const onEdit = (selected: Product) => {
    setSelectedItem(selected);
  };

  const onDelete = (selected: Product) => {
    deleteAPI(COMPONENT_TABLE_PATH, selected.id)
      .then(() => {
        mutate(COMPONENT_TABLE_PATH);
      })
      .catch((error) => console.error(error));
  };

  const onCloseModal = () => {
    setSelectedItem(null);
  };

  if (error) return <FailedLoad />;
  if (isLoading) return <Loading />;

  return (
    <>
      <ProductForm
        open={Boolean(selectedItem)}
        initialData={selectedItem}
        handleClose={onCloseModal}
      />
      <SahinDataTable
        data={data}
        columns={productListColumns}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};
