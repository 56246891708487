import { Check } from '@mui/icons-material';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccountRouteNames } from 'account/common/model/AccountRouteNames';
import { SahinDropdown } from 'common/components/Dropdown/SahinDropdown';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { Loading } from 'common/components/Loading/Loading';
import { SahinMaskField } from 'common/components/MaskField/SahinMaskField';
import { PlanCard } from 'common/components/PlanCard/PlanCard';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { CommonTablePaths } from 'common/contact/common/model/CommonTablePaths';
import { useAppSelector } from 'common/context/hooks';
import { cities } from 'common/data/Cities';
import { postAPI } from 'common/helper/apiHelpers';
import { createLink } from 'common/helper/createRoute';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaymentFormYup } from './PaymentForm.types';

interface Payment {
  user: {
    id: string;
    name: string;
    surname: string;
    phone: string;
    email: string;
    identity: string;
  };
  address: {
    city: string;
    address: string;
  };
  refCode?: string;
}
export const PaymentForm = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Payment>({
    resolver: PaymentFormYup,
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedPlan = useAppSelector((state) => state.account.selectedPlan);
  const [form, setForm] = useState<any>(null);
  const [refCode, setRefCode] = useState({
    code: '',
    verify: false,
    isChange: true,
  });

  const onSubmit = (data: any) => {
    setForm({ loading: true });
    const formData = {
      address: data.address,
      buyer: data.user,
      plan: selectedPlan,
      refCode: refCode.code,
    };

    postAPI(AdminTablePaths.PAYMENT, formData).then((item) => {
      setForm(item.data);
    });
  };

  useEffect(() => {
    if (!selectedPlan) {
      navigate(createLink(AccountRouteNames, AccountRouteNames.PLAN));
    }
  }, [navigate, selectedPlan]);

  if (!selectedPlan) return <></>;

  const totalPrice = selectedPlan.price * (selectedPlan.isYearly ? 12 : 1);
  return (
    <ListWrapper title={t('account.donePay')}>
      <Grid
        sx={{ height: '100%' }}
        spacing={8}
        alignContent="center"
        justifyContent="center"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
      >
        <Grid xs={3} alignContent="center" item container>
          <PlanCard
            xs={12}
            plan={selectedPlan}
            color={selectedPlan?.index}
            isYearly={selectedPlan.isYearly}
            isClosedHoverEffect
          />
        </Grid>
        <Grid xs={9} alignContent="center" item container>
          <Grid container>
            <Alert
              severity="warning"
              sx={{ width: '100%', mb: 5, letterSpacing: 1 }}
            >
              Otomatik ödeme talimatı değildir!
              <Box p={0.5} />
              Paket bitiminde tekrar paket alımı yapmanız gerekir!
            </Alert>
          </Grid>
          {form?.paymentUrl && (
            <iframe
              title="ödeme"
              src={`${form.paymentUrl}&iframe=true`}
              frameBorder="0"
              width="100%"
              height="800px"
            />
          )}
          {form && !form?.paymentUrl && <Loading />}
          {!form && (
            <Grid xs={12} spacing={3} item container>
              <Grid xs={6} item>
                <SahinTextField
                  {...register(`user.name`)}
                  error={errors?.user?.name?.message}
                  label={t('form.name.value')}
                  placeholder={t('form.name.placeholder')}
                  fullWidth
                />
              </Grid>
              <Grid xs={6} item>
                <SahinTextField
                  {...register(`user.surname`)}
                  error={errors?.user?.surname?.message}
                  label={t('form.surname.value')}
                  placeholder={t('form.surname.placeholder')}
                  fullWidth
                />
              </Grid>
              <Grid xs={6} item>
                <SahinMaskField
                  {...register(`user.phone`)}
                  error={errors?.user?.phone?.message}
                  label={t('form.phone.value')}
                  placeholder={t('form.phone.placeholder')}
                  fullWidth
                />
              </Grid>
              <Grid xs={6} item>
                <SahinTextField
                  {...register(`user.email`)}
                  error={errors?.user?.email?.message}
                  label={t('form.email.value')}
                  placeholder={t('form.email.placeholder')}
                  fullWidth
                />
              </Grid>
              <Grid xs={6} item>
                <SahinMaskField
                  {...register(`user.identity`)}
                  error={errors?.user?.identity?.message}
                  label={t('form.identity.value')}
                  mask={'###########'}
                  placeholder={t('form.identity.placeholder')}
                  fullWidth
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  control={control}
                  name="address.city"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SahinDropdown
                      error={errors?.address?.city?.message}
                      label={t('form.city.value')}
                      data={Object.values(cities).map((item) => ({
                        label: item,
                        key: item,
                      }))}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid xs={6} item>
                <Grid p={2} pt={0} container>
                  <Typography fontSize={14}>
                    Referans Kodunu sadece ilk paketinizi alırken
                    kullanabilirsiniz ! Size{' '}
                    <Link color="secondary">2 Ay Ücretsiz</Link> mevcut
                    pakettinizden kullanım hakkı tanımlanır.
                  </Typography>
                </Grid>
                <Grid alignItems="center" columnSpacing={2} item container>
                  <Grid xs={10} item>
                    <SahinMaskField
                      // {...register(`refCode`)}
                      // error={errors?.refCode?.message}
                      label={t('form.refCode.value')}
                      placeholder={t('form.refCode.placeholder')}
                      mask={'^^^^^^'}
                      error={
                        refCode.code &&
                        !refCode.verify &&
                        (refCode.isChange
                          ? 'Kodu Doğrulayın!'
                          : 'Doğrulanamadı (farklı bir kod deneyin)')
                      }
                      onChange={(event: any) => {
                        setRefCode({
                          code: event.target.value.toUpperCase(),
                          verify: false,
                          isChange: true,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={2} item>
                    <Tooltip title="Doğrula">
                      <IconButton
                        size="large"
                        disabled={!refCode.isChange}
                        onClick={() => {
                          if (refCode.code.length !== 6) return;
                          postAPI(CommonTablePaths.REFERENCE_CHECK, {
                            code: refCode,
                          }).then((res) => {
                            setRefCode((prevState) => ({
                              ...prevState,
                              verify: res?.data?.verify,
                              isChange: false,
                            }));
                          });
                        }}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                {refCode.verify && (
                  <Grid p={2} gap={1} alignItems="center" container>
                    <Typography fontSize={12} color="secondary" align="center">
                      <Check />
                    </Typography>
                    <Typography fontSize={12} color="secondary" align="center">
                      DOĞRULANDI!
                    </Typography>
                  </Grid>
                )}
                {!refCode.code && (
                  <Grid p={2} pt={0} container>
                    <Typography fontSize={12} color="text.disabled">
                      - zorunlu değildir
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid xs={6} item>
                <SahinTextField
                  {...register(`address.address`)}
                  error={errors?.address?.address?.message}
                  label={t('form.address.value')}
                  placeholder={t('form.address.placeholder')}
                  rows={4}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid
                xs={12}
                justifyContent="space-between"
                alignItems="flex-start"
                item
                container
              >
                <Grid
                  xs={6}
                  sx={{ p: 2, background: '#2a2a2a' }}
                  spacing={1}
                  component={Paper}
                  item
                  container
                >
                  <Grid spacing={2} item container>
                    <Grid xs={3} item>
                      {selectedPlan.isYearly ? '12' : '1'} {t('month')}
                    </Grid>
                    <Grid xs={5} item>
                      {selectedPlan.title} {t('account.package')}
                    </Grid>
                    <Grid xs={4} item>
                      {selectedPlan.price} TL
                    </Grid>
                  </Grid>
                  <Box sx={{ m: 1 }} />
                  <Grid spacing={2} item container>
                    <Grid xs={8} item>
                      {t('account.totalPrice')}
                    </Grid>
                    <Grid xs={4} item>
                      {totalPrice} TL
                    </Grid>
                  </Grid>
                  <Grid
                    spacing={2}
                    sx={{ color: 'text.success' }}
                    item
                    container
                  >
                    <Grid xs={8} item>
                      {t('account.discount', { discount: '10' })}
                    </Grid>
                    <Grid xs={4} item>
                      {selectedPlan.isYearly ? totalPrice * 0.1 : totalPrice} TL
                    </Grid>
                  </Grid>
                  <Grid spacing={2} item container>
                    <Grid xs={8} item>
                      {t('account.totalPay')}:
                    </Grid>
                    <Grid xs={4} item>
                      {selectedPlan.isYearly ? totalPrice * 0.9 : totalPrice} TL
                    </Grid>
                  </Grid>
                </Grid>
                <SahinButton type="submit" disabled={!isValid}>
                  {t('account.donePay')}
                </SahinButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ListWrapper>
  );
};

/*
/**
 KART BİLGİLERİ FORM

<Grid xs={6} spacing={3} item container>
  <Grid xs={12} item>
    <SahinTextField
      {...register(`contact.cardNo`)}
      label={'cardNo'}
      placeholder={`Enter card no`}
      fullWidth
    />
  </Grid>
  <Grid xs={7} item>
    <SahinTextField
      {...register(`contact.cardNo`)}
      label={'cardNo'}
      placeholder={`Enter card no`}
      fullWidth
    />
  </Grid>
  <Grid xs={5} item>
    <SahinTextField
      {...register(`contact.cvv`)}
      label={'cvv'}
      placeholder={`Enter cvv`}
      fullWidth
    />
  </Grid>
  <Grid xs={12} item>
    <SahinTextField
      {...register(`contact.cardNo`)}
      label={'cardNo'}
      placeholder={`Enter card no`}
      fullWidth
    />
  </Grid>
  <Grid xs={12} justifyContent="flex-end" item container>
    <SahinButton type="submit" disabled={!isValid}>
      KAYDET
    </SahinButton>
  </Grid>
</Grid>
*/
