import { createSlice } from '@reduxjs/toolkit';
import { diffNewData } from 'common/utils/helper';
import { AdminCacheNames } from 'common/model/AdminCacheNames';

interface SahinYazilimContext {
  // Product
  products: Product[];
  lastSeedProduct: number;
  notSeedProductCount: number;
}

const initialState: SahinYazilimContext = {
  // Product
  products: [],
  lastSeedProduct: JSON.parse(
    localStorage.getItem(AdminCacheNames.LAST_SEED_PRODUCT) ?? '0',
  ),
  notSeedProductCount: 0,
};

const sahinYazilimSlice = createSlice({
  name: 'sahinyazilim',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
      state.notSeedProductCount = diffNewData(
        state.products,
        state.lastSeedProduct,
        AdminCacheNames.LAST_SEED_PRODUCT,
      );
    },
  },
});

export const sahinYazilimActions = sahinYazilimSlice.actions;
export const sahinYazilimReducer = sahinYazilimSlice.reducer;
