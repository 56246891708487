import { axios } from 'common/helper/axiosInit';
import { yelkovan } from 'common/helper/momentInit';
import { AdminCacheNames } from 'common/model/AdminCacheNames';
import { Nullable } from 'common/model/Nullable';
import { DurationInputArg2, Moment } from 'moment';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;

export const diffNewData = (
  data: any[],
  last: number,
  cacheName: AdminCacheNames,
): number => {
  const count = data.filter((product) => {
    return (product.updateDate || 1) > (last || 0);
  }).length;
  if (last) {
    localStorage.setItem(cacheName, JSON.stringify(last));
  }
  return count;
};

export const getCache = (path: AdminCacheNames, defaultValue?: any): any => {
  const stringJson = localStorage.getItem(path);
  return JSON.parse(stringJson ?? '0') || defaultValue;
};

export const setCache = (path: AdminCacheNames, data: any): void => {
  if (!data) {
    localStorage.removeItem(path);
    return;
  }
  localStorage.setItem(path, JSON.stringify(data));
};

export const fetcher = (resource: string, options?: any) => {
  let currentResource: RequestInfo | URL = resource;
  if (
    typeof currentResource === 'string' &&
    !currentResource.startsWith('http')
  ) {
    currentResource = `${BACKEND_API_URL}/${currentResource}`;
  }

  return axios.get(currentResource).then((res) => {
    if (res.data.status) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  });
};

export const generateID = () =>
  (Math.random() * 1000).toString(16).replace('.', '');

export const groupBy = (xs: any[], key: string) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const timeBetween = (day: Date | Moment) => {
  const now = yelkovan(Date.now());
  const second = yelkovan(day);
  const diff = now.diff(second, 'minute');

  if (diff < 60) {
    return `${diff} dakika`;
  }

  if (diff < 24 * 60) {
    const hours = Math.floor(diff / 60).toFixed(0);
    const minutes = ((diff - Math.floor(diff / 60)) % 60).toFixed(0);
    return `${hours} saat ${minutes} dakika`;
  }

  const days = Math.floor(diff / (24 * 60));
  const hours = Math.floor((diff - days * 24 * 60) / 60);

  return `${days} gün ${hours} saat`;
};

export const datesBetween = (
  day1: Date | Moment,
  day2: Date | Moment,
  unit: DurationInputArg2,
) => {
  const now = yelkovan(day1);
  const second = yelkovan(day2);
  const diff = now.diff(second, unit);
  return diff;
};

export const dateFormatDayMonthTime = (day: Date | Moment) => {
  return yelkovan(day).format('D MMMM, HH:mm');
};

export const dateFormatDayMonth = (day: Date | Moment) => {
  return yelkovan(day).format('D MMMM');
};

export const dateFormatDayMonthYear = (day: Date | Moment) => {
  return yelkovan(day).format('D MMMM YYYY');
};

export const dateSum = (
  day: Date | Moment,
  amount: number,
  unit: DurationInputArg2,
) => yelkovan(day).add(amount, unit);

export const trimAllSpace = (value?: string) => {
  if (!value) return '';
  return value.replaceAll(' ', '');
};

export const phoneCountryFormat = (
  phone?: string,
  isStartWithCountryCode = true,
) => {
  if (!phone) return phone;
  const result = trimAllSpace(phone);
  if (result.length !== 10) {
    console.error(result + ' lenght error!');
  }
  return isStartWithCountryCode ? `90${result}` : result;
};

export const phoneTurkeyFormat = (phone?: string, isStartWithZero = true) => {
  let result = phoneCountryFormat(phone);
  if (!result) return phone;
  result = [
    result.substring(2, 5),
    result.substring(5, 8),
    result.substring(8, 12),
  ].join(' ');

  return isStartWithZero ? `0${result}` : result;
};

export const getBrandDomain = (brand?: Nullable<Brand>, isTitle?: boolean) => {
  const domainName = brand?.subdomain;
  const { protocol, host } = window.location;
  const domain = `${domainName}.${host}`;
  if (isTitle) return domain;
  return `${protocol}//${domain}`;
};

export const currencyFormat = (currency?: number, precision = 2) => {
  if (!currency) return '-';
  const formatter = new Intl.NumberFormat('tr-TR', {
    // style: 'currency',
    currency: 'TRY',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: precision, // (causes 2500.99 to be printed as $2,501)
    // localeMatcher: "",
    // style: "",
    // currencySign: "",
    // useGrouping: false,
    // minimumIntegerDigits: 0,
    // minimumFractionDigits: 2,
    // maximumFractionDigits: 2,
    // minimumSignificantDigits: 2,
    // maximumSignificantDigits: 2,
  });

  return `${formatter.format(currency)} ₺`;
  // return `${currency.toFixed(precision)} ₺`;
};
