import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  text: {
    background: 'transparent!important',
    boxShadow: 'none!important',
  },
  soft: {
    background: 'rgba(0, 0, 0, .2)!important',
    boxShadow: 'none!important',
  },
});
