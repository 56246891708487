import { Grid } from '@mui/material';
import { JustText } from './JustText';

export const TodayWeekMonth = ({
  loading,
  error,
  today,
  weekly,
  monthly,
  todayTitle,
  weeklyTitle,
  monthlyTitle,
}: {
  loading: boolean;
  error: boolean;
  today?: string;
  weekly?: string;
  monthly?: string;
  todayTitle?: string;
  weeklyTitle?: string;
  monthlyTitle?: string;
}) => {
  const justTextWeight =
    12 / [today, weekly, monthly].filter((item) => item !== undefined).length;

  return (
    <Grid container spacing={1}>
      {today && (
        <Grid item xs={justTextWeight}>
          <JustText
            title={todayTitle ?? 'Today'}
            loading={loading}
            error={error}
            text={`${today}`}
          />
        </Grid>
      )}
      {weekly && (
        <Grid item xs={justTextWeight}>
          <JustText
            title={weeklyTitle ?? 'Weekly'}
            loading={loading}
            error={error}
            text={`${weekly}`}
          />
        </Grid>
      )}
      {monthly && (
        <Grid item xs={justTextWeight}>
          <JustText
            title={monthlyTitle ?? 'Monthly'}
            loading={loading}
            error={error}
            text={`${monthly}`}
          />
        </Grid>
      )}
    </Grid>
  );
};
