import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import { SurveyType } from 'common/model/Survey';
import { useState } from 'react';

type OptionQuestionType = SurveyType['questions'][0];

export const Options = ({
  data,
  onChange,
}: {
  data: OptionQuestionType;
  onChange?: (answer: number[]) => void;
}) => {
  const [checked, setChecked] = useState<number[]>([]);

  const handleChange = (id: number) => () => {
    if (data.selectionType === 'single' || data.type === 'multiple_choice') {
      if (checked.includes(id)) setChecked([]);
      else setChecked([id]);

      onChange?.([id]);
    } else if (data.selectionType === 'multiple') {
      if (checked.includes(id))
        setChecked(checked.filter((item) => item !== id));
      else setChecked([...checked, id]);

      onChange?.([...checked, id]);
    }
  };

  return (
    <FormControl>
      <FormLabel>{data.text}</FormLabel>
      <FormGroup>
        {data?.options?.map((option) => {
          return (
            <FormControlLabel
              key={option.id}
              label={option.text}
              checked={!!checked.includes(option.id)}
              onChange={handleChange(option.id)}
              control={
                <Checkbox required={checked.length === 0 && data.required} />
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};
