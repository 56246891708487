import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const ProductFormYup = yupResolver(
  yup
    .object({
      status: yup.string().required(),
    })
    .required(),
);
