import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useStyles } from './ListTopBar.styles';

export const ListTopBar = ({ onAdd, onDelete, selected, toolbox }: any) => {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar className={classes.root}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            {onAdd && (
              <IconButton onClick={onAdd}>
                <ControlPointRoundedIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            {onDelete && (
              <IconButton
                onClick={() => onDelete(selected)}
                disabled={!selected}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid justifyContent="end" container>
          {toolbox}
        </Grid>
        {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
           */}
      </Toolbar>
    </AppBar>
  );
};

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       width: '12ch',
//       '&:focus': {
//         width: '20ch',
//       },
//     },
//   },
// }));
