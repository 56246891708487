import { Box, Button, Grid, Typography } from '@mui/material';
import { SahinDropdown } from 'common/components/Dropdown/SahinDropdown';
import { SahinModal } from 'common/components/Modal/SahinModal';
import { SahinSwitch } from 'common/components/SahinSwitch/SahinSwitch';
import { SelectFile } from 'common/components/SelectFile/SelectFile';
import { SelectFileRef } from 'common/components/SelectFile/SelectFile.types';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { useAppSelector } from 'common/context/hooks';
import { postAPI, updateAPI } from 'common/helper/apiHelpers';
import { createLink } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { getCdnImageUrl } from 'common/utils/image';
import { forwardRef, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { mutate } from 'swr';
import { COMPONENT_TABLE_PATH } from '../List/ProductList';
import { ProductFormYup } from './ProductForm.types';

export const ProductForm = forwardRef<any, any>(
  ({ initialData, open, handleClose }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      reset,
    } = useForm<Product>({
      resolver: ProductFormYup,
      mode: 'onBlur',
    });
    const { t } = useTranslation();
    const categories = useAppSelector((state) => state.common.categories);

    const fileManager = useRef<SelectFileRef>(null);

    useEffect(() => {
      reset(initialData || {});
    }, [initialData, reset]);

    const onSubmit = async (data: Product) => {
      const fileUpload = await fileManager.current?.fileUpload();
      if (!fileUpload) return;
      data.images = fileUpload;
      let promise;
      if (initialData?.id) {
        promise = updateAPI(COMPONENT_TABLE_PATH, initialData.id, data);
      } else {
        promise = postAPI(COMPONENT_TABLE_PATH, data);
      }
      promise.then(() => mutate(COMPONENT_TABLE_PATH));
      handleClose();
    };

    return (
      <SahinModal
        open={open}
        handleClose={handleClose}
        width="50%"
        height="80%"
      >
        <Grid
          sx={{ height: '100%' }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          container
        >
          <Grid alignContent="flex-start" spacing={3} container>
            <Grid xs={6} justifyContent="center" item container>
              <Controller
                control={control}
                name="isActive"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <SahinSwitch
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    label={t('product.showSite')}
                    size={1}
                  />
                )}
              />
            </Grid>
            <Grid xs={6} justifyContent="center" item container>
              <Controller
                control={control}
                name="isPopular"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <SahinSwitch
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    label={t('product.popularTagActive')}
                    size={1}
                  />
                )}
              />
            </Grid>
            <Box p={2} />
            <Grid xs={12} item>
              <SahinTextField
                {...register('name')}
                error={errors?.name?.message}
                label={t('form.notHumanName.value')}
                placeholder={t('form.notHumanName.placeholder')}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <SahinTextField
                {...register('price')}
                error={errors?.price?.message}
                label={t('form.price.value')}
                placeholder={t('form.price.placeholder')}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <Controller
                control={control}
                name="categoryId"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <SahinDropdown
                    // {...register(`categoryId`)}
                    error={errors?.categoryId?.message}
                    label={t('category.category')}
                    data={categories?.map((item) => ({
                      key: item.id,
                      label: item.name,
                    }))}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />

              {categories === null && (
                <Typography
                  component={Link}
                  to={createLink(AdminRouteNames, AdminRouteNames.CATEGORY)}
                >
                  {t('form.category.category')}
                </Typography>
              )}
            </Grid>
            <Grid xs={12} item>
              <SahinTextField
                {...register('description')}
                error={errors?.description?.message}
                label={t('form.description.value')}
                placeholder={t('form.description.placeholder')}
                aria-multiline
                rows={2}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <SelectFile
                {...register('images')}
                ref={fileManager}
                containerProps={{ width: '100%' }}
                onChange={undefined}
                error={errors?.images?.message}
                value={initialData?.images}
                previewValue={initialData?.images?.map((item: string) =>
                  getCdnImageUrl(item),
                )}
                showPreview
              />
            </Grid>
            <Grid xs={12} justifyContent="flex-end" item container>
              <Button variant="contained" type="submit">
                {initialData?.id ? t('edit') : t('add')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </SahinModal>
    );
  },
);
