import {
  AddBusinessOutlined,
  BusinessOutlined,
  CategoryOutlined,
  ChatOutlined,
  ClearAllOutlined,
  HomeOutlined,
  PowerSettingsNewOutlined,
  QrCode2Outlined,
  SettingsOutlined,
  ViewCarouselOutlined,
} from '@mui/icons-material';
import { AccountRouteNames } from 'account/common/model/AccountRouteNames';
import { CommonRouteNames } from 'common/contact/common/model/CommonRouteNames';
import { commonActions } from 'common/context/CommonSlice';
import { store } from 'common/context/store';
import { createLink } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { DrawerMenuList } from './SahinDrawer.types';

export const menuList: DrawerMenuList[] = [
  {
    title: '',
    menu: [
      {
        label: 'Raporlar',
        link: createLink(AdminRouteNames, AdminRouteNames.HOME),
        icon: HomeOutlined,
      },
      {
        label: 'Siparişler',
        link: createLink(AdminRouteNames, AdminRouteNames.ORDER),
        icon: AddBusinessOutlined,
      },
      {
        label: 'Ürünler',
        link: createLink(AdminRouteNames, AdminRouteNames.PRODUCT),
        icon: CategoryOutlined,
        badge: 'sahinyazilim.notSeedProductCount',
      },
      {
        label: 'Kategoriler',
        link: createLink(AdminRouteNames, AdminRouteNames.CATEGORY),
        icon: ClearAllOutlined,
      },
      // {
      //   label: 'Üyeler',
      //   link: createLink(AdminRouteNames, AdminRouteNames.USER),
      //   icon: Person3,
      // },
    ],
  },
  {
    title: '',
    menu: [
      {
        label: 'İletişim Talepleri',
        link: createLink(CommonRouteNames, CommonRouteNames.CONTACT),
        icon: ChatOutlined,
      },
      {
        label: 'Anasayfa Slider',
        link: createLink(AdminRouteNames, AdminRouteNames.SLIDER),
        icon: ViewCarouselOutlined,
      },
    ],
  },
  {
    title: '',
    menu: [
      {
        label: 'Hesap Yönetimi',
        link: createLink(AccountRouteNames, AccountRouteNames.PLAN),
        icon: BusinessOutlined,
      },
      {
        label: 'QR Editör',
        link: createLink(AdminRouteNames, AdminRouteNames.CORPORATEL_IDENTITY),
        icon: QrCode2Outlined,
      },
      {
        label: 'Ayarlar',
        link: createLink(AdminRouteNames, AdminRouteNames.SETTING),
        icon: SettingsOutlined,
      },
    ],
  },
  {
    title: '',
    menu: [
      {
        label: 'Çıkış',
        action: () => store.dispatch(commonActions.setAuth(null)),
        icon: PowerSettingsNewOutlined,
      },
    ],
  },
];
