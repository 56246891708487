import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';

export const Image = ({
  src,
  alt,
  width,
  height,
  aspectRatio, // = '1/1'
  ...rest
}: any) => {
  const [visible, setVisible] = useState(false);

  const onLoad = () => {
    setVisible(true);
  };

  return (
    <>
      {src && !visible && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
      <Grid
        {...rest}
        sx={{
          width,
          height,
          aspectRatio,
          ...((!visible && {
            visibility: 'hidden',
            height: 0,
          }) ||
            {}),
        }}
      >
        <img
          src={src}
          alt={alt}
          onLoad={onLoad}
          style={{
            ...(width > height ? { width } : { height }),
            maxWidth: width,
            objectFit: 'cover',
          }}
        />
      </Grid>
    </>
  );
};
