import { Grid, GridProps, Typography, TypographyProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { SahinButton } from '../SahinButton/SahinButton';
import { SahinButtonProps } from '../SahinButton/SahinButton.types';
import { useStyles } from './Landing.styles';

export const WhatThatIt = ({
  title,
  text,
  buttonText,
  buttonLink,
  buttonProps,
  props,
  titleProps,
}: {
  title: string;
  text?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonProps?: SahinButtonProps;
  props?: GridProps;
  titleProps?: TypographyProps;
}) => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.floor4}
      {...props}
    >
      <Typography variant="h4" textAlign="center" {...titleProps}>
        {title}
      </Typography>
      {text && (
        <Typography variant="body2" fontSize="1.5rem" mt={1} textAlign="center">
          {text}
        </Typography>
      )}
      {(buttonProps || buttonLink || buttonText) && (
        <SahinButton
          color="soft"
          component={Link}
          to={buttonLink}
          {...buttonProps}
        >
          {buttonText}
        </SahinButton>
      )}
    </Grid>
  );
};
