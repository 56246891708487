import { createTheme } from '@mui/material/styles';
import { DefaultTheme, createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    color: #fff;
    fontSize: 12px;
    font-weight: 400;
    font-family: ${['"Righteous"', '-apple-system', 'BlinkMacSystemFont'].join(
      ',',
    )}
  }

  svg {
    display: inline-block;
    float: left;
  }
`;

export const theme: DefaultTheme = {
  fonts: {
    primary: "'Nunito Sans', sans-serif",
    secondary: "'Nunito', sans-serif",
  },
  colors: {
    black: 'black',
    white: 'white',
    gray: {
      0: '#FFFFFF',
      5: '#F9F9FA',
      10: '#EFF0F2',
      20: '#fff',
      30: '#eee',
      40: '#ccc',
      50: '#aaa',
      60: '#9a9a9a',
      70: '#7a7a7a',
      80: '#5a5a5a',
      90: '#3a3a3a',
      100: '#1a1a1a',
    },
    blue: {
      10: '#EFF2FC',
      20: '#DEE6F9',
      30: '#CEDAF6',
      40: '#BECDF3',
      50: '#AEC0F0',
      60: '#9DB4ED',
      70: '#8DA7EA',
      80: '#7D9BE7',
      90: '#6C8EE4',
      100: '#5C82E1',
    },
    green: {
      10: '#EEFBF4',
      20: '#DDF6E9',
      30: '#CDF2DE',
      40: '#BCEED3',
      50: '#ABEAC8',
      60: '#9AE5BD',
      70: '#89E1B2',
      80: '#78DDA7',
      90: '#68D99C',
      100: '#57D491',
    },
    red: {
      10: '#FDEEED',
      20: '#FBDDDB',
      30: '#F9CCCA',
      40: '#F7BBB8',
      50: '#F5A9A6',
      60: '#F39894',
      70: '#F18782',
      80: '#EF7671',
      90: '#ED655F',
      100: '#EB544D',
    },
    yellow: {
      10: '#FDF7EC',
      20: '#FBEED9',
      30: '#F9E6C7',
      40: '#F6DDB4',
      50: '#F4D5A1',
      60: '#F2CD8E',
      70: '#F0C47B',
      80: '#EDBC69',
      90: '#EBB356',
      100: '#E9AB43',
    },
    purple: {
      50: '#69438E',
      90: '#411170',
      100: '#3b0a6c',
      120: '#350961',
    },
    dark: {
      10: '#ffffff',
      20: '#dddddd',
      30: '#aaaaaa',
      40: '#888888',
      50: '#666666',
      60: '#444444',
      70: '#3a3a3a',
      80: '#2e2e2e',
      90: '#1e1e1e',
      100: '#0a0a0a',
    },
  },
};

export const muiTheme = createTheme({
  typography: {
    fontFamily: ['"Righteous"', '-apple-system', 'BlinkMacSystemFont'].join(
      ',',
    ),
  },

  palette: {
    mode: 'dark',
    primary: { main: '#1789ef', dark: '#0f65b2', light: '#39a0fc' },
    secondary: { main: '#1cab81', dark: '#15785b', light: '#28c697' },
    common: {
      white: 'white',
      black: 'blue',
    },
    text: {
      primary: '#ffffff',
      secondary: '#cccccc',
      disabled: '#999999',
      success: '#2ECA45',
    },
  },
} as any);
