import { Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { ListTopBar } from '../ListTopBar/ListTopBar';
import { TextMessage } from '../TextMessage/TextMessage';
import { GRID_DEFAULT_LOCALE_TEXT } from './SahinDataTable.model';
import { useStyles } from './SahinDataTable.styles';
import {
  SahinDataTableProps,
  defaultRowParams,
  generalColumns,
} from './SahinDataTable.types';

export const SahinDataTable = ({
  data,
  columns,
  showProperty,
  onEdit,
  onAdd,
  onDelete,
  customActionComponent,
  customActionComponentTitle,
  actionButtonColumnWidth,
  toolbox,
  ...rest
}: SahinDataTableProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<any[]>([]);
  const selectedItem = data?.find((item: any) => item.id === selected?.[0]);
  const currentColumns = useMemo<GridColDef[]>(
    () => [
      {
        ...defaultRowParams,
        field: 'rowNumber',
        headerAlign: 'center',
        align: 'center',
        headerName: '✦',
        width: 100,
        renderCell: (params) => params.api.getRowIndex(params.row.id) + 1,
      },
      ...columns.map((item: any) => ({ ...defaultRowParams, ...item })),
      ...generalColumns({
        onEdit,
        onDelete,
        customActionComponent,
        showProperty,
        customActionComponentTitle,
        actionButtonColumnWidth,
      }),
    ],
    [
      columns,
      onDelete,
      onEdit,
      showProperty,
      customActionComponent,
      customActionComponentTitle,
      actionButtonColumnWidth,
    ],
  );

  const onSelect = (selected: any) => {
    setSelected(selected);
  };

  return (
    <Grid className={classes.dataTable} flexDirection="column" container>
      <ListTopBar
        onAdd={onAdd}
        onEdit={onEdit}
        selected={selected.length === 1 && selectedItem}
        toolbox={toolbox}
      />
      {!!data?.length && (
        <DataGrid
          rows={data}
          pageSize={15}
          // rowHeight={20}
          columns={currentColumns}
          className={classes.dataTable}
          onSelectionModelChange={(value) => onSelect(value)}
          // rowsPerPageOptions={[5, 10, 20]}
          // checkboxSelection
          pagination
          autoHeight
          localeText={GRID_DEFAULT_LOCALE_TEXT}
          {...rest}
        />
      )}
      {!data?.length && <TextMessage text="VERİ YOK" />}
    </Grid>
  );
};
