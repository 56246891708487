export enum AdminRouteNames {
  PREFIX = 'sy/yonetim',
  HOME = '/',
  PRODUCT = 'product',
  SLIDER = 'slider',
  CATEGORY = 'category',
  ORDER = 'order',
  USER = 'user',
  SETTING = 'setting',
  CORPORATEL_IDENTITY = 'corporate',
  ACCOUNT = 'account',
  POST = 'create-post-seo',
}
