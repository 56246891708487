import { ButtonGroup, Grid, Slider, Typography } from '@mui/material';
import { SahinDropdown } from 'common/components/Dropdown/SahinDropdown';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps, QRCode } from 'react-qrcode-logo';

// const logoImage = '/mini_logo.png';
const defaultValue: IProps = {
  // logoImage,
  bgColor: '#ffffff',
  eyeColor: '#000000',
  fgColor: '#000000',
  logoPadding: 15,
  ecLevel: 'H',
  logoPaddingStyle: 'square',
  eyeRadius: [
    {
      outer: [0, 10, 0, 10],
      inner: [10, 0, 10, 0],
    },
    {
      inner: [0, 10, 0, 10],
      outer: [10, 0, 10, 0],
    },
    {
      inner: [0, 10, 0, 10],
      outer: [10, 0, 10, 0],
    },
  ],
};

export const CreateQr = ({ title, url }: any) => {
  const { t } = useTranslation();

  const [qrCodeParameters, setQrCodeParameters] =
    useState<IProps>(defaultValue);

  useEffect(() => {
    setQrCodeParameters((prevState) => ({ ...prevState, value: url }));
  }, [url]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setQrCodeParameters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changeQrUrl = (isMenu?: boolean) => {
    let currentURL = url;
    if (isMenu) {
      currentURL += '/menu';
    }
    setQrCodeParameters((prevState) => ({ ...prevState, value: currentURL }));
  };

  return (
    <Grid sx={{ height: '100%', overflow: 'hidden' }} container>
      <Grid
        xs={8}
        alignContent="center"
        spacing={3}
        alignItems="center"
        item
        container
      >
        <Grid justifyContent="center" item container>
          <ButtonGroup>
            <SahinButton
              color={qrCodeParameters.value !== url ? 'soft' : 'normal'}
              variant="outlined"
              onClick={() => changeQrUrl()}
            >
              {t('homepage')}
            </SahinButton>
            <SahinButton
              color={qrCodeParameters.value === url ? 'soft' : 'normal'}
              onClick={() => changeQrUrl(true)}
            >
              {t('menu.menu')}
            </SahinButton>
          </ButtonGroup>
        </Grid>
        <Grid justifyContent="center" item container>
          <Grid xs={5} item container>
            <SahinTextField value={qrCodeParameters.value} fullWidth />
          </Grid>
        </Grid>
        <Grid justifyContent="center" item container>
          <QRCode {...qrCodeParameters} size={400} ecLevel="H" />
        </Grid>
      </Grid>
      <Grid
        xs={3.5}
        justifyContent="center"
        alignContent="start"
        rowSpacing={4}
        item
        container
      >
        <Grid item container>
          <Typography
            textAlign="center"
            sx={{ p: 1, m: 2, flex: 1, borderRadius: 10, background: '#444' }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item container>
          <SahinTextField
            type="color"
            defaultValue={qrCodeParameters.bgColor}
            name="bgColor"
            label={t('qr.bgColor')}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <SahinTextField
            type="color"
            defaultValue={qrCodeParameters.fgColor}
            name="fgColor"
            label={t('qr.fgColor')}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item container>
          <SahinTextField
            type="color"
            defaultValue={qrCodeParameters.eyeColor}
            name="eyeColor"
            label={t('qr.eyeColor')}
            onChange={handleChange}
            fullWidth
          />
        </Grid>

        <Grid item container>
          <Typography gutterBottom>{t('qr.cornerEdgeSoftness')}</Typography>
          <Slider
            name="eyeRadius"
            min={0}
            max={30}
            step={1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item container>
          <SahinDropdown
            shrink={true}
            label={t('qr.qrStyle')}
            name="qrStyle"
            defaultValue="squares"
            value={qrCodeParameters.qrStyle}
            data={[
              { key: 'squares', label: t('qr.square') },
              { key: 'dots', label: t('qr.dot') },
            ]}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
