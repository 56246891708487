import { red } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  title: {
    paddingBottom: 10,
  },
  error: {
    color: red[500],
    paddingTop: 5,
    paddingLeft: 10,
  },
}));
