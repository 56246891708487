import { Box, ButtonGroup, Grid } from '@mui/material';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { Loading } from 'common/components/Loading/Loading';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { useAppSelector } from 'common/context/hooks';
import { postAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { trimAllSpace } from 'common/utils/helper';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { SettingFormYup } from './SettingsForm.types';
import { BrandForm } from './components/BrandForm';
import { ContactForm } from './components/ContactForm';
import { PaymentTypeForm } from './components/PaymentTypeForm';
import { useTranslation } from 'react-i18next';

export const COMPONENT_TABLE_PATH = AdminTablePaths.SETTING;
const settingsDefault = {
  store: {
    isOrderOpen: true,
    isGuestOrder: true,
    isMenu: true,
  },
  paymentMethod: {
    minPrice: 0,
    gateCreditCard: true,
    gateCash: true,
    ticket: true,
    setcard: true,
    multinet: true,
    paye: true,
    sodexo: true,
    metrepol: true,
  },
};

export const Settings = () => {
  const settings = useAppSelector((state) => state.common.settings);
  const currentSettings = useMemo(
    () => ({
      ...settingsDefault,
      ...settings,
      store: { ...settingsDefault.store, ...settings?.store },
      paymentMethod: {
        ...settingsDefault.paymentMethod,
        ...settings?.paymentMethod,
      },
    }),
    [settings],
  );
  if (!settings) return <Loading />;
  return <SettingsForm setting={currentSettings as Setting} />;
};

const SettingsForm = ({ setting }: { setting: Setting }) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Required<Setting>>({
    defaultValues: setting,
    resolver: SettingFormYup,
    mode: 'onBlur',
  });

  const { t } = useTranslation();

  const settingsForm = useRef<HTMLInputElement>(null);
  const [activePage, setActivePage] = useState(0);

  const onSubmit = (data: Required<Setting>) => {
    data.contact.phone = trimAllSpace(data?.contact?.phone);
    data.contact.whatsapp = trimAllSpace(data?.contact?.whatsapp);

    const promise = postAPI(COMPONENT_TABLE_PATH, data);
    promise.then(() => mutate(COMPONENT_TABLE_PATH)).catch(console.log);
  };

  const handleOnSubmit = handleSubmit(onSubmit);
  const formProps = {
    register,
    errors,
    control,
    setting,
    setValue,
    handleOnSubmit,
  };
  return (
    <ListWrapper
      title={t('settings.settings')}
      infoComponent={
        <SahinButton
          sx={{ textTransform: 'uppercase' }}
          onClick={() => settingsForm.current?.click()}
          disabled={!isValid}
        >
          {t('save')}
        </SahinButton>
      }
    >
      <Grid
        onSubmit={handleOnSubmit}
        sx={{ height: '100%', pb: 111 }}
        alignContent="flex-start"
        component="form"
        columnSpacing={5}
        container
      >
        <Grid xs={3} item container>
          <ButtonGroup orientation="vertical" fullWidth>
            {[
              t('settings.siteInformation'),
              t('settings.contact'),
              t('settings.paymentSection'),
            ].map((item, ndx) => (
              <SahinButton
                key={item}
                color={activePage !== ndx ? 'soft' : 'normal'}
                size="large"
                onClick={() => setActivePage(ndx)}
              >
                {item}
              </SahinButton>
            ))}
          </ButtonGroup>
        </Grid>
        {[
          <BrandForm key={1} {...formProps} />,
          <ContactForm key={2} {...formProps} />,
          <PaymentTypeForm key={3} {...formProps} />,
        ].map((item, ndx) => (
          <Grid
            key={ndx}
            style={{ display: activePage === ndx ? 'flex' : 'none' }}
            xs={8}
            spacing={3}
            alignContent="flex-start"
            item
            container
          >
            {item}
          </Grid>
        ))}
        <input type="submit" style={{ display: 'none' }} ref={settingsForm} />
      </Grid>
      <Box p={6} />
    </ListWrapper>
  );
};
