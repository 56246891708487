import { Grid, Tab, Tabs } from '@mui/material';
import { Image } from 'common/components/Image/Image';
import { SelectFile } from 'common/components/SelectFile/SelectFile';
import { SelectFileRef } from 'common/components/SelectFile/SelectFile.types';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { getCdnImageUrl } from 'common/utils/image';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LogoForm = ({
  setting,
  register,
  errors,
  setValue,
  handleOnSubmit,
}: any) => {
  const { t } = useTranslation();
  const fileManager = useRef<SelectFileRef>(null);

  const onChangeLogo = async () => {
    setTimeout(async () => {
      const images = await fileManager.current?.fileUpload();
      if (!images) return;
      const [image] = images;
      setValue('store.logo', getCdnImageUrl(image));
      handleOnSubmit();
    }, 500);
  };

  const [logoState, setLogoState] = useState(0);
  return (
    <>
      <Grid xs={12} spacing={2} justifyContent="space-between" item container>
        <Grid xs={12} item>
          <Tabs
            value={logoState}
            onChange={(_, value) => {
              setLogoState(value);
            }}
            sx={{
              background: '#3e3e3e',
              direction: 'flex',
              justifyContent: 'center',
            }}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab label={t('link')} />
            <Tab label={t('upload')} />
          </Tabs>
        </Grid>
        <Grid xs={12} item container>
          <SahinTextField
            {...register('store.logo')}
            error={errors?.store?.logo?.message}
            label="Logo"
            placeholder="Enter logo"
            sx={{ display: logoState === 1 ? 'none' : 'unset' }}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} justifyContent="center" item container>
          <SelectFile
            ref={fileManager}
            containerProps={{
              sx: { display: logoState === 0 ? 'none' : 'unset' },
            }}
            onSelect={onChangeLogo}
          />
        </Grid>
      </Grid>
      <Grid xs={5.5} item>
        {setting?.store?.logo && <Image src={setting.store.logo} height={80} />}
      </Grid>
    </>
  );
};
