import { Grid } from '@mui/material';
import { App } from 'application/components/App';
import { LandingPage } from 'application/components/Landing/Landing';
import { Login } from 'application/components/Login/Login';
import { Home } from 'application/components/Main/Home';
import { PaymentErrorPage } from 'application/components/Payment/PaymentErrorPage/PaymentErrorPage';
import { PaymentSuccessPage } from 'application/components/Payment/PaymentSuccessPage/PaymentSuccessPage';
import { Register } from 'application/components/Register/Register';
import { ResetPassword } from 'application/components/ResetPassword/ResetPassword';
import { WelcomePage } from 'application/components/WelcomePage/WelcomePage';
import { About } from 'blog/pages/About';
import { Contact } from 'blog/pages/Contact';
import { DeliveryReturn } from 'blog/pages/DeliveryReturn';
import { PrivacyPolicy } from 'blog/pages/PrivacyPolicy';
import { SSS } from 'blog/pages/SSS';
import { SalesContract } from 'blog/pages/SalesContract';
import { SocialMedia } from 'blog/pages/SocialMedia';
import { WhyRk } from 'blog/pages/WhyRk';
import { commonRoutes } from 'common/contact/common/helper/CommonRoute';
import { ProjectRouteNames } from 'common/helper/ProjectRouteNames';
import { adminRoute } from 'common/helper/SahinYazilimRoute';
import { createRoute } from 'common/helper/createRoute';
import { Navigate } from 'react-router-dom';

export const mainRoutes = createRoute([
  {
    path: ProjectRouteNames.HOME_PAGE,
    element: <App />,
  },
  {
    path: ProjectRouteNames.PAYMENT_ERROR,
    element: <PaymentErrorPage />,
  },
  {
    path: ProjectRouteNames.PAYMENT_SUCCESS,
    element: <PaymentSuccessPage />,
  },
]);

export const welcomeRoute = createRoute([
  {
    path: ProjectRouteNames.LANDING_PAGE,
    element: <LandingPage />,
  },
  {
    path: ProjectRouteNames.HOME_PAGE,
    element: <WelcomePage />,
  },
]);

export const welcomeRoutes = createRoute([
  {
    path: ProjectRouteNames.LOGIN,
    element: <Login />,
  },
  {
    path: ProjectRouteNames.REGISTER,
    element: <Register />,
  },
  {
    path: ProjectRouteNames.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: ProjectRouteNames.ABOUT,
    element: <About />,
  },
  {
    path: ProjectRouteNames.WHY_RK,
    element: <WhyRk />,
  },
  {
    path: ProjectRouteNames.SSS,
    element: <SSS />,
  },
  // {
  //   path: ProjectRouteNames.SUPPORT,
  //   element: <Support />,
  // },
  {
    path: ProjectRouteNames.CONTACT,
    element: <Contact />,
  },
  {
    path: ProjectRouteNames.DELIVERY_RETURN,
    element: <DeliveryReturn />,
  },
  {
    path: ProjectRouteNames.SALES_CONTRACT,
    element: <SalesContract />,
  },
  {
    path: ProjectRouteNames.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ProjectRouteNames.SOCIAL_MEDIA,
    element: <SocialMedia />,
  },
  {
    path: ProjectRouteNames.HOME_PAGE,
    element: <Navigate to="/login" />,
  },
]);

export const homeRoutes = createRoute([
  {
    path: `/${ProjectRouteNames.ADMIN}/*`,
    element: (
      <Grid sx={{ height: '100%' }} container>
        {adminRoute}
      </Grid>
    ),
  },
  {
    path: `/${ProjectRouteNames.COMMON}/*`,
    element: commonRoutes,
  },
  {
    path: ProjectRouteNames.HOME_PAGE,
    element: <Home />,
  },
]);
