import { AllRouteTypes } from 'common/model/AllRouteNames';
import { RoutesType } from 'common/model/RoutesType';
import { Route, Routes } from 'react-router-dom';

export const createLink = (prefixEnum: AllRouteTypes, link: string) => {
  let path = `/${link}`;
  if (link !== '/' && link.startsWith('/')) {
    path = `/${link.replace('/', '')}`;
  }
  const prefix = prefixEnum['PREFIX'] as string;
  if (prefix !== '/' && prefix !== '') {
    path = `/${prefix}${path}`;
  }
  return path;
};

export const createRoute = (routes: RoutesType[]) => (
  <Routes>
    {routes.map((route, key) => (
      <Route key={key} path={route.path} element={route.element} />
    ))}
  </Routes>
);
