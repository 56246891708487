import {
  Box,
  Button,
  Fade,
  Paper,
  Stack,
  Unstable_TrapFocus as TrapFocus,
  Typography,
} from '@mui/material';
import { AdminCacheNames } from 'common/model/AdminCacheNames';
import { getCache, setCache } from 'common/utils/helper';
import { useState } from 'react';

export const CookiePolicy = () => {
  const [open, setOpen] = useState(
    !getCache(AdminCacheNames.COOKIE_POLICY, false),
  );

  const handleClose = (accept: boolean) => {
    setOpen(false);
    setCache(AdminCacheNames.COOKIE_POLICY, {
      accept,
      time: Date.now(),
    });
  };
  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={open}>
        <Paper
          role="dialog"
          square
          variant="outlined"
          tabIndex={-1}
          sx={{
            background: '#000000bb',
            position: 'fixed',
            width: { xs: 300, md: 400 },
            bottom: 20,
            left: 20,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1,
            borderRadius: 3,
            zIndex: 10,
          }}
        >
          <Stack direction="column" justifyContent="space-between" gap={2}>
            <Box
              sx={{
                flexShrink: 1,
                alignSelf: 'flex-start',
              }}
            >
              <Typography fontWeight="bold">
                İnternet sitemizde kullanıcı deneyimini iyileştirmek amacıyla
                çerezler yoluyla kişisel verileriniz işlenmektedir.
              </Typography>
              <Typography variant="body2" mt={1}>
                {`Çerez
            ayarlarınızı yönetmek için "Ayarlar" seçeneğine tıklayabilir,
            sitemizdeki çerezle ilgili detaylı bilgiye Çerez Politikası'ndan
            ulaşabilirsiniz. Aydınlatma metnine buradan ulaşabilirsiniz.`}
              </Typography>
            </Box>
            <Stack
              gap={2}
              direction="row"
              sx={{
                flexShrink: 0,
                alignSelf: 'flex-end',
              }}
            >
              <Button size="small" onClick={() => handleClose(false)}>
                Reddet
              </Button>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleClose(true)}
              >
                Kabul et
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
};
