import { Button, Grid } from '@mui/material';
import { SahinModal } from 'common/components/Modal/SahinModal';
import { SelectFile } from 'common/components/SelectFile/SelectFile';
import { SelectFileRef } from 'common/components/SelectFile/SelectFile.types';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { postAPI, updateAPI } from 'common/helper/apiHelpers';
import { getCdnImageUrl } from 'common/utils/image';
import { forwardRef, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';
import { COMPONENT_TABLE_PATH } from '../List/SliderList';
import { SliderFormYup } from './SliderForm.types';

export const SliderForm = forwardRef<any, any>(
  ({ initialData, open, handleClose }, ref) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
    } = useForm<Slider>({
      resolver: SliderFormYup,
      mode: 'onBlur',
    });

    const { t } = useTranslation();

    const fileManager = useRef<SelectFileRef>(null);

    useEffect(() => {
      reset(initialData || {});
    }, [initialData, reset]);

    const onSubmit = async (data: Slider) => {
      const fileUpload = await fileManager.current?.fileUpload();
      if (!fileUpload) return;
      data.image = fileUpload?.[0];

      let promise;
      if (initialData?.id) {
        promise = updateAPI(COMPONENT_TABLE_PATH, initialData.id, data);
      } else {
        promise = postAPI(COMPONENT_TABLE_PATH, data);
      }
      promise.then(() => mutate(COMPONENT_TABLE_PATH));
      handleClose();
    };

    return (
      <SahinModal
        open={open}
        handleClose={handleClose}
        width="50%"
        height="50%"
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid sx={{ height: '100%' }} container>
            <Grid alignContent="flex-start" spacing={3} container>
              <Grid xs={12} item>
                <SelectFile
                  {...register('image')}
                  ref={fileManager}
                  containerProps={{ width: '100%' }}
                  onChange={(images) => {
                    initialData.image = images[0];
                    setValue('image', images[0]);
                  }}
                  error={errors?.image?.message}
                  previewValue={
                    initialData?.image
                      ? [getCdnImageUrl(initialData.image)]
                      : []
                  }
                  value={initialData?.image ? [initialData.image] : []}
                  showPreview
                />
              </Grid>
              <Grid xs={12} item>
                <SahinTextField
                  {...register('url')}
                  error={errors?.url?.message}
                  label={t('link')}
                  placeholder={t('linkPlaceholder')}
                  helperText={t('slider.linkHelperText')}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} justifyContent="flex-end" item container>
                <Button variant="contained" type="submit">
                  {initialData?.id ? t('edit') : t('add')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </SahinModal>
    );
  },
);
