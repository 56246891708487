import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';

export const Stepper = ({ nextButton, backButton, steps, activeStep }: any) => {
  return (
    <MobileStepper
      variant="progress"
      steps={3}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 600, flexGrow: 1, background: 'transparent' }}
      nextButton={
        nextButton ?? (
          <Button size="small" disabled={activeStep === 3}>
            İLERİ
            <KeyboardArrowRight />
          </Button>
        )
      }
      backButton={
        backButton ?? (
          <Button size="small" disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            GERİ
          </Button>
        )
      }
    />
  );
};
