import { Badge, Grid, Typography } from '@mui/material';
import { HomeCharts } from 'common/components/Charts/HomeCharts';
import { ListWrapper } from 'common/components/ListWrapper/ListWrapper';
import { useAppSelector } from 'common/context/hooks';
import { useFetch } from 'common/hooks/useFetchSwr';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { currencyFormat, getBrandDomain } from 'common/utils/helper';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type AllDailyAnyMore = {
  date: string;
  total: number;
}[];

type NumberOfAllProductSales = {
  date: string;
  product: {
    name: string;
    category: string;
    id: number;
    count: number;
  }[];
}[];

type Time = 'today' | 'weekly' | 'monthly';

export const Home = () => {
  const { t } = useTranslation();

  const brand = useAppSelector((state) => state.common.brand);

  const {
    data: dailyOrder,
    error: dailyOrderError,
    isLoading: dailyOrderIsLoading,
  } = useFetch<AllDailyAnyMore>(AdminTablePaths.DAILY_ORDER);

  const {
    data: dailyPrice,
    error: dailyPriceError,
    isLoading: dailyPriceIsLoading,
  } = useFetch<AllDailyAnyMore>(AdminTablePaths.DAILY_PRICE);

  const {
    data: numberOfProductSales,
    error: numberOfProductSalesError,
    isLoading: numberOfProductSalesIsLoading,
  } = useFetch<NumberOfAllProductSales>(
    AdminTablePaths.NUMBER_OF_PRODUCT_SALES,
  );

  const allDailyOrder = useMemo(
    () =>
      dailyOrder?.map((order) => {
        return {
          xAxisName: order.date,
          Order: order.total,
          uv: 0,
          amt: 0,
        };
      }) ?? [],
    [dailyOrder],
  );

  const allDailyPrice = useMemo(
    () =>
      dailyPrice?.map((price) => {
        return {
          xAxisName: price.date,
          uv: 0,
          Price: price.total,
          amt: 0,
        };
      }) ?? [],
    [dailyPrice],
  );

  const numberOfAllProductSales = useMemo(() => {
    if (numberOfProductSales) {
      const dailyProductOrder = [];
      for (const entry of numberOfProductSales) {
        const { date, product } = entry;
        for (const productItem of product) {
          const { id, name, count, category } = productItem;
          dailyProductOrder.push({
            productId: id,
            productName: name,
            productCount: count,
            productCategory: category,
            date: date,
          });
        }
      }

      const monthlyProductOrder = dailyProductOrder.reduce(
        (acc: any, curr: any) => {
          if (acc[curr.productName]) {
            acc[curr.productName] += curr.productCount;
          } else {
            acc[curr.productName] = curr.productCount;
          }
          return acc;
        },
        {},
      );

      return Object.keys(monthlyProductOrder).map((key) => {
        return {
          xAxisName: key,
          uv: 0,
          Number: monthlyProductOrder[key],
          amt: 0,
        };
      });
    }
    return [];
  }, [numberOfProductSales]);

  const getSales = useCallback(
    (time: Time) => {
      let response = 0;
      switch (time) {
        case 'today':
          response = allDailyPrice?.[allDailyPrice.length - 1]?.Price ?? 0;
          break;
        case 'weekly': {
          if (allDailyPrice.length)
            response = allDailyPrice?.reduce((acc: number, curr) => {
              if (
                new Date(
                  curr.xAxisName + new Date().toISOString().slice(0, 4),
                ).getTime() >
                Date.now() - 7 * 24 * 60 * 60 * 1000
              )
                return acc + curr.Price;
              return acc;
            }, 0);
          break;
        }
        case 'monthly': {
          if (allDailyPrice.length)
            response = allDailyPrice?.reduce(
              (acc: number, curr: any) => acc + curr.Price,
              0,
            );
          break;
        }
      }
      return response;
    },
    [allDailyPrice],
  );

  const getOrders = useCallback(
    (time: Time) => {
      let response = 0;
      switch (time) {
        case 'today':
          response = allDailyOrder?.[allDailyOrder.length - 1]?.Order ?? 0;
          break;
        case 'weekly': {
          if (allDailyOrder.length)
            response = allDailyOrder?.reduce((acc: number, curr) => {
              if (
                new Date(
                  curr.xAxisName + new Date().toISOString().slice(0, 4),
                ).getTime() >
                Date.now() - 7 * 24 * 60 * 60 * 1000
              )
                return acc + curr.Order;
              return acc;
            }, 0);
          break;
        }
        case 'monthly': {
          if (allDailyOrder.length)
            response = allDailyOrder?.reduce(
              (acc: number, curr: any) => acc + curr.Order,
              0,
            );
          break;
        }
      }
      return response;
    },
    [allDailyOrder],
  );

  const getPopularProduct = useCallback(
    (time: Time): string => {
      if (!numberOfProductSales) return '-';
      const dailyProductOrder = [];

      for (const entry of numberOfProductSales) {
        const { date, product } = entry;
        for (const productItem of product) {
          const { id, name, count, category } = productItem;
          dailyProductOrder.push({
            productId: id,
            productName: name,
            productCount: count,
            productCategory: category,
            date: date,
          });
        }
      }

      let response = '-';
      switch (time) {
        case 'today':
          response =
            dailyProductOrder[dailyProductOrder.length - 1]?.productName ?? '-';
          break;
        case 'weekly':
          {
            const weeklyProductOrder = dailyProductOrder.reduce(
              (acc: any, curr: any) => {
                if (
                  new Date(curr.date).getTime() >
                  Date.now() - 7 * 24 * 60 * 60 * 1000
                ) {
                  if (acc[curr.productName]) {
                    acc[curr.productName] += curr.productCount;
                  } else {
                    acc[curr.productName] = curr.productCount;
                  }
                }
                return acc;
              },
              {},
            );
            if (weeklyProductOrder.length) {
              response = Object.keys(weeklyProductOrder).reduce((a, b) =>
                weeklyProductOrder[a] > weeklyProductOrder[b] ? a : b,
              );
            }
          }
          break;
        case 'monthly': {
          const monthlyProductOrder = dailyProductOrder.reduce(
            (acc: any, curr: any) => {
              if (acc[curr.productName]) {
                acc[curr.productName] += curr.productCount;
              } else {
                acc[curr.productName] = curr.productCount;
              }
              return acc;
            },
            {},
          );
          if (monthlyProductOrder.length) {
            response = Object.keys(monthlyProductOrder).reduce((a, b) =>
              monthlyProductOrder[a] > monthlyProductOrder[b] ? a : b,
            );
          }
          break;
        }
      }

      return response;
    },
    [numberOfProductSales],
  );

  // const badgeProps: any = useMemo(
  //   () =>
  //     brand?.enabled
  //       ? { color: 'success', badgeContent: t('active') }
  //       : { color: 'success', badgeContent: t('inactive') },
  //   [brand?.enabled, t],
  // );

  const headerInfoComponent = useMemo(
    () => (
      <Grid columnSpacing={4} item container>
        <Grid
          component="a"
          href={getBrandDomain(brand)}
          color="inherit"
          rel="noreferrer"
          target="_blank"
          item
        >
          <Badge color="success" badgeContent={t('active')}>
            <Typography textAlign="center" fontSize={16}>
              {getBrandDomain(brand, true)}
            </Typography>
          </Badge>
        </Grid>
        <Grid item>
          <Typography textAlign="center" component="h4" fontSize={16}>
            {brand?.name}
          </Typography>
        </Grid>
      </Grid>
    ),
    [brand, t],
  );

  return (
    <ListWrapper title={t('homepage')} infoComponent={headerInfoComponent}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <HomeCharts
            loading={dailyPriceIsLoading}
            error={dailyPriceError}
            today={`${getSales('today')} TL`}
            weekly={`${getSales('weekly')} TL`}
            monthly={`${getSales('monthly')} TL`}
            todayTitle={t('homeCharts.todayEarn')}
            weeklyTitle={t('homeCharts.weeklyEarn')}
            monthlyTitle={t('homeCharts.monthlyEarn')}
            barData={allDailyPrice}
            valueFormatter={currencyFormat}
            barValueKey="Price"
            barTitle={t('homeCharts.earnBarTitle')}
          />
        </Grid>
        <Grid item xs={12}>
          <HomeCharts
            loading={dailyOrderIsLoading}
            error={dailyOrderError}
            today={`${getOrders('today')} Adet`}
            weekly={`${getOrders('weekly')} Adet`}
            monthly={`${getOrders('monthly')} Adet`}
            todayTitle={t('homeCharts.todayOrder')}
            weeklyTitle={t('homeCharts.weeklyOrder')}
            monthlyTitle={t('homeCharts.monthlyOrder')}
            barData={allDailyOrder}
            valueFormatter={(text: string) => `${text} Adet`}
            barValueKey="Order"
            barTitle={t('homeCharts.orderBarTitle')}
          />
        </Grid>
        <Grid item xs={12}>
          <HomeCharts
            loading={numberOfProductSalesIsLoading}
            error={numberOfProductSalesError}
            today={getPopularProduct('today')}
            weekly={getPopularProduct('weekly')}
            monthly={getPopularProduct('monthly')}
            todayTitle={t('homeCharts.todayPopularProduct')}
            weeklyTitle={t('homeCharts.weeklyPopularProduct')}
            monthlyTitle={t('homeCharts.monthlyPopularProduct')}
            barData={numberOfAllProductSales}
            barValueKey="Number"
            barTitle={t('homeCharts.popularBarTitle')}
          />
        </Grid>
      </Grid>
    </ListWrapper>
  );
};
