import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  header: {
    height: '90px',
    [theme.breakpoints.down('md')]: {
      '& img': {
        height: 'auto',
        objectFit: 'cover',
      },
    },
  },
  headerNavButton: {
    '&:hover': {
      opacity: '0.8',
    },
    cursor: 'pointer',
    padding: '10px',
    margin: '0 15px',
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize: '1.2em',
    textDecoration: 'none',
  },
  navSlider: {
    height: 'calc(100vh - 90px)',
    padding: '0 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  floor2: {
    minHeight: '70vh',
    background: '#eee',
  },
  floor3: {
    padding: '40px',
    '& > :not(:last-child)': {
      marginBottom: '100px',
    },
  },
  floor4: {
    background: '#eee',
    color: 'black',
    padding: '60px 0',
    '& > :not(:last-child)': {
      marginBottom: '20px',
    },
  },
  floor5: {
    padding: '40px',
    '& > :not(:last-child)': {
      marginBottom: '100px',
    },
  },
  floor6: {
    padding: '40px',
    color: '#222',
    background: '#eee',
    '& > :not(:last-child)': {
      marginBottom: '40px',
    },
  },
  floor7: {
    '& > :not(:last-child)': {
      // marginRight: '20px',
    },
  },
  floor8: {
    background: 'black',
    opacity: '0.8',
    padding: '40px',
  },
  textField: {
    width: '40%',
    margin: '20px',
  },
  footer: {
    '& a': {
      color: 'currentColor',
      textDecoration: 'none',
      letterSpacing: 1,
      fontSize: '.9rem',
    },
    padding: '30px 0',
    paddingBottom: '50px',
    opacity: '0.97',
    borderTop: '4px solid #11111190',
    background:
      'linear-gradient(-170deg, #3099ff 0%,#6099ef 40%, #8099ef 100%)',
  },
  miniFooter: {
    padding: '20px',
    borderTop: '4px solid #11111190',
    background:
      'linear-gradient(-170deg, #3099ff 0%,#6099ef 40%, #8099ef 100%)',
  },
  incomingPageTitle: {
    fontSize: '2.5rem',
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
      textAlign: 'center',
      marginTop: '1rem',
    },
  },
  incomingPageInfo: {
    fontSize: '1.2em',
    marginRight: '40px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      textAlign: 'center',
      margin: 0,
      marginTop: '3rem',
    },
  },
  incomingPageButtonNowStart: {
    fontSize: '1.2em',
    marginTop: '95px',
    padding: '.5em 2.4em',
    background: '#3099ff',
    color: '#fff',
    boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.1)',
    borderRadius: '10px',
    '&:hover': {
      background: '3099ff',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      marginTop: '3rem',
    },
  },
  incomingPageButtonMoreInfo: {
    fontSize: '.9rem',
    marginTop: '20px',
    background: 'white',
    color: 'black',
    boxShadow: '5px 5px 10px 0px rgba(0,0,0,0.1)',
    borderRadius: '5px 20px',
    padding: '10px 20px 10px 20px',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      marginTop: '1rem',
    },
  },
  useCasesTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  floor2Title: {
    fontSize: '2.5rem',
    textTransform: 'capitalize',
    marginBottom: '50px',
    color: '#222',
  },
  featuresMainTitle: {
    fontSize: '2.5rem',
    textTransform: 'capitalize',
    marginBottom: '50px',
    color: '#222',
  },
  featuresTitle: {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    color: '#222',
  },
  featuresInfo: {
    fontSize: '1rem',
    marginTop: '20px',
    color: '#444',
    marginBottom: '40px',
    padding: '0 10px',
  },
}));
