import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  root: {
    background: 'linear-gradient(125deg, #764ba2 0%, #1789ef 50%)',
    backgroundAttachment: 'fixed',
    color: 'white',
    '& a': {
      color: 'currentColor',
    },
  },
}));
