import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme) => ({
  planCard: {
    position: 'relative',
    padding: '20px',
    '& > :not(:last-child)': {
      marginBottom: '20px',
    },
    borderRadius: '10px',

    transition: 'all 0.2s ease-in-out',
  },
  cardHover: {
    '&:hover': {
      boxShadow: '4px 4px 10px 0px rgba(0,0,0,0.2)',
      transform: 'scale(1.05)',
      margin: '0 40px 0 20px',
    },
  },
  planCardColor1: {
    background: '#3099ff',
  },
  planCardColor2: {
    background: '#6099ef',
  },
  planCardColor3: {
    background: '#8099ef',
  },
  planCardColor4: {
    background: 'orange',
  },
  badge: {
    borderRadius: '10px',
    // background: '#1cab81',
    background: 'currentColor',
    position: 'absolute',
    right: '7px',
    top: '7px',
    padding: '5px 10px',
    border: '4px solid #fff',
    // boxShadow: '2px 2px 20px 2px #00000050',
  },
  planHeader: {
    background: 'white',
    borderRadius: '10px 10px 0px 0px',
    padding: '10px',
    color: 'black',
  },
  planDescription: {
    fontSize: '1rem',
    marginTop: '20px',
  },
  planPrice: {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
  },
  planTitle: {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
  },
  disabled: {
    opacity: 0.95,
  },
}));
