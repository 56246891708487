export const tr = {
  homepage: 'ANA SAYFA',

  active: 'Aktif',
  inactive: 'Pasif',

  month: 'Ay',

  edit: 'Düzenle',
  delete: 'Sil',
  add: 'Ekle',
  save: 'Kaydet',
  upload: 'Yükle',
  link: 'Bağlantı',

  linkPlaceholder: 'Bağlantı giriniz',

  general: 'Genel',
  send: 'Gönder',

  resetPassword: 'Şifre Sıfırla',

  homeCharts: {
    tooltipLabels: {
      Order: 'Sipariş',
      Price: 'Kazanç',
      Number: 'Adet',
    },

    earnBarTitle: 'Kazanç (Günlük)',
    todayEarn: 'Günlük Kazanç',
    weeklyEarn: 'Haftalık Kazanç',
    monthlyEarn: 'Aylık Kazanç',

    orderBarTitle: 'Sipariş Sayısı (Günlük)',
    todayOrder: 'Günlük Sipariş',
    weeklyOrder: 'Haftalık Sipariş',
    monthlyOrder: 'Aylık Sipariş',

    popularBarTitle: 'En Çok Satan Ürünler',
    todayPopularProduct: 'Günün Popüler Ürünü',
    weeklyPopularProduct: 'Haftanın Popüler Ürünü',
    monthlyPopularProduct: 'Ayın Popüler Ürünü',
  },

  menuBar: {
    homepage: 'Ana Sayfa',
    orders: 'Siparişler',
    menuLayout: 'Menü Yapısı',
    products: 'Ürünler',
    categories: 'Kategoriler',
    contactRequests: 'İletişim Telepleri',
    banner: 'Anasayfa Slider',
    accountManagement: 'Hesap Yönetimi',
    corporateIdentity: 'Kurumsal Kimlik',
    logout: 'Çıkış',
  },

  form: {
    fullName: {
      value: 'Ad Soyad',
      placeholder: 'Adınızı ve soyadınızı giriniz',
    },
    title: { value: 'Başlık', placeholder: 'Başlık giriniz' },
    name: { value: 'İsim', placeholder: 'İsminizi giriniz' },
    refCode: { value: 'Referans Kodu', placeholder: 'Referans Kodu giriniz' },
    notHumanName: { value: 'Adı', placeholder: 'Adını Giriniz' },
    surname: { value: 'Soyisim', placeholder: 'Soyisminizi giriniz' },
    password: { value: 'Şifre', placeholder: 'Şifrenizi giriniz' },
    newPassword: { value: 'Yeni Şifre', placeholder: 'Yeni şifrenizi giriniz' },
    passwordAgain: {
      value: 'Şifre Tekrar',
      placeholder: 'Şifrenizi tekrar giriniz',
    },
    email: { value: 'E-posta', placeholder: 'E-posta adresinizi giriniz' },
    city: { value: 'Şehir', placeholder: 'Şehir seçiniz' },
    address: { value: 'Adres', placeholder: 'Adresinizi giriniz' },
    price: { value: 'Fiyat', placeholder: 'Fiyat giriniz' },
    phone: {
      value: 'Telefon Numarası',
      placeholder: 'Telefon numaranızı giriniz',
    },
    identity: {
      value: 'TC Kimlik Numarası',
      placeholder: 'TC kimlik numaranızı giriniz',
    },

    organizationName: {
      value: 'Organizasyon Adı',
      placeholder: 'Organizasyon adını giriniz',
    },
    organizationAddress: {
      value: 'Organizasyon Adresi',
      placeholder: 'Organizasyon adresini giriniz',
    },
    customDomain: {
      value: 'Özel Alan Adı',
      placeholder: 'Özel alan adını giriniz',
    },
    subdomain: {
      value: 'Alt Alan Adı',
      placeholder: 'Alt alan adını giriniz',
    },
    description: {
      value: 'Açıklama',
      placeholder: 'Açıklama giriniz',
    },
    content: {
      value: 'İçerik',
      placeholder: 'İçerik giriniz',
    },
    subject: {
      value: 'Konu',
      placeholder: 'Konu giriniz',
    },
    message: {
      value: 'Mesaj',
      placeholder: 'Mesaj giriniz',
    },
  },

  qr: {
    bgColor: 'Arkaplan Rengi',
    fgColor: 'Nokta Rengi',
    eyeColor: 'Köşe Rengi',
    qrStyle: 'Nokta Şekli',

    cornerEdgeSoftness: 'Köşe Kenar Yumuşaklığı',

    dot: 'Nokta',
    square: 'Kare',
  },

  account: {
    accountManagement: 'Hesap Yönetimi',
    selectPlan: 'Plan Seç',
    donePay: 'ÖDEMEYİ TAMAMLA',
    package: 'Paket',
    totalPrice: 'Ara Toplam',
    totalPay: 'Ödenecek Tutar',
    discount: 'İndirim: (%{{ discount }})',
    changePlan: 'Plan Değiştir',

    activeTime: '{{time}}tir aktifsiniz!',
  },

  application: {
    landing: {
      whatThatItTitle: 'Neden Restorant Kolay Kullanmalıyım?',
      whatThatItButton: 'Bilgi Edin',

      featureTitle1: 'Çoklu Dil Desteği',
      featureDescription1:
        'Çoklu dil desteği ile müşterilerinize kendi dillerinde hizmet verin.',

      featureTitle2: 'Referans Kodu',
      featureDescription2:
        'Referans Kodu ile 1 aylık ödeme yap 2 ay ücretsiz kullanma hakkı kazan!',
    },
    login: {
      successLogin: 'Giriş Yapıldı',
      register: 'KAYIT OL',
      login: 'GİRİŞ YAP',
      forgotPassword: 'Şifremi Unuttum',

      notHaveAccound: 'Hesabın yok mu? Hemen Kaydol !',
      haveAccound: 'Zaten Hesabın var mı? Giriş Yap !',
      forgotPasswordSuccess: 'Şifre Sıfırlama Linki Gönderildi',
      forgotPasswordError: 'Şifre Sıfırlama Linki Gönderilemedi',
    },
    payment: {
      successPay: 'Ödeme Başarıyla Tamamlandı.',
      errorPay: 'Ödeme işlemi başarısız oldu.',
      tryAgain: 'Lütfen tekrar deneyiniz.',
      paymentMethods: 'Ödeme Yöntemleri',
    },
  },

  menu: {
    menu: 'MENÜ',
    newMenuArea: 'Yeni Menü Alanı',
    goMenu: 'Menüye Git',
  },

  product: {
    newProduct: 'Yeni Ürün',
    delete: {
      title: 'Ürünü Sil',
      description: 'Bu ürünü silmek istediğinize emin misiniz?',
      cancel: 'İptal',
      delete: 'Sil',
    },

    partTitle: {
      value: 'Bölüm Başlığı',
      placeholder: 'Bölüm başlığı giriniz',
    },

    showSite: 'Sitede Göster',
    popularTagActive: 'Popüler Etiketi Aktif',
  },

  order: {
    products: 'Ürünler',
    paymentType: 'Ödeme Yöntemi',
    orderNote: 'Sipariş Notu',
  },

  orderStatus: {
    pending: 'Bekliyor',
    preparing: 'Hazırlanıyor',
    on_way: 'Teslim Ediliyor',
    delivered: 'Teslim Edildi',
    rejected: 'İptal Edildi',
  },

  confirmDialog: {
    title: 'Onayla',
    description: 'Bu işlemi onaylıyor musunuz?',
  },

  category: {
    category: 'Kategori',
  },

  settings: {
    settings: 'Ayarlar',
    siteInformation: 'Site Bilgileri',
    paymentSection: 'Ödeme Seçenekleri',
    contact: 'İletişim',
    socialMedia: 'Sosyal Medya',
    logo: 'Logo',
    logoPlaceholder: 'Logo bağlantısı giriniz',
  },

  socialMediaForm: {
    whatsapp: {
      value: 'Whatsapp',
      placeholder: 'Whatsapp numaranızı giriniz',
    },
    instagram: {
      value: 'Instagram',
      placeholder: 'Instagram hesap linki giriniz',
    },
    facebook: {
      value: 'Facebook',
      placeholder: 'Facebook hesap linki giriniz',
    },
    twitter: {
      value: 'Twitter',
      placeholder: 'Twitter hesap linki giriniz',
    },
  },

  slider: {
    linkHelperText:
      'Tıklandığında bir adrese gitmesini istiyorsanız doldurun (boş bırakabilirsiniz)',
  },
};
