import { accountRoute } from 'account/common/helper/AccountRoute';
import { CategoryList } from 'category/components/List/CategoryList';
import { createRoute } from 'common/helper/createRoute';
import { AdminRouteNames } from 'common/model/AdminRouteNames';
import { CorporateIdentityList } from 'corporateIdentity/List/CorporateIdentityList';
import { OrderList } from 'order/components/List/OrderList';
import { PostList } from 'post/components/List/PostList';
import { ProductList } from 'product/components/List/ProductList';
import { Navigate } from 'react-router-dom';
import { Settings } from 'settings/Form/SettingsForm';
import { SliderList } from 'slider/components/List/SliderList';

export const adminRoute = createRoute([
  {
    path: `/${AdminRouteNames.PRODUCT}`,
    element: <ProductList />,
  },
  {
    path: `/${AdminRouteNames.CATEGORY}`,
    element: <CategoryList />,
  },
  {
    path: `/${AdminRouteNames.ORDER}`,
    element: <OrderList />,
  },
  {
    path: `/${AdminRouteNames.SETTING}`,
    element: <Settings />,
  },
  {
    path: `/${AdminRouteNames.CORPORATEL_IDENTITY}`,
    element: <CorporateIdentityList />,
  },
  {
    path: `/${AdminRouteNames.SLIDER}`,
    element: <SliderList />,
  },
  {
    path: `/${AdminRouteNames.POST}`,
    element: <PostList />,
  },
  {
    path: `/${AdminRouteNames.ACCOUNT}/*`,
    element: accountRoute,
  },
  {
    path: '*',
    element: <Navigate to={AdminRouteNames.HOME} replace={true} />,
  },
]);
