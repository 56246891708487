import { Grid } from '@mui/material';
import { ReactNode } from 'react';

export const TextMessage = ({ text }: { text: string | ReactNode }) => {
  return (
    <Grid
      sx={{ height: '100%', flex: 1 }}
      justifyContent="center"
      alignItems="center"
      alignContent="stretch"
      container
    >
      {text}
    </Grid>
  );
};
