import { SahinDataTable } from 'common/components/DataTable/SahinDataTable';
import { useAppSelector } from 'common/context/hooks';
import { deleteAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import { Nullable } from 'common/model/Nullable';
import { useState } from 'react';
import { mutate } from 'swr';
import { CategoryForm } from '../Form/CategoryForm';
import { categoryListColumns } from './CategoryList.types';

export const COMPONENT_TABLE_PATH = AdminTablePaths.CATEGORY;

export const CategoryList = () => {
  const categories = useAppSelector((state) => state.common.categories);

  const [selectedItem, setSelected] =
    useState<Nullable<Partial<Category>>>(null);

  const onAdd = () => {
    setSelected({});
  };

  const onEdit = (selected: Category) => {
    setSelected(selected);
  };

  const onDelete = (selected: Category) => {
    deleteAPI(COMPONENT_TABLE_PATH, selected.id)
      .then(() => {
        mutate(COMPONENT_TABLE_PATH);
      })
      .catch((error) => console.error(error));
  };

  const onCloseModal = () => {
    setSelected(null);
  };

  return (
    <>
      <CategoryForm
        open={Boolean(selectedItem)}
        initialData={selectedItem}
        handleClose={onCloseModal}
      />
      <SahinDataTable
        data={categories}
        columns={categoryListColumns}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};
