import { Grid } from '@mui/material';
import { SahinSwitch } from 'common/components/SahinSwitch/SahinSwitch';
import { Controller } from 'react-hook-form';

export const SettingSwitchField = ({ control, label, name }: any) => {
  return (
    <>
      <Grid xs={6} item container>
        {label}
      </Grid>
      <Grid xs={6} justifyContent="flex-end" item container>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <SahinSwitch
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              inputRef={ref}
              size={1}
            />
          )}
        />
      </Grid>
    </>
  );
};
