import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@mui/material';
import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';
import { OwnerEnv } from 'common/utils/env';

export const SSS = () => {
  const content = (
    <>
      {sss.map((item) => (
        <Accordion key={item.key} sx={{ marginTop: 2 }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{item.title}</Typography>
            <Divider />
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              component="pre"
              sx={{ textWrap: 'wrap', paddingRight: 10 }}
            >
              {item.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );

  return <BlogWrapper title={title} content={content} />;
};

const sss = [
  {
    key: 1,
    title: 'Neden Komisyonsuz Sipariş?',
    content: `E-sipariş siteleri, özellikle komisyon olmayan aylık tek alım paketleri sunanlar, satıcılara birçok avantaj sağlar. İşte bu avantajlardan bazıları:

Düşük Maliyet: Komisyon olmayan aylık tek alım paketleri, satıcıların satış başına ekstra ücret ödemeden ürünlerini listeleyebilecekleri bir platform sunar. Bu, genellikle daha düşük işletme maliyetleri anlamına gelir.

Fiyat Kontrolü: Satıcılar, komisyon ücretlerini fiyatlarına eklemek zorunda kalmazlar. Bu, ürünlerini daha rekabetçi fiyatlarla sunmalarını sağlar.

Kar Marjının Korunması: Her satıştan bir yüzde alınmadığı için, satıcılar kar marjlarını koruyabilir ve işlerini daha öngörülebilir bir şekilde yönetebilirler.

Öngörülebilir Harcamalar: Aylık tek alım paketleri, satıcıların bütçelerini daha etkili bir şekilde yönetmelerini sağlar. Sabit bir maliyetle, aylık harcamaları daha öngörülebilir hale gelir.

Daha Fazla Satış Fırsatı: Komisyon olmayan platformlar, satıcıların daha fazla ürün listelemesini teşvik eder. Bu, daha fazla satış fırsatı anlamına gelir.

Bu avantajlar, satıcıların işlerini büyütmelerine, karlılıklarını artırmalarına ve müşterilerine daha iyi hizmet vermelerine yardımcı olur. Bu nedenle, komisyon olmayan aylık tek alım paketleri sunan e-sipariş siteleri, çeşitli sektörlerdeki satıcılar için ideal bir seçenek olabilir.
`,
  },
  {
    key: 2,
    title: 'Nasıl Satın Alırım?',
    content: `Restorant Kolay platformuna hoş geldiniz. İşte platformumuzu kullanarak kendi sipariş sitenizi nasıl oluşturabileceğinizi adım adım anlatıyoruz:

Öncelikle ${OwnerEnv.domain} adresine gidin ve “Hemen Başla” butonuna tıklayın.

Karşınıza çıkan formu doldurun ve kaydınızı tamamlayın. Böylece sipariş siteniz, Restorant Kolay altyapısıyla hemen aktif olacaktır.

Sitenize, formda belirttiğiniz isimle erişebilirsiniz. Örneğin: https://İSİM.${OwnerEnv.domain}

Alan adının uzunluğu sizi endişelendirmesin. Müşterilerinizin sitenize kolayca ulaşabilmesi için sistemimizde bir QR Editörü bulunmaktadır. Bu editörden indireceğiniz QR kodunu kullanarak müşterilerinizin sitenizi rahatça kullanmasını sağlayabilirsiniz.

Yakın bir zamanda ekstra sub domain seçeneklerini de ekleyeceğiz. Örneğin:

https://İSİM.reko.com
https://İSİM.siparis.me
https://İSİM.siparis.net

Şu an için özel domain desteğimiz bulunmamaktadır. Ancak, 2024 Şubat’ta özel domain desteğini paketlerimize eklemeyi planlıyoruz. Böylece istediğiniz (.com, .net, .org vb.) tüm alan adlarında sitenizi yayınlayabileceksiniz.
Ayrıca, tanıdığınız diğer restoran sahiplerine referans kodunuzla sistemimizi kullanmalarını sağlarsanız, kullandığınız paketten 2 ay ücretsiz hizmet alabilirsiniz. Bu, işletmenizin büyümesine yardımcı olurken aynı zamanda maliyetlerinizi de düşürür.

Restorant Kolay ile işletmenizi dijital dünyaya taşıyın ve müşterilerinize daha iyi hizmet verin. Sizin başarınız, bizim başarımızdır.
  `,
  },
  {
    key: 3,
    title: 'Faydası Nedir?',
    content: `Komisyonsuz bir sipariş sistemini kullanmanın avantajlarını daha somut bir örnekle açıklamak gerekirse:

Bir restoran düşünün ki, aylık geliri 100.000 TL. Eğer bu restoran, her satıştan %15 komisyon alan bir sipariş sistemini kullanıyorsa, aylık olarak bu sistemlere 15.000 TL ödemek zorunda kalır. Bu durumda restoranın net kazancı 85.000 TL olur.

Şimdi aynı restoranın komisyonsuz bir sipariş sistemini kullandığını düşünelim. Bu sistemde restoran, sabit bir ücret öder ve tüm satışlarından elde ettiği geliri korur. Diyelim ki bu sabit ücret aylık 1000 TL. Bu durumda, restoranın aylık geliri ne olursa olsun, ödemesi gereken tutar hep aynı kalır. Yani restoran 100.000 TL kazanırsa da, ödemesi gereken tutar 1000 TL’dir. Bu durumda restoranın net kazancı 99.000 TL olur.

Bu örnekte görüldüğü gibi, komisyonsuz bir sipariş sistemi kullanmak, restoranın kazancını önemli ölçüde artırabilir. Ayrıca, sabit bir maliyetle çalışmak, restoranın maliyetlerini daha iyi kontrol etmesini ve işletme bütçesini daha etkin bir şekilde yönetmesini sağlar.

Bu nedenle, komisyonsuz bir sipariş sistemini kullanmak, restoranların hem mali açıdan hem de işletme verimliliği açısından büyük avantajlar sağlar.

`,
  },
  {
    key: 4,
    title: 'Ne Yapıyoruz',
    content: `Restorant Kolay, restoran işletmecilerine kendi sipariş sitelerini sadece 10 dakika içinde kurma imkanı sunan bir platformdur. Bu platform, diğer sipariş sistemlerinin aksine, yüksek komisyon ücretleri yerine daha basit ve uygun fiyatlı bir hizmet sunar. Restorant Kolay’ın sunduğu bu hizmet, restoran sahiplerinin kendi özel web sitelerini oluşturarak kalitelerini müşterilerine daha iyi bir şekilde göstermelerine olanak sağlar.

Restorant Kolay’ın sunduğu bu hizmet sayesinde, restoran sahipleri siparişlerini kendi sitelerinden yönetebilir ve bu sayede kazançlarını artırabilirler. Bu platform, restoran sahiplerine hem online varlıklarını güçlendirme hem işlerini daha verimli bir şekilde yönetme hem de müşterilerine daha iyi bir hizmet vermesini sağlar.

Restorant Kolay, restoran işletmecilerine bir dizi özellik sunar:

1. Kurulumsuz Kullanım: Restorant Kolay, her cihazda çalışabilen bir restoran yazılımıdır. Kurulum gerektirmeden kullanabilirsiniz.

2. Uzaktan Erişim: Restorant Kolay bulut tabanlı bir restoran uygulamasıdır. Dünyanın neresinde olursanız olun sipariş lerinize ve raporlarınıza anında ulaşabilirsiniz.

3. Her Cihazda Çalışma: Restorant Kolay'ı cep telefonu, tablet ve bilgisayarlarınızda kullanabilirsiniz.

4. Kolay Kullanım: Restorant Kolay'ı yediden yetmişe herkes kullanabilir. Çalışanlarınızın pos sistemini öğrenmesi ve alışması zaman almaz.

5. Hızlı Sipariş Alma: Garsonun masa başında onayladığı sipariş milisaniyeler içinde mutfakta, barda, ocak başında olur.

6. Dijital Karekod Menü: Misafirleriniz QR kod menüyü okutarak siparişlerini temassız verebilir.

7. Raporlama: Kolay Restoran ile aldığınız siparişler gün sonu işlemleri sonrasında raporlanır.`,
  },
];
const title = 'Sık Sorulan Sorular';
