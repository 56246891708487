import { Grid, Typography } from '@mui/material';
import { SahinButton } from 'common/components/SahinButton/SahinButton';
import { CommonTablePaths } from 'common/contact/common/model/CommonTablePaths';
import { getAPI, postAPI } from 'common/helper/apiHelpers';
import { SurveyType } from 'common/model/Survey';
import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Options } from 'survey/components/Options';
import { Text } from 'survey/components/Text';

type SurveyAnswerType = {
  questionId: number;
  answer: string | number | number[];
};

export const SurveyPage = ({ id }: { id?: number }) => {
  const [searchParams] = useSearchParams();
  const surveyId = id ?? searchParams.get('id');
  const secret = searchParams.get('secret');

  const [survey, setSurvey] = useState<SurveyType | null>(null);
  const [answers, setAnswers] = useState<SurveyAnswerType[]>([]);
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    if (surveyId && secret === 'mamadi') {
      getAPI(CommonTablePaths.SURVEY, surveyId).then((res) => {
        setSurvey(res.data);
      });
    }
  }, [secret, surveyId]);

  const handleChange =
    (questionId: number) => (answer: number | number[] | string) => {
      const index = answers.findIndex((a) => a.questionId === questionId);
      if (index === -1) {
        setAnswers((prev) => [...prev, { questionId, answer }]);
      } else {
        setAnswers((prev) => {
          prev[index].answer = answer;
          return [...prev];
        });
      }
    };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (surveyId)
      postAPI(CommonTablePaths.SURVEY, { answers: answers }, surveyId).then(
        (res) => {
          setCode(res.data.data.code);
        },
      );
  };

  return (
    <Grid
      justifyContent="center"
      style={{ height: '100%', overflow: 'scroll', padding: '50px 0' }}
      container
    >
      {survey?.enabled ? (
        <Grid
          component="form"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          onSubmit={onSubmit}
          p={4}
          container
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}
            >
              {survey?.title}
            </Typography>
          </Grid>
          <Grid item>
            {survey?.questions?.map((question) => (
              <Grid key={question.id} p={4}>
                {question.type === 'text' && (
                  <Text data={question} onChange={handleChange(question.id)} />
                )}
                {(question.type === 'options' ||
                  question.type === 'multiple_choice') && (
                  <Options
                    data={question}
                    onChange={handleChange(question.id)}
                  />
                )}
                {/* {question.type === 'multiple_choice' && (
                  <MultipleChoice data={question} />
                )} */}
              </Grid>
            ))}
            <SahinButton type="submit" fullWidth>
              Gönder
            </SahinButton>
          </Grid>
        </Grid>
      ) : (
        <>Survey is not enabled</>
      )}
      {code && (
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          {code}
        </Typography>
      )}
    </Grid>
  );
};
