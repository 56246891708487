import { AddPhotoAlternate, Upload } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { uploadAPI } from 'common/helper/apiHelpers';
import { AdminTablePaths } from 'common/model/AdminTablePaths';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { SahinButton } from '../SahinButton/SahinButton';
import { SelectFileProps, SelectFileRef } from './SelectFile.types';

export const SelectFile = forwardRef<SelectFileRef, SelectFileProps>(
  (props, ref) => {
    const {
      showPreview,
      showUploadButton,
      value,
      previewValue,
      path = AdminTablePaths.BRAND,
      onChange,
      onSelect,
      error,
      containerProps,
      multiple,
    } = props;
    const [files, setFiles] = useState<FileList | null>(null);
    const [fileUrl, setFileUrl] = useState<string[] | undefined>();
    const [progress, setProgress] = useState<any[]>([]);
    const uploadedIndex = useRef<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const addFile = useCallback(
      (event: any) => {
        const files: FileList = event.target.files;
        if (!files || !files.length) return;

        const fileUrl = Array.from(files).map((file) =>
          URL.createObjectURL(file),
        );

        setFileUrl(fileUrl);
        setFiles(files);
        onSelect?.(fileUrl);
      },
      [onSelect],
    );

    const fileUploadHandler = useCallback(
      async (file: File, ndx: number): Promise<string[]> => {
        if (!file || !files?.length) {
          console.log('file not found');
          return [];
        }
        const uploaded = await uploadAPI(path, file, (api) => {
          const progress = Math.floor((api.progress || 0) * 100);
          setProgress((pv) => {
            pv[ndx] = progress;
            return [...pv];
          });
        });
        let uploadedFiles: string[] = [];
        if (ndx < files.length - 1) {
          uploadedFiles = await fileUploadHandler(files[ndx + 1], ndx + 1);
        }

        return [uploaded, ...uploadedFiles];
      },
      [files, path],
    );

    const fileUpload = useCallback(async () => {
      if (fileUrl?.length && !files?.length) return value;
      if (!files?.length) {
        return value;
      }
      const ndx = uploadedIndex.current || 0;
      const uploadedFileURL = await fileUploadHandler(files[ndx], ndx);
      onChange?.(uploadedFileURL);
      return uploadedFileURL;
    }, [fileUploadHandler, fileUrl, files, onChange, value]);

    useImperativeHandle(ref, () => ({
      fileUpload,
    }));

    return (
      <Grid container {...containerProps}>
        <Grid container mb={2} spacing={0.5}>
          {showPreview && (
            <>
              {fileUrl?.map((image, index) => (
                <Grid key={index} sx={{ opacity: 0.5 }} item>
                  <Grid
                    p={1}
                    bgcolor="rgba(255,255,255,0.05)"
                    borderRadius={4}
                    container
                  >
                    <img
                      style={{
                        width: '100px',
                        borderRadius: '15px',
                      }}
                      src={image}
                      alt="images"
                    />
                    <br />
                    {progress[index] && `${progress[index]} %`}
                  </Grid>
                </Grid>
              ))}
              {previewValue?.map((image, index) => (
                <Grid key={index} item>
                  <Grid
                    p={1}
                    bgcolor="rgba(255,255,255,0.05)"
                    borderRadius={4}
                    container
                  >
                    <img
                      style={{
                        width: '100px',
                        borderRadius: '15px',
                      }}
                      src={image}
                      alt="images"
                    />
                    <br />
                    {progress[index] && `${progress[index]} %`}
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <Grid container>
          <input
            ref={inputRef}
            type="file"
            style={{ display: 'none' }}
            onChange={addFile}
            multiple={multiple}
          />
          <SahinButton
            sx={{
              width: '100%',
              p: 1,
              mb: 1,
              border: '3px dashed #4e4e4e',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
              fontSize: 8,
            }}
            color="soft"
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            {<AddPhotoAlternate />}
          </SahinButton>
        </Grid>
        {showUploadButton && (
          <Grid justifyContent="flex-end" container>
            <SahinButton
              color="soft"
              startIcon={<Upload />}
              onClick={fileUpload}
              disabled={progress.length > 0}
            >
              YÜKLE
            </SahinButton>
            <Typography>{error}</Typography>
          </Grid>
        )}
      </Grid>
    );
  },
);
