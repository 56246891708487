import { Grid } from '@mui/material';
import { BarCharts } from './BarCharts';
import { TodayWeekMonth } from './TodayWeekMonth/TodayWeekMonth';

type BarData = {
  xAxisName: string;
  uv: number;
  [key: string]: number | string | undefined;
  amt: number;
}[];

interface HomeChartsProps {
  loading: boolean;
  error: boolean;
  today?: string;
  weekly?: string;
  monthly?: string;
  todayTitle?: string;
  weeklyTitle?: string;
  monthlyTitle?: string;
  barData?: BarData;
  barValueKey?: string;
  barTitle?: string;
  valueFormatter?: (value: any) => string;
}

export const HomeCharts = ({
  loading,
  error,
  today,
  weekly,
  monthly,
  todayTitle,
  weeklyTitle,
  monthlyTitle,
  barData,
  barValueKey,
  barTitle,
  valueFormatter,
}: HomeChartsProps) => {
  return (
    <Grid container spacing={1}>
      {[today, weekly, monthly].filter((item) => item !== undefined).length >
        0 && (
        <Grid item xs={12}>
          <TodayWeekMonth
            today={today}
            weekly={weekly}
            monthly={monthly}
            todayTitle={todayTitle}
            weeklyTitle={weeklyTitle}
            monthlyTitle={monthlyTitle}
            loading={loading}
            error={error}
          />
        </Grid>
      )}

      {barData && barValueKey && barTitle && (
        <Grid item xs={12}>
          <BarCharts
            title={barTitle}
            data={barData}
            error={error}
            loading={loading}
            xKey="xAxisName"
            valueFormatter={valueFormatter}
            valueKey={barValueKey}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '10px',
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
