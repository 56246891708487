import { BlogWrapper } from 'blog/components/BlogWrapper/BlogWrapper';

export const About = () => {
  const title = 'Hakkımızda';
  const content = `
Biz, restorantların ürünlerini komisyonsuz olarak listeleyebilecekleri ve kolayca sipariş alabilecekleri bir e-sipariş platformu olan Restorant Kolay’ız.

Misyonumuz, satıcıların işlerini büyütmelerine ve müşterilerine daha iyi hizmet vermelerine yardımcı olmaktır.

Restorantların günümüz çağına yetişmek için kullandığı alternatifi olmaması dolayısıyla tekelleşmiş ve yüksek komisyonlarla kullanılan sistemlere alternatif olmak için komisyonsuz bir sistem geliştirmek amacıyla bir araya gelmiş bir ekibiz.

Ekibimizin bu sistemi geliştirmekteki amacı Türkiye’de yaşayan her bireyin en temel ihtiyaçlarına daha ucuz ulaşması ve emekçinin de hakkını kazanması için yüksek maliyetleri düşmeye katkıda bulunmak istiyoruz.

Genç mühendislerimizin yenilikçi çözümleri ile sizinde şikayetçi olduğunuzu bildiğimiz komisyonları aradan çıkararak, sizin üzerinizden gelişen değil sizinle gelişen bir sistem olmayı ve Türkiye’nin tamamında restorantların düşük maliyetlerle sipariş alabildiği günü hedefliyoruz.

Yaklaşık 1 yıldır üzerinde çalıştığımız bu sistemi Ekim ayı ile sizlerin kullanımına sunuruyoruz. Nisan 2023’te geliştirmeye başladığımız sistemi büyük bir hızla geliştirmeye ve siz değerli kullanıcılarımızın sorunlarına çözümler bulmaya devam ediyoruz. Her zaman kullanıcılarımız yanında olmaya devam edeceğiz..

Bu sistemi hem nasıl kullanacağınızı hemde nasıl müşterilerinizi bu sistemi kullanmaya yönlendirebileceğinizi Youtube kanalımızda hazırladığımız videolarla sizlerle paylaşıyor olacağız.
`;

  return <BlogWrapper title={title} content={content} />;
};
