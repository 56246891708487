import { Grid } from '@mui/material';
import { PlanCard } from '../PlanCard/PlanCard';
import { SahinSwitch } from '../SahinSwitch/SahinSwitch';
import { useStyles } from './Landing.styles';
import { useState } from 'react';
export const plans = [
  {
    id: 'PLAN0010',
    title: 'ÜCRETSİZ',
    price: '0',
    description: 'İşletmeniz için hızlı sipariş sayfasına sahip olursunuz',
    features: ['1 Yönetici Hesabı', 'Aylık 100 sipariş', '100 MB Hafıza'],
  },
  {
    id: 'PLAN0030',
    title: 'Giriş',
    price: '1000',
    description: 'İşletmeniz için hızlı sipariş sayfasına sahip olursunuz',
    features: [
      '1 Yönetici Hesabı',
      'Aylık 1.000 sipariş',
      'E-Posta Bildirimi',
      '1000 MB Hafıza',
      '7/24 Mesaj Destek',
    ],
  },
  {
    id: 'PLAN0050',
    title: 'Gelişmiş',
    // price: '1000',
    description:
      'Siparişleri kolay yönettiğiniz bir sipariş sisteme sahip olursunuz.',
    features: [
      '1 Yönetici Hesabı',
      '2 Siparis Takip Hesabı',
      'Aylık 5.000 Sipariş',
      '5 GB Hafıza',
      'Giriş plandakilerin tümü',
    ],
    disabled: true,
  },
  {
    id: 'PLAN0080',
    title: 'Özel',
    // price: '2000',
    description:
      'Siparişleri analiz edip arttırdığınız bir sipariş sisteme sahip olursunuz.',
    features: [
      'Özel Domain (.com, .net)',
      'Mobil Uygulama',
      'Çoklu Şube Desteği',
      '2 Yönetici Hesabı',
      '5 Siparis Takip Hesabı',
      '10 Kurye Hesabı',
      '10 GB Hafıza',
      'Sınırsız Aylık Sipariş',
      '7/24 Canlı Destek',
      'Gelişmiş plandakilerin tümü',
    ],
    disabled: true,
  },
];

export const Plans = ({ onSelect, switchColorful, sx, ...rest }: any) => {
  const { classes } = useStyles();
  const [isYearly, setYearly] = useState(true);

  const onSelectHandle = (selected: any, index: number) => {
    onSelect?.(selected, index, isYearly);
  };

  return (
    <Grid
      xs={12}
      justifyContent="center"
      className={classes.floor7}
      id="pricing"
      item
      container
      {...rest}
    >
      <Grid
        xs={12}
        justifyContent="center"
        sx={{ my: 10, mt: 1 }}
        item
        container
      >
        <SahinSwitch
          onChange={(value) => setYearly(value)}
          value={isYearly}
          label={isYearly ? 'YILLIK' : 'AYLIK'}
          informativeText={isYearly ? '%10 İNDİRİM' : undefined}
          sx={{ width: '250px' }}
          colorful={switchColorful}
        />
      </Grid>
      <Grid
        xs={12}
        lg={12}
        justifyContent="center"
        spacing={8}
        item
        container
        sx={(theme) => ({
          ...sx,
          [theme.breakpoints.down('md')]: {
            padding: '80px 0',
          },
        })}
        {...rest}
      >
        {plans.map((plan, index) => (
          <Grid key={index} xs={10} lg={12 / plans.length} item>
            <PlanCard
              xs={12}
              plan={plan}
              color={index}
              isYearly={isYearly}
              onSelect={(selected: any) => onSelectHandle(selected, index)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
