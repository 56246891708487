import { Grid, Typography } from '@mui/material';
import { ReactComponent as CategoryListOrder } from 'assets/icons/categoryListOrder.svg';
import { ReactComponent as CategoryTopOrder } from 'assets/icons/categoryTopOrder.svg';
import { SahinTextField } from 'common/components/TextField/SahinTextField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LogoForm } from './LogoForm';
import { SettingSwitchField } from './SettingSwitchField';
import { SettingsDividerTitle } from './SettingsDividerTitle';

export const BrandForm = ({
  register,
  errors,
  control,
  setting,
  setValue,
  handleOnSubmit,
}: any) => {
  const { t } = useTranslation();

  return (
    <>
      <SettingsDividerTitle text={t('settings.siteInformation')} />
      {/* TİTLE */}
      <Grid xs={12} item>
        <SahinTextField
          {...register(`store.title` as keyof Setting)}
          error={errors?.store?.title?.message}
          label={t('form.title.value')}
          placeholder={t('form.title.placeholder')}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <LogoForm
        setting={setting}
        register={register}
        errors={errors}
        setValue={setValue}
        handleOnSubmit={handleOnSubmit}
      />
      <SettingsDividerTitle text={t('general')} />
      {generalSettingsForm.map((item) => (
        <>
          <SettingSwitchField control={control} {...item} />
          <SettingsDividerTitle />
        </>
      ))}

      <Grid xs={6} item container>
        Anasayfa Kategori Listeleme Görünümü
      </Grid>
      <Grid
        xs={6}
        justifyContent="flex-end"
        alignContent="start"
        alignItems="start"
        gap={2}
        item
        container
      >
        <Controller
          control={control}
          name="store.homePageDesign"
          render={({ field: { onChange, value } }) => (
            <Typography
              fontSize={60}
              onClick={() => onChange?.(false)}
              component={Grid}
              sx={
                !value
                  ? { outline: '3px solid #1cab81', padding: 0.4 }
                  : { background: '#777', padding: 0.4 }
              }
            >
              <CategoryTopOrder />
            </Typography>
          )}
        />
        <Controller
          control={control}
          name="store.homePageDesign"
          render={({ field: { onChange, value } }) => (
            <Typography
              fontSize={60}
              onClick={() => onChange?.(true)}
              component={Grid}
              sx={
                value
                  ? { outline: '3px solid #1cab81', padding: 0.4 }
                  : { background: '#777', padding: 0.4 }
              }
            >
              <CategoryListOrder />
            </Typography>
          )}
        />
      </Grid>
      <SettingsDividerTitle />
    </>
  );
};

const generalSettingsForm = [
  { name: 'store.isOrderOpen', label: 'Site Aktif' },
  { name: 'store.isMenu', label: 'Menü Aktif' },
  { name: 'store.isGuestOrder', label: 'Üyeliksiz Sipariş Aktif' },
];
