import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';

export const ContactFormYup = yupResolver(
  yup
    .object({
      subject: yup.string().min(3).required(),
      message: yup.string().min(5).required(),
    })
    .required(),
);
