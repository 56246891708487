import { Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import { LandingFooter } from 'common/components/LandingComponents/LandingFooter';
import { LandingHeader } from 'common/components/LandingComponents/LandingHeader';

export const SocialMedia = () => {
  return (
    <Grid justifyContent="space-around" alignContent="space-between" container>
      <LandingHeader />
      <Grid xs={10} justifyContent="space-around" item container>
        <Typography variant="h1" fontSize={34}>
          Sosyal Medya
        </Typography>
      </Grid>
      <Grid xs={10} spacing={4} item container>
        {list.map((item) => (
          <Grid key={item.key} xs={3} item container>
            <Grid
              component={Paper}
              justifyContent="center"
              alignItems="center"
              direction="column"
              p={4}
              gap={2}
              item
              container
            >
              <IconButton
                LinkComponent="a"
                target="_blank"
                href={item.link}
                sx={{
                  background: '#2e2e2e',
                  width: 80,
                  height: 80,
                }}
              >
                {item.icon}
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <LandingFooter />
    </Grid>
  );
};

const list = [
  {
    key: 0,
    icon: <LinkedIn fontSize="large" color="primary" />,
    link: 'https://linkedin.com/company/RestorantKolay',
  },

  {
    key: 2,
    icon: <YouTube fontSize="large" color="error" />,
    link: 'https://youtube.com/@RestorantKolay',
  },
  {
    key: 3,
    icon: <Twitter fontSize="large" color="info" />,
    link: 'https://x.com/RestorantKolay',
  },
  {
    key: 1,
    icon: (
      <>
        <svg width="0" height="0">
          <radialGradient id="rg" r="150%" cx="30%" cy="107%">
            <stop stopColor="#fdf497" offset="0" />
            <stop stopColor="#fdf497" offset="0.05" />
            <stop stopColor="#fd5949" offset="0.45" />
            <stop stopColor="#d6249f" offset="0.6" />
            <stop stopColor="#285AEB" offset="0.9" />
          </radialGradient>
        </svg>
        <Instagram
          fontSize="large"
          sx={{
            fill: 'url(#rg)',
          }}
        />
      </>
    ),
    link: 'https://instagram.com/RestorantKolay',
  },
];
