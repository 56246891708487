import { sahinAlertDialog } from 'common/components/AlertDialog/AlertDialog';
import { fetcher } from 'common/utils/helper';
import { SWRConfig } from 'swr';

export const SwrManager = ({ children }: any) => {
  return (
    <SWRConfig
      value={{
        fetcher,
        errorRetryInterval: 10000,
        errorRetryCount: 4,
        refreshWhenOffline: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: (message) => {
          sahinAlertDialog({
            message,
            type: 'error',
          });
          return true;
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
