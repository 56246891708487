import { Grid, Typography } from '@mui/material';
import { TextMessage } from 'common/components/TextMessage/TextMessage';
import { FailedLoad } from '../../FailedLoad/FailedLoad';
import { Loading } from '../../Loading/Loading';

export const JustText = ({
  loading,
  error,
  text,
  title,
  sx,
}: {
  loading: boolean;
  error: boolean;
  text: string;
  title: string;
  sx?: React.CSSProperties;
}) => (
  <Grid
    sx={{
      padding: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '10px',
      ...sx,
    }}
  >
    <Typography variant="h6" component="div" gutterBottom>
      <TextMessage text={title} />
    </Typography>
    {loading && <Loading />}
    {!loading && error && <FailedLoad />}
    {!loading && !error && text && (
      <Typography variant="h6" component="div" gutterBottom>
        <TextMessage text={text} />
      </Typography>
    )}
  </Grid>
);
