import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawer: {
    width: '100%',
    position: 'relative',
  },
  paper: {
    width: '100%',
    position: 'absolute!important' as 'absolute',
    background: '#1e1e1e!important',
  },
  listItem: {
    padding: 0,
    color: '#6e6e6e',
    '& svg': {
      color: '#6e6e6e',
    },
    '&:hover': {
      '& svg': {
        color: '#9e9e9e',
      },
    },
  },
  icon: {
    minWidth: 35,
  },
});
