import { configureStore } from '@reduxjs/toolkit';
import { accountReducer } from 'account/common/context/AccountSlice';
import { sahinYazilimReducer } from 'context/SahinYazilimSlice';
import { commonReducer } from './CommonSlice';
// import api from './api';

export const store = configureStore({
  reducer: {
    // [api.reducerPath]: api.reducer,
    common: commonReducer,
    account: accountReducer,
    sahinyazilim: sahinYazilimReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
