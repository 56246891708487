import { Grid, Typography } from '@mui/material';
import { useStyles } from './Landing.styles';
import { OwnerEnv } from 'common/utils/env';

export const LandingMiniFooter = () => {
  const { classes } = useStyles();

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="space-between"
      className={classes.miniFooter}
    >
      <Grid
        item
        xs={6}
        lg={3}
        container
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        flexDirection="column"
      >
        <img src="/logo.png" alt="" height={20} />
      </Grid>
      <Grid
        item
        xs={6}
        lg={3}
        container
        alignContent="center"
        justifyContent="start"
        alignItems="center"
      >
        <Typography variant="caption">
          {OwnerEnv.company.phone}
          <br />
          {OwnerEnv.company.mail}
        </Typography>
      </Grid>
    </Grid>
  );
};
