import { PaymentForm } from 'account/payment/components/PaymentForm/PaymentForm';
import { ActivePlan } from 'account/plan/components/ActivePlan/ActivePlan';
import { PlanList } from 'account/plan/components/List/PlanList';
import { createRoute } from 'common/helper/createRoute';
import { Navigate } from 'react-router-dom';
import { AccountRouteNames } from '../model/AccountRouteNames';

export const accountRoute = createRoute([
  {
    path: `/${AccountRouteNames.PLAN}`,
    element: <ActivePlan />,
  },
  {
    path: `/${AccountRouteNames.PLAN_SELECT}`,
    element: <PlanList />,
  },
  {
    path: `/${AccountRouteNames.PAYMENT}`,
    element: <PaymentForm />,
  },
  {
    path: '*',
    element: <Navigate to={AccountRouteNames.PLAN} replace={true} />,
  },
]);
