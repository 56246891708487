import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Modal, ModalProps } from '@mui/material';
import { useStyles } from './SahinModal.styles';

export type SahinModalProps = {
  open: boolean;
  handleClose: () => void;
  width?: number | string;
  height?: number | string;
} & ModalProps;

export const SahinModal = ({
  open,
  handleClose,
  children,
  width = '60%',
  height = '60vh',
  ...rest
}: SahinModalProps) => {
  const { classes } = useStyles({ width, height });

  return (
    <Modal open={open} onClose={handleClose} {...rest}>
      <Box className={classes.root}>
        <Grid className={classes.close} justifyContent="end" container>
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid className={classes.content} container>
          {children}
        </Grid>
      </Box>
    </Modal>
  );
};
