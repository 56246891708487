import { Grid, Typography } from '@mui/material';
import { useResponsive } from 'common/hooks/useResponsive';
import { MouseEvent, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CookiePolicy } from './CookiePolicy';
import { useStyles } from './Landing.styles';

export const LandingHeader = ({ homeMenu = false }: { homeMenu?: boolean }) => {
  const { classes } = useStyles();
  const { isMd } = useResponsive();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const onGoHref = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const href = event.currentTarget.href;
    const indexSharp = href.indexOf('#');
    const id = href.substring(indexSharp + 1, href.length);
    const wanted = document.getElementById(id);
    const position = wanted?.getBoundingClientRect();
    if (!position) return;
    const top =
      window.innerHeight > position.height
        ? (window.innerHeight - position.height) / 2
        : 5;
    window.scrollTo({
      top: position.top - top,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Grid
      xs={12}
      className={classes.header}
      justifyContent="center"
      alignContent="center"
      item
      container
    >
      <CookiePolicy />
      <Grid
        xs={11}
        className={classes.header}
        justifyContent="center"
        alignContent="center"
        item
        container
      >
        <Grid component={Link} to="/" lg={3} md={12} item container>
          <img
            src="/logo.png"
            style={{ maxWidth: '100%', maxHeight: '50px' }}
            alt="restorant kolay logo"
          />
        </Grid>
        {isMd.up && homeMenu && (
          <>
            <Grid xs={6} justifyContent="center" item container>
              <>
                <a
                  href="#use-cases"
                  onClick={onGoHref}
                  className={classes.headerNavButton}
                >
                  Kullanım Şekilleri
                </a>
                <a
                  href="#features"
                  onClick={onGoHref}
                  className={classes.headerNavButton}
                >
                  Özellikler
                </a>
                <a
                  href="#pricing"
                  onClick={onGoHref}
                  className={classes.headerNavButton}
                >
                  Fiyat
                </a>
              </>
            </Grid>
            <Grid xs={3} justifyContent="end" item container>
              <Typography
                component={Link}
                to="/login"
                variant="h6"
                className={classes.headerNavButton}
              >
                GİRİŞ
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
