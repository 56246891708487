import { Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { sahinCustomDialog } from 'common/components/CustomDialog/CustomDialog';
import { Image } from 'common/components/Image/Image';
import { CheckStatusColor } from 'common/model/StatusColor';
import { getCdnImageUrl } from 'common/utils/image';

const getColor = (status: boolean) => {
  return status ? CheckStatusColor.ACTIVE : CheckStatusColor.INACTIVE;
};

export const productListColumns: GridColDef[] = [
  {
    field: 'image',
    headerName: '🖼️',
    width: 100,
    type: 'actions',
    renderCell: (params) => {
      return params.row?.images?.length ? (
        <Image
          src={getCdnImageUrl(params.row.images[0])}
          alt={params.row.name}
          height={50}
          onClick={() => {
            sahinCustomDialog({
              children: (
                <Image
                  src={getCdnImageUrl(params.row.images[0])}
                  alt={params.row.name}
                  aspectRatio="auto"
                />
              ),
            });
          }}
        />
      ) : (
        '#'
      );
    },
  },
  { field: 'name', headerName: 'Adı', width: 200 },
  { field: 'description', headerName: 'Açıklama', width: 300 },
  { field: 'price', headerName: 'Fiyat', width: 130 },
  {
    field: 'isActive',
    headerName: 'Yayında',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <Chip
          sx={{ height: 20, width: 20 }}
          color={getColor(params.row.isActive)}
        />
      );
    },
    width: 100,
  },
  {
    field: 'isPopular',
    headerName: 'Popüler',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <Chip
          sx={{ height: 20, width: 20 }}
          color={getColor(params.row.isPopular)}
        />
      );
    },
    width: 100,
  },
];
