import { yupResolver } from '@hookform/resolvers/yup';
import { yup } from 'application/init/yupInit';
import { getCdnImageUrl } from 'common/utils/image';

export const CategoryFormYup = yupResolver(
  yup
    .object({
      name: yup.string().min(2).required(),
      description: yup.string().min(0).required(),
    })
    .required(),
);

const root = 'assets/category_icons/';
export const categoryIcons: Icon[] = [...Array(67)].map((_, index) => ({
  name: `${index}`,
  url: getCdnImageUrl(`${root}${index}.svg`),
  imageRoot: `${root}${index}.svg`,
}));
